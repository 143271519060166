import { ChevronLeftIcon, CopyIcon } from '@chakra-ui/icons'
import {
  CollapsableTopBar,
  NavButton,
  NavItemContainer,
  TextOnlyNavButton,
  VerticalSubMenu,
} from 'components/CollapsableTopBar'
import { tryOpenNewTab } from 'components/TryOpenNewTab'
import { BackgroundIcon } from 'components/common/BackgroundIcon'
import { useFeatureFlag } from 'components/common/hooks/useFeatureFlags'
import { useQueryString } from 'components/common/utils/QueryString'
import { cn } from 'components/common/utils/tailwindUtils'
import { showCopyReviewDialog } from 'data/CopyReview'
import { VideoSourceData } from 'data/ReviewSelectionInit'
import { DocumentPermissions } from 'data/common'
import { ReactNode, useCallback } from 'react'
import ReactTagManager from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import { ReviewActivityType } from 'ui/ReviewMetaStore'
import { showSignInToEditDialog } from 'util/dialogUtils'
import { useWindowDimensions } from './UseWindowDimensions'
import logoSvg from './betalogo.svg'
import { ShareDialogOptions, showShareReviewDialog } from './components/ShareReviewDialog'
import { VideoController } from './components/VideoController'
import { FirebaseDb, WrappedAuth, useFirebase } from './components/common/Firebase'
import { useUser } from './components/common/UseUser'
import graphIcon from './icons/graph_icon.png'
import shareIcon from './icons/share_button.png'
import { HighlightsButtons, HighlightsMode, ViewModeButtonProps } from './ui/HighlightsButtons'
import { HighlightPlayer, HighlightTypes } from './ui/HighlightsPlayer'
import { useCopyLinkToClipBoard } from './util/links'

export function HomeNavigationBar({
  auth,
  shareDialogOptions,
  showCreateButton = true,
  leftSideChildren,
  rightSideChildren,
}: {
  showCreateButton?: boolean
  leftSideChildren?: ReactNode
  rightSideChildren?: ReactNode
  auth: WrappedAuth
  shareDialogOptions?: ShareDialogOptions
}) {
  const clipboardCopier = useCopyLinkToClipBoard()
  const navigate = useNavigate()
  const { SignIn } = useUser({
    auth,
  })
  const { value: bookReviewPage } = useFeatureFlag('bookReviewPage', false, true)
  const { value: publicLeaguesPricing } = useFeatureFlag('publicLeaguesPricing', false)
  const [backbuttonDestination] = useQueryString('referrer_url', undefined)

  const handleShareButtonClicked = useCallback((shareDialogOptions: ShareDialogOptions) => {
    showShareReviewDialog(shareDialogOptions)
  }, [])

  return (
    <CollapsableTopBar
      // hamburgerButtonClassName='bg-[rgba(189,32,38,0.54)]'
      leftMainButton={() => (
        <>
          {backbuttonDestination && (
            <NavButton
              onClick={() => navigate(backbuttonDestination)}
              className='bg-transparent text-white font-semibold'
              alt={'Go to back to the previous page'}>
              <ChevronLeftIcon fontSize={30}></ChevronLeftIcon>
              Back
            </NavButton>
          )}
          {!backbuttonDestination && <PlaybackHomeButton showCreateButton={showCreateButton} />}
        </>
      )}
      rightFixedChildren={() => [
        rightSideChildren,
        <NavItemContainer key='signin'>
          <SignIn />
        </NavItemContainer>,
        clipboardCopier.copy && shareDialogOptions && (
          <NavButton
            key='share'
            onClick={() => handleShareButtonClicked(shareDialogOptions)}
            icon={shareIcon}
            className='bg-[#1c1c1c] text-white'
            alt={'share this review as a link'}
            disabled={clipboardCopier.isCopying}
            squashedChildrenArray={['']}>
            Share
          </NavButton>
        ),
      ]}>
      {() => [
        <VerticalSubMenu
          key={'leaderboards'}
          label='Leaderboards'>
          <TextOnlyNavButton
            key='nswdlleaderboard'
            onClick={() => window.location.assign('/leaderboards?id=-NzhvbHFWR6At-AwpyzC')}>
            NSWDL Leaderboard
          </TextOnlyNavButton>
          <TextOnlyNavButton
            key='sadlleaderboard'
            onClick={() => window.location.assign('/leaderboards?id=-OB2m34C2NjQGUuWKrYq')}>
            SADL Leaderboard
          </TextOnlyNavButton>
          <TextOnlyNavButton
            key='apileaderboard'
            onClick={() => window.location.assign('/leaderboards?id=-O98B8B6YBM52TkeWyYC')}>
            API Gold Matches Leaderboard
          </TextOnlyNavButton>
        </VerticalSubMenu>,
        <TextOnlyNavButton
          key='media'
          onClick={() => navigate('/media')}>
          Media
        </TextOnlyNavButton>,
        bookReviewPage && (
          <TextOnlyNavButton
            key='book'
            onClick={() => navigate('/bookareview')}>
            Book a PRO review
          </TextOnlyNavButton>
        ),
        <VerticalSubMenu
          key={'partnerships'}
          label='Partnerships'>
          <TextOnlyNavButton
            key='pbdodgeball'
            onClick={() => {
              tryOpenNewTab('https://www.pbdodgeball.co.uk/')
            }}>
            PB Dodgeball Club | London UK
          </TextOnlyNavButton>
          <TextOnlyNavButton
            key='dodgeballhub'
            onClick={() => tryOpenNewTab('https://www.dodgeballhub.com/')}>
            Dodgeball Hub | Events
          </TextOnlyNavButton>
          {publicLeaguesPricing && (
            <TextOnlyNavButton
              key='leagues'
              onClick={() => navigate('/leagues', { replace: true })}>
              For Leagues
            </TextOnlyNavButton>
          )}
        </VerticalSubMenu>,
        <TextOnlyNavButton
          key='about'
          onClick={() => navigate('/about')}>
          About
        </TextOnlyNavButton>,
        leftSideChildren,
      ]}
    </CollapsableTopBar>
  )
}
export function EditorNavigationBar({
  canExitWatchMode,
  onFullscreenClicked,
  highlightPlayer,
  highlightsMode,
  isFullScreen,
  isPlayingHighlights,
  onShowAnalysisClicked,
  onWatchModeClicked,
  documentPermissions,
  auth,
  shareDialogOptions,
  videoController,
  watchMode,
  hasPlayersOrFilters,
  showCreateButton = true,
  height = 48,
  trackingProgress = 0,
  viewingModeButtonProps,
  firebaseDb,
  reviewFileProps,
  chosenStatsLevel,
  onStatsLevelChosen,
  activityType,
}: {
  activityType?: ReviewActivityType | undefined
  chosenStatsLevel?: DodgeballStatsLevel
  onStatsLevelChosen?: (level: DodgeballStatsLevel) => void
  height?: number
  showCreateButton?: boolean
  documentPermissions: DocumentPermissions | undefined
  videoController: VideoController | undefined
  isPlayingHighlights: false | HighlightTypes
  highlightPlayer: HighlightPlayer | undefined
  auth: WrappedAuth
  highlightsMode: HighlightsMode
  hasPlayersOrFilters: boolean
  onFullscreenClicked: () => void
  watchMode: boolean
  onWatchModeClicked: () => void
  canExitWatchMode: boolean
  isFullScreen: boolean
  onShowAnalysisClicked?: (show: boolean) => void
  shareDialogOptions: ShareDialogOptions | undefined
  trackingProgress: number
  viewingModeButtonProps?: ViewModeButtonProps
  firebaseDb: FirebaseDb
  reviewFileProps: {
    reviewId?: string
    videoSourceData?: VideoSourceData
  }
}) {
  const clipboardCopier = useCopyLinkToClipBoard()
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const { SignIn, user, navigateToSignIn } = useUser({
    auth,
  })
  const compactMode = windowDimensions.width <= 900
  const extremeCompact = windowDimensions.width <= 500

  const handleShareButtonClicked = useCallback((shareDialogOptions: ShareDialogOptions) => {
    showShareReviewDialog(shareDialogOptions)
  }, [])

  const [backbuttonDestination] = useQueryString('referrer_url', undefined)
  const handleCopy = useCallback(
    (reviewId: string) => () => {
      if (!user) {
        showSignInToEditDialog({
          action: { title: 'copy', message: 'make a copy' },
          navigateToSignIn,
        })
        return
      }
      showCopyReviewDialog(firebaseDb, user, reviewId, navigate)
    },
    [firebaseDb, navigate, navigateToSignIn, user],
  )

  return (
    <CollapsableTopBar
      // hamburgerButtonClassName='bg-[rgba(189,32,38,0.54)]'
      leftMainButton={() => [
        backbuttonDestination ?
          <NavButton
            onClick={() => navigate(backbuttonDestination)}
            className='bg-transparent text-white font-semibold'
            alt={'Go to back to the previous page'}>
            <ChevronLeftIcon fontSize={30}></ChevronLeftIcon>
            Back
          </NavButton>
        : <PlaybackHomeButton
            documentPermissions={documentPermissions}
            showCreateButton={showCreateButton}
            compactMode={compactMode}
          />,
      ]}
      rightFixedChildren={() => [
        <NavItemContainer
          showOnlyInVerticalMenu={true}
          key='signin'>
          <SignIn />
        </NavItemContainer>,
        onShowAnalysisClicked && (
          <NavButton
            key='analysis'
            icon={graphIcon}
            onClick={() => {
              ReactTagManager.event('show_insights_clicked', {
                category: 'insights',
              })
              onShowAnalysisClicked(true)
              videoController?.pause()
            }}
            className={cn(
              'bg-white text-black',
              trackingProgress >= 0.8 && 'animate-rainbowBorder border-[3px]',
            )}
            alt={'View statistics'}
            squashedChildrenArray={['']}>
            Insights
          </NavButton>
        ),
        clipboardCopier.copy && shareDialogOptions && !watchMode && (
          <NavButton
            key='share'
            onClick={() => handleShareButtonClicked(shareDialogOptions)}
            icon={shareIcon}
            className='bg-[#1c1c1c] text-white'
            disabled={clipboardCopier.isCopying}
            squashedChildrenArray={['Share', '']}>
            {highlightPlayer?.isPlayingHighlights ? `Share ${highlightsMode}` : 'Share Review'}
          </NavButton>
        ),
      ]}>
      {() => [
        <>
          {videoController && highlightPlayer && (
            <NavItemContainer>
              <HighlightsButtons
                viewingModeButtonProps={viewingModeButtonProps}
                height={height - 8}
                hasPlayersOrFilters={hasPlayersOrFilters}
                videoController={videoController}
                isPlayingHighlights={isPlayingHighlights}
                highlightPlayer={highlightPlayer}
                displayStyle={
                  extremeCompact ? 'smallest'
                  : compactMode ?
                    'small'
                  : 'large'
                }
                highlightsMode={highlightsMode}
                fullscreenClicked={onFullscreenClicked}
                watchMode={watchMode}
                onWatchModeClicked={onWatchModeClicked}
                canExitWatchMode={canExitWatchMode}
                isFullScreen={isFullScreen}
              />
            </NavItemContainer>
          )}
        </>,
        <VerticalSubMenu
          showOnlyInVerticalMenu={true}
          key='File'
          label='File'>
          {reviewFileProps.reviewId && (
            <NavButton
              icon={<CopyIcon />}
              onClick={handleCopy(reviewFileProps.reviewId)}>
              Make a copy
            </NavButton>
          )}
        </VerticalSubMenu>,
        (
          onStatsLevelChosen &&
          activityType === 'dodgeball' &&
          viewingModeButtonProps?.viewingMode === 'edit'
        ) ?
          <NavItemContainer
            key='level'
            showOnlyInVerticalMenu={false}>
            <DodgeballStatsLevelDropdown
              className='h-full w-full'
              chosen={chosenStatsLevel}
              onChosen={onStatsLevelChosen}
            />
          </NavItemContainer>
        : undefined,
      ]}
    </CollapsableTopBar>
  )
}

export function PlaybackHomeButton({
  documentPermissions,
  showCreateButton,
  compactMode,
}: {
  documentPermissions?: DocumentPermissions | undefined
  showCreateButton: boolean
  compactMode?: boolean
}) {
  const navigate = useNavigate()
  return (
    <NavButton
      onClick={() => navigate('/')}
      className='h-full bg-transparent p-0'
      alt={'Go to the home page or create a new playback'}>
      <PlaybackText />
    </NavButton>
  )
}

export const PlaybackText = () => (
  <img
    src={logoSvg}
    className='h-full'
  />
)
/** for offline testing */
export function StoryBook() {
  const firebase = useFirebase(firebaseConfig)
  return (
    <div className='flex h-svh w-svw flex-col'>
      <EditorNavigationBar
        trackingProgress={100}
        auth={firebase.auth}
        canExitWatchMode={true}
        documentPermissions='view'
        onFullscreenClicked={() => ''}
        hasPlayersOrFilters={false}
        highlightPlayer={undefined}
        highlightsMode='Highlights'
        isFullScreen={false}
        isPlayingHighlights={'highlights'}
        onWatchModeClicked={() => ''}
        shareDialogOptions={undefined}
        videoController={undefined}
        watchMode={false}
        firebaseDb={firebase.firebaseDb}
        reviewFileProps={{
          reviewId: 'reviewId',
          videoSourceData: { id: 'id', source: 'Youtube' },
        }}
        activityType='dodgeball'
      />
    </div>
  )
}

export type DodgeballStatsLevel = keyof (typeof DodgeballTemplate)['TemplateIds'] |string
function DodgeballStatsLevelDropdown({
  chosen = 'basic',
  onChosen,
  className,
}: {
  chosen: DodgeballStatsLevel | undefined
  onChosen: (level: DodgeballStatsLevel) => void
  className?: string
}) {
  return (
    <div
      className={cn(
        'relative flex flex-row items-center justify-center gap-1 bg-blue-grey px-2 py-1',
        className,
      )}>
      <BackgroundIcon
        src={graphIcon}
        className='h-[1.2em] w-[1.2em]'
      />
      <select
        onChange={(e) => {
          onChosen(e.target.value as DodgeballStatsLevel)
        }}
        className='h-full w-full border-none bg-blue-grey font-montserrat text-md text-white'>
        <option
          selected={chosen === 'basic'}
          value='basic'>
          Basic Stats
        </option>
        <option
          selected={chosen === 'default'}
          value='default'>
          Advanced Stats
        </option>
        <option
          selected={chosen === 'kdr'}
          value='kdr'>
          Live Stats
        </option>
      </select>
    </div>
  )
}
