import {
  CSSProperties,
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { cn } from './common/utils/tailwindUtils'

export function useToggleSwitch(initial: boolean, onChanged?: (checked: boolean) => void) {
  const [checked, setChecked] = useState(initial)
  const inputRef = useRef<HTMLInputElement>(null)

  const onChangedInternal = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked)
      onChanged?.(event.target.checked)
    },
    [onChanged],
  )

  const Switch = useCallback(
    (props: { height?: number }) => (
      <ToggleSwitch
        checked={checked}
        ref={inputRef}
        height={props.height}
        onChanged={onChangedInternal}
      />
    ),
    [checked, onChangedInternal],
  )
  return { Toggle: Switch, checked, setChecked }
}
export type ToggleSwitchProps = {
  height?: number
  onChanged: ChangeEventHandler<HTMLInputElement>
  checked: boolean
}

export const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
  function ToggleSwitch(props, ref) {
    const labelref = useRef<HTMLLabelElement>(null)
    return (
      <label
        ref={labelref}
        className='relative inline-block h-[calc(var(--switch-height)_*_1px)] w-[calc(var(--switch-height)_*_2.5px)]'
        style={{ ['--switch-height']: props.height ?? 16 } as CSSProperties}>
        <input
          className='peer/toggle h-0 w-0 opacity-0'
          ref={ref}
          type='checkbox'
          checked={props.checked}
          onChange={props.onChanged}
        />
        <span
          className={cn(
            `duration-400 absolute inset-0 cursor-pointer rounded-[99999px] bg-black shadow-[0_0_1px_#dd3d4e]
            transition before:absolute before:bottom-[calc(var(--switch-height)_*_-0.25px)] before:left-0
            before:h-[calc(var(--switch-height)_*_1.5px)] before:w-[calc(var(--switch-height)_*_1.5px)]
            before:rounded-[50%] before:bg-white before:transition-[0.4s] before:content-[""]
            peer-checked/toggle:bg-[#dd3d4e]
            peer-checked/toggle:before:translate-x-[calc((var(--switch-height)_*_1px))]`,
          )}></span>
      </label>
    )
  },
)
