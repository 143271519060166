import { ALL_DEFINITIONS } from 'templates/TemplateLibrary'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'

export const mapLegacyKeyToEvent = (key: string) => {
  if (DodgeballTemplate.Definitions.has(key))
    return (
      DodgeballTemplate.Definitions.getOrNull(key) ?? ALL_DEFINITIONS.find((it) => it.key === key)
    )

  switch (key) {
    case 'Set_Up_Success':
      return DodgeballTemplate.Definitions.get('set_up_success')
    case 'Attack_Attempt':
      return DodgeballTemplate.Definitions.get('throw')
    case 'Trade_Attempt':
      return DodgeballTemplate.Definitions.get('throw_trade')
    case 'Trade_Hit':
      return DodgeballTemplate.Definitions.get('throw_trade_hit')
    case 'Trade_On_Target':
      return DodgeballTemplate.Definitions.get('throw_trade_ontarget')
    case 'Trade_Miss':
      return DodgeballTemplate.Definitions.get('throw_trade_miss')
    case 'Throw_Hit':
      return DodgeballTemplate.Definitions.get('throw_hit')
    case 'Throw_On_Target':
      return DodgeballTemplate.Definitions.get('throw_ontarget')
    case 'Throw_Miss':
      return DodgeballTemplate.Definitions.get('throw_miss')
    case 'Throw_Got_Caught':
      return DodgeballTemplate.Definitions.get('died_got-caught')
    case 'Punish_Attempt':
      return DodgeballTemplate.Definitions.get('throw_punish')
    case 'Punish_Hit':
      return DodgeballTemplate.Definitions.get('throw_punish_hit')
    case 'Punish_On_Target':
      return DodgeballTemplate.Definitions.get('throw_punish_ontarget')
    case 'Punish_Miss':
      return DodgeballTemplate.Definitions.get('throw_punish_miss')
    case 'Opp-shot_Attempt':
      return DodgeballTemplate.Definitions.get('throw_opp-shot')
    case 'Opp-shot_Hit':
      return DodgeballTemplate.Definitions.get('throw_opp-shot_hit')
    case 'Opp-shot_On_Target':
      return DodgeballTemplate.Definitions.get('throw_opp-shot_ontarget')
    case 'Opp-shot_Miss':
      return DodgeballTemplate.Definitions.get('throw_opp-shot_miss')
    case 'Counter Rush_Attempt':
      return DodgeballTemplate.Definitions.get('throw_counter-rush')
    case 'Counter Rush_Hit':
      return DodgeballTemplate.Definitions.get('throw_counter-rush_hit')
    case 'Counter Rush_On_Target':
      return DodgeballTemplate.Definitions.get('throw_counter-rush_ontarget')
    case 'Counter Rush_Miss':
      return DodgeballTemplate.Definitions.get('throw_counter-rush_miss')
    case 'Set_Throw_Attempt':
      return DodgeballTemplate.Definitions.get('throw_set-throw')
    case 'Set_Throw_Hit':
      return DodgeballTemplate.Definitions.get('throw_set-throw_hit')
    case 'Set_Throw_On_Target':
      return DodgeballTemplate.Definitions.get('throw_set-throw_ontarget')
    case 'Set_Throw_Miss':
      return DodgeballTemplate.Definitions.get('throw_set-throw_miss')
    case 'Catch_Attempt':
      return DodgeballTemplate.Definitions.get('defend_catch')
    case 'Catch_Success':
      return DodgeballTemplate.Definitions.get('defend_catch_success')
    case 'Catch_Fail':
      return DodgeballTemplate.Definitions.get('defend_catch_failed')
    case 'Catch_Fail_Reach_Died':
      return DodgeballTemplate.Definitions.get('died_catch-reach')
    case 'Catch_Fail_Died':
      return DodgeballTemplate.Definitions.get('defend_catch_failed')
    case 'Caught_Back_In':
      return DodgeballTemplate.Definitions.get('revive')
    case 'Defend_Attempt':
      return DodgeballTemplate.Definitions.get('defend')
    case 'Defend_Fail':
      return DodgeballTemplate.Definitions.get('defend_failed')
    case 'Defend_Fail_Died':
      return DodgeballTemplate.Definitions.get('defend_failed-died')
    case 'Defend_Success':
      return DodgeballTemplate.Definitions.get('defend_success')
    case 'Got_Targeted':
      return DodgeballTemplate.Definitions.get('got_targeted')
    case 'Block_Attempt':
      return DodgeballTemplate.Definitions.get('defend_block')
    case 'Block_Success':
      return DodgeballTemplate.Definitions.get('defend_block_success')
    case 'Block_Fail':
      return DodgeballTemplate.Definitions.get('defend_block_failed')
    case 'Block_Fail_Died':
      return DodgeballTemplate.Definitions.get('defend_block_failed-died')
    case 'Dodge_Attempt':
      return DodgeballTemplate.Definitions.get('defend_dodge')
    case 'Dodge_Success':
      return DodgeballTemplate.Definitions.get('defend_dodge_success')
    case 'Dodge_Fail':
      return DodgeballTemplate.Definitions.get('defend_dodge_failed')
    case 'Dodge_Fail_Died':
      return DodgeballTemplate.Definitions.get('defend_dodge_failed-died')
    case 'Foot_Foul':
      return DodgeballTemplate.Definitions.get('died_foot-foul')
    case 'Died':
      return DodgeballTemplate.Definitions.get('died')
    case 'Just_Died':
      return DodgeballTemplate.Definitions.get('died_noaction')
    case 'Throw_Died':
      return DodgeballTemplate.Definitions.get('died_while-throwing')
    case 'Hit_Died':
      return DodgeballTemplate.Definitions.get('died_hit')
    default:
      return ALL_DEFINITIONS.find((it) => it.key === key)
  }
}
