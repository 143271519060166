import { Box, BoxProps } from '@chakra-ui/react'
import React, { ReactNode, RefObject } from 'react'
import { cn } from './utils/tailwindUtils'

export interface SquashingBoxProps {
  squashedChildrenArray?: ReactNode[]
  children?: ReactNode
  className?: string
}

export class SquashingBox extends React.Component<SquashingBoxProps> {
  private ref: RefObject<HTMLDivElement>
  private map: Map<number, number>
  private observer: ResizeObserver | null = null
  private index = 0

  constructor(props: SquashingBoxProps) {
    super(props)

    this.ref = React.createRef()
    this.map = new Map<number, number>()
  }

  blur = () => {
    return this.ref.current?.blur()
  }
  focus = () => {
    return this.ref.current?.focus()
  }
  availableChildren = () => [this.props.children].concat(this.props.squashedChildrenArray ?? [])

  checkSquash = () => {
    const box = this.ref.current
    if (!box) return
    if (box.scrollWidth && box.clientWidth < box.scrollWidth) {
      if (this.availableChildren().length > this.index + 1) {
        this.map.set(this.index, box.scrollWidth)
        this.index += 1
        this.forceUpdate()
      }
    } else if (
      box.scrollWidth &&
      box.clientWidth > box.scrollWidth &&
      this.map.has(this.index - 1) &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      box.clientWidth > this.map.get(this.index - 1)!
    ) {
      this.index -= 1
      this.forceUpdate()
    }
  }

  componentDidMount() {
    const box = this.ref.current
    if (!box) return

    this.observer = new ResizeObserver(this.checkSquash)
    let childrenLength = this.availableChildren().length

    const checkSquashAgain = () =>
      requestAnimationFrame(() => {
        this.checkSquash()
        if (--childrenLength === 0) return
        checkSquashAgain()
      })

    checkSquashAgain()
    this.observer.observe(box)
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  render() {
    const { squashedChildrenArray: childrenArray, ...boxProps } = this.props
    const currentChild = this.availableChildren()[this.index]
    const isFinalChild = this.availableChildren().length === this.index + 1
    return (
      <>
        {!(!currentChild || React.Children.toArray(currentChild).none((it) => !!it)) && (
          <div
            className={cn(
              this.props.className,
              this.props.squashedChildrenArray?.length && !isFinalChild && 'overflow-x-clip',
            )}
            ref={this.ref}>
            {currentChild}
          </div>
        )}
      </>
    )
  }
}
