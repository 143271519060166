import { Component } from 'react'
import { VideoController } from './VideoController'
import { VideoPlayerProps } from './VideoPlayerProps'
import { DEFAULT_VIDEO_PLAYER_STATUS, VideoPlayerStatus } from './VideoPlayerStatus'

export abstract class BaseVideoPlayer<StatusOwner>
  extends Component<VideoPlayerProps>
  implements VideoController
{
  seekOptions?: { time: number }
  timer?: NodeJS.Timer
  currentStatus: VideoPlayerStatus = { ...DEFAULT_VIDEO_PLAYER_STATUS }

  componentDidMount() {
    this.timer = setInterval(async () => {
      const player = this.getStatusOwner()
      if (!player) return
      const status = await this.getPlayerStatus(player)
      if (this.currentStatus.playbackState !== status.playbackState)
        this.props.onStateChange(status.playbackState)
      Object.assign(this.currentStatus, status)
    }, 1000 / 30)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  abstract getStatusOwner(): StatusOwner | undefined

  async seekTo(time: number): Promise<void> {
    const newOptions = { time }
    this.seekOptions = newOptions
    setTimeout(() => {
      if (this.seekOptions === newOptions) {
        this.seekOptions = undefined
      }
    }, 1000)
    await this.seekToInternal(time)
  }

  async scrubTo(time: number): Promise<void> {
    const newOptions = { time }
    this.seekOptions = newOptions
    setTimeout(() => {
      if (this.seekOptions === newOptions) {
        this.seekOptions = undefined
      }
    }, 1000)
    await this.scrubToInternal(time)
  }

  async seekRelative(time: number): Promise<void> {
    await this.seekTo(Math.max(this.currentStatus.currentTime + time, 0))
  }

  async getPlayerStatus(player: StatusOwner): Promise<VideoPlayerStatus> {
    const status = await this.getPlayerStatusInternal(player)
    return Object.assign(status, {
      currentTime: this.seekOptions === undefined ? status.currentTime : this.seekOptions.time,
    })
  }

  protected abstract getPlayerStatusInternal(player: StatusOwner): Promise<VideoPlayerStatus>

  abstract pause(): Promise<void>

  abstract play(): Promise<void>

  protected abstract seekToInternal(time: number): Promise<void>

  protected abstract scrubToInternal(time: number): Promise<void>

  abstract setPlaybackRate(suggestedRate: number): Promise<void>
}
