import { FirebaseActivityType, FirebaseVideoSource } from './common'

export namespace StatRecordStorage {
  export const mapFirebaseFromStatRecordAlias = (
    entry: StatRecordAlias,
  ): FirebaseStatRecordAliasEntry => ({
    ...entry,
    watcherOnly: entry.watcherOnly ?? null,
    allLinks: entry.allLinks ?? null,
  })

  export const mapFirebaseToStatRecordAlias = (
    entry: FirebaseStatRecordAliasEntry,
  ): StatRecordAlias => {
    const { allTeams, watcherOnly, allLinks, ...teams } = entry

    return {
      allTeams,
      watcherOnly: watcherOnly ?? undefined,
      allLinks: {
        ...allLinks,
        ...Object.entries(teams).reduce((acc, [team, statRecordId]) => {
          return {
            ...acc,
            [statRecordId]: {
              [parseInt(team)]: 'hidden' satisfies StatRecordTeamVisibility,
            } satisfies StatRecordAlias['allLinks'][string],
          }
        }, {}),
      } satisfies StatRecordAlias['allLinks'],
    }
  }

  export const mapFirebaseFromStatRecord = (entry: StatRecord): FirebaseStatRecordEntry => {
    return {
      ...entry,
    }
  }

  export const mapFirebaseToStatRecord = (entry: FirebaseStatRecordEntry): StatRecord => {
    return {
      ...entry,
      rules: {
        ...(entry.teams || entry.rules ?
          {}
        : {
            0: entry.team === 0 || entry.team === undefined ? 'stats_unlocked' : 'hidden',
            1: entry.team === 1 || entry.team === undefined ? 'stats_unlocked' : 'hidden',
          }),
        ...entry.teams,
        ...entry.rules,
      },
    }
  }
}

export type FirebaseStatRecordEntry = {
  source: FirebaseVideoSource
  reviewId: string
  videoId: string
  activityType: FirebaseActivityType
  /**
   * "no_stats" means no access to stats
   *
   * @deprecated
   */
  team?: number | null
  /**
   * @deprecated
   */
  teams?: {
    [team: number]: StatRecordTeamVisibility
  }
  rules?: {
    [team: number]: StatRecordTeamVisibility
  }
  statsTemplateKey: string | null
}

export type StatRecord = {
  source: FirebaseVideoSource
  reviewId: string
  videoId: string
  activityType: FirebaseActivityType
  rules: {
    [team: number]: StatRecordTeamVisibility
  }
  statsTemplateKey: string | null
}

export function isVisible(visibility: StatRecordTeamVisibility) {
  return visibility && visibility !== 'hidden'
}

export function matchingStatRecordTeamRules(
  statRecord: StatRecord,
  rule: 'visible' | 'stats_unlocked',
): number[] {
  return matchingTeamsRules(statRecord.rules, rule)
}

export function matchingTeamsRules(
  rules: StatRecord['rules'],
  rule:'not_visible'| 'visible' | 'stats_unlocked',
): number[] {
  if (rule === 'visible')
    return Object.entries(rules).mapNotNull(([team, visibility]) =>
      visibility === 'stats_locked' || visibility === 'stats_unlocked' ? parseInt(team) : undefined,
    )

  if (rule === 'stats_unlocked')
    return Object.entries(rules).mapNotNull(([team, visibility]) =>
      visibility === 'stats_unlocked' ? parseInt(team) : undefined,
    )

  if (rule === 'not_visible')
    return Object.entries(rules).mapNotNull(([team, visibility]) =>
      visibility === 'hidden' ? parseInt(team) : undefined,
    )

  return []
}

export type StatRecordTeamVisibility =
  | 'hidden'
  | 'stats_locked'
  | 'stats_unlocked'
  | undefined
  | null

export type FirebaseStatRecordAliasEntry = {
  allTeams: string
  watcherOnly?: string | null
  [team: number]: string
  /* This may not include the allTeams and watcherOnly Links */
  allLinks?: { [statRecordId: string]: StatRecord['rules'] } | null
}

export type StatRecordAlias = {
  allTeams: string
  watcherOnly?: string
  allLinks: { [statRecordId: string]: StatRecord['rules'] }
}

export function findExactAlias(
  alias: StatRecordAlias,
  rules: {
    [team: number]: StatRecordTeamVisibility
  },
):
  | undefined
  | {
      statRecordId: string
      teamVisibilities: {
        [team: number]: StatRecordTeamVisibility
      }
    } {
  const link = Object.entries(alias.allLinks).find(([_, teamVisibilities]) => {
    const teams = Object.keys(teamVisibilities)
      .concat(Object.keys(rules))
      .map((i) => parseInt(i))
      .distinct()

    return teams.every((team) => {
      return rules[team] === teamVisibilities[team]
    })
  })

  if (!link) return undefined

  const [statRecordId, teamVisibilities] = link
  return {
    statRecordId,
    teamVisibilities,
  }
}

export function createStatsRecordLink({ statRecordId }: { statRecordId: string }) {
  const shareCanonical = new URL(`${window.location.origin}/statsrecord`)
  shareCanonical.searchParams.set('id', statRecordId)
  return shareCanonical.toString()
}
