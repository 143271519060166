import { BoxProps } from '@chakra-ui/react'
import React, { CSSProperties, HTMLAttributes, HtmlHTMLAttributes } from 'react'
import { HoverableBox, HoverableImage } from '../UseHover'
import { cn } from './utils/tailwindUtils'

export type IconInfo = string | { src: string; filter?: string; opacity?: string }

export interface BackgroundIconProps {
  src?: IconInfo
  alt?: string
  disableContextMenu?: boolean
  className?: string
  style?: CSSProperties
}

/**
 * For when we really don't want context menus
 * @param props
 * @constructor
 */
export function BackgroundIcon(props: BackgroundIconProps & HTMLAttributes<HTMLImageElement>) {
  const { src, alt, disableContextMenu, ...imageProps } = props
  if (!props.disableContextMenu)
    return (
      <>
        {typeof src === 'string' && (
          <img
            className={props.className}
            style={props.style}
            draggable={false}
            {...imageProps}
            src={src}
            alt={props.alt}
          />
        )}

        {typeof src !== 'string' && (
          <img
            className={cn(props.className)}
            draggable={false}
            {...imageProps}
            style={{
              ...props.style,
              opacity: props.style?.opacity ?? src?.opacity,
              filter: props.style?.filter ?? src?.filter,
            }}
            src={src?.src}
            alt={props.alt}
          />
        )}
      </>
    )
  return (
    <div
      {...imageProps}
      className={props.className}
      style={{
        ...props.style,
      }}>
      {typeof src === 'string' && (
        <div
          style={{
            objectFit: 'inherit',
            backgroundImage: props.src && `url('${props.src}')`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
          }}
        />
      )}

      {typeof src !== 'string' && (
        <div
          style={{
            backgroundImage: src && `url('${src.src}')`,
            filter: src?.filter,
            opacity: src?.opacity,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </div>
  )
}
