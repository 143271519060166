import { useCallback, useEffect, useMemo, useState } from 'react'
import { FirebaseDb } from '../components/common/Firebase'
import { useQueryString } from '../components/common/utils/QueryString'
import { VideoSourceData } from './ReviewSelectionInit'
import { FirebaseVideoSource } from './common'

export type SetReviewSelectionStateProps = {
  reviewId: string
  type: 'reviewId'
}

export function useReviewSelectionState(
  firebaseDb: FirebaseDb,
  localStorage: Storage,
  // reviewId?: string,
  // sourceData?: VideoSourceData,
): {
  reviewId?: string
  videoSourceData?: VideoSourceData
  isNewReview?: boolean
  isLocalReview: boolean
} {
  const [queryReviewId, setQueryParamReviewIdInternal] = useQueryString('id', undefined)
  const [selectionState, setSelectionStateInternal] = useState<{
    reviewId?: string
    sourceData?: VideoSourceData
    isNewReview?: boolean
    isLocalReview?: boolean
  }>()

  const setReviewSelection = useCallback(
    (props: SetReviewSelectionStateProps) => {
      switch (props.type) {
        case 'reviewId':
          Promise.all([
            firebaseDb.getVal<string>(`reviews/${props.reviewId}/videoId`),
            firebaseDb.getVal<FirebaseVideoSource | undefined>(`reviews/${props.reviewId}/source`),
          ]).then(([videoId, source]) => {
            if (videoId) {
              const videoDataSource = mapReviewVideoDataSourceFromFirebase(videoId, source)
              const storedReviewId =
                localStorage.getItem(`videoId:${videoId}:${source}`) ||
                // backwards compatibility
                localStorage.getItem(`videoId:${videoId}`)
              setSelectionStateInternal({
                sourceData: videoDataSource,
                reviewId: props.reviewId,
                isLocalReview: !!storedReviewId && storedReviewId === props.reviewId,
              })
              setQueryParamReviewIdInternal(props.reviewId)
            }
          })
          break
      }
    },
    [firebaseDb, localStorage, setQueryParamReviewIdInternal],
  )

  useEffect(() => {
    if (!selectionState?.reviewId && !selectionState?.sourceData && queryReviewId) {
      // Load from query param
      setReviewSelection({ type: 'reviewId', reviewId: queryReviewId })
    }
  }, [selectionState, queryReviewId, setReviewSelection])

  return useMemo(
    () => ({
      reviewId: selectionState?.reviewId,
      isNewReview: selectionState?.isNewReview,
      videoSourceData: selectionState?.sourceData,
      isLocalReview: !!selectionState?.isLocalReview,
    }),
    [
      selectionState?.reviewId,
      selectionState?.isNewReview,
      selectionState?.isLocalReview,
      selectionState?.sourceData,
    ],
  )
}

export function mapReviewVideoDataSourceFromFirebase(
  videoId: string,
  source: FirebaseVideoSource | undefined,
): VideoSourceData {
  return { id: videoId, source: source ?? 'Youtube' }
}
