import { ThreeDots } from 'react-loading-icons'
import { cn } from './common/utils/tailwindUtils'

export function PageLoader(props: { className?: string; title?: string }) {
  return (
    <div className={cn('flex h-full w-full flex-col items-center justify-center', props.className)}>
      {props.title && (
        <h1 className='font-montserrat text-2xl text-white font-bold'>{props.title}</h1>
      )}
      <ThreeDots />
    </div>
  )
}
