import { EditIcon } from '@chakra-ui/icons'
import { Box, Flex, Image } from '@chakra-ui/react'
import { cn } from 'components/common/utils/tailwindUtils'
import * as CSS from 'csstype'
import { DocumentPermissions, MetaStatsCache, RecentsFirebaseEntry } from 'data/common'
import { User } from 'firebase/auth'
import React, { PropsWithChildren, RefObject, useCallback, useMemo } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { InView } from 'react-intersection-observer'
import { useFeatureFlagAdminStore } from 'ui/AdminManagementDialog'
import { TimelineStorage } from './AppDataRepository'
import { Colors } from './Colors'
import useHover from './components/UseHover'
import { EditableStringDiv } from './components/common/EditableStringDiv'
import { FirebaseDb } from './components/common/Firebase'
import { usePromiseState } from './components/common/utils/reactUtils'
import { BroadcastFirebaseEntry, getReviewEntryRefs } from './data/ReviewSelectionInit'
import binIcon from './icons/bin_icon.png'
import bulletListIcon from './icons/bulletListIcon.png'
import comments_icon from './icons/comment.png'
import linkIcon from './icons/link_icon.svg'
import penBlockIcon from './icons/penBlockIcon.png'
import penEditIcon from './icons/penEditIcon.png'
import userIcon from './icons/userIcon.png'
import viewIcon from './icons/viewIcon.png'
import { AppScore } from './ui/AppScore'
import { useReviewMetaStore } from './ui/ReviewMetaStore'
import { clampColorString } from './util/color'
import { getThumbnail } from './util/links'

import mapFirebaseToMetaStats = TimelineStorage.mapFirebaseToMetaStats

type ReviewTileProps<
  T extends Omit<RecentsFirebaseEntry, 'metaStats' | 'lastVisited'> | BroadcastFirebaseEntry,
> = {
  entry: T &
    (
      | {
          time: number
          timeLabel: string
        }
      | {
          time?: undefined
          timeLabel?: undefined
        }
    ) &
    MetaStatsCache
  href?: string
  onClick?: (entry: T) => void
  firebaseDb: FirebaseDb
  user?: User
  width?: string
  dateFormat?: 'datetime' | 'date'
  deletable?: boolean
  onDelete?: () => void
  disableEditing?: boolean
  className?: string
  order?: (hasChanged: boolean) => number | undefined
}

export function LazyScrollReviewTile<
  T extends Omit<RecentsFirebaseEntry, 'metaStats' | 'lastVisited'> | BroadcastFirebaseEntry,
>(props: ReviewTileProps<T> & { scrollableParent: RefObject<Element> }) {
  return (
    <InView
      root={props.scrollableParent.current}
      triggerOnce={true}
      threshold={0}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className='min-h-20 w-full'>
          {inView && <ReviewTile {...props} />}
        </div>
      )}
    </InView>
  )
}

export function ReviewTile<
  T extends Omit<RecentsFirebaseEntry, 'metaStats' | 'lastVisited'> | BroadcastFirebaseEntry,
>(props: ReviewTileProps<T>) {
  const [isHover, anchorRef] = useHover<HTMLAnchorElement>()
  const { order = (hasChanged) => undefined, disableEditing = false } = props
  const reviewMetaStore = useReviewMetaStore(
    props.firebaseDb,
    props.user,
    props.entry.reviewId ?? undefined,
    undefined,
    undefined,
    undefined,
  )
  const { usersDictionary } = useFeatureFlagAdminStore(props.firebaseDb)

  const cachedMetaStats = props.entry.metaStats
  const commentsSeen =
    (props.user?.uid ?
      props.entry.metaStatsUserCache?.[props.user?.uid]?.commentCount
    : undefined) ??
    props.entry.metaStats?.commentCount ??
    0
  const newMetaStats = usePromiseState(async () => {
    const metaStats = (await reviewMetaStore.initMeta?.()) ?? undefined

    // Update the metaStats for the entry
    if (
      props.user &&
      JSON.stringify(metaStats) !== JSON.stringify(props.entry.metaStats) &&
      props.entry.reviewId
    ) {
      const refs = await getReviewEntryRefs({
        reviewOwner: props.entry.ownerUID ?? undefined,
        user: props.user,
        reviewId: props.entry.reviewId,
        firebaseDb: props.firebaseDb,
      })
      refs.forEach((ref) => {
        try {
          ref.get().then((snapshot) => {
            // We caused a bug here by not checking if the snapshot exists.
            // This could write to a nonexisting entry in the user's review list
            if (snapshot.exists()) return

            // This is a fix for the bug mentinoned above
            if (!snapshot.val()?.['reviewId']) {
              ref.remove()
              return
            }

            ref.child('metaStats').set(metaStats)
          })
        } catch (e) {
          console.error(e)
        }
      })
    }

    return metaStats
  }, [
    reviewMetaStore.initMeta,
    props.firebaseDb,
    props.entry.metaStats,
    props.entry.reviewId,
    props.user,
  ])

  const correctMetaStats = newMetaStats ?? props.entry.metaStats

  const handleOnClick = useCallback(
    (e: React.MouseEvent) => {
      if (!props.onClick) {
        anchorRef.current?.click()
      }
      props.onClick?.(props.entry)

      // window.location.href = `/?id=${props.entry.reviewId}&mode=${props.entry.mode || 'edit'}`
    },
    [props, anchorRef],
  )

  const visits = useMemo(
    () => correctMetaStats?.visits && Object.values(correctMetaStats.visits),
    [correctMetaStats],
  )
  const usersCount = useMemo(() => visits?.length, [visits])
  const visitsCount = useMemo(() => visits?.sum(), [visits])

  const firebaseMetaStats = correctMetaStats
  const hasNewComments =
    !!correctMetaStats?.commentCount && commentsSeen !== correctMetaStats?.commentCount
  const hasRequestedChanges =
    !!correctMetaStats?.requestedChangesCount ||
    (correctMetaStats?.requestedChangesCount === undefined &&
      !!cachedMetaStats?.requestedChangesCount)
  const metaStats = firebaseMetaStats && mapFirebaseToMetaStats(firebaseMetaStats)
  const handleTitleChange = useCallback(
    async (title: string, mode: DocumentPermissions) => {
      reviewMetaStore.changeTitle?.(title, mode)
    },
    [reviewMetaStore],
  )

  const thumbnail = usePromiseState(
    () => getThumbnail(props.entry.videoId, props.entry.source),
    [props.entry.videoId, props.entry.source],
  )
  const { mode } = props.entry
  const iconSrc = mode === 'edit' ? penEditIcon : penBlockIcon
  return (
    <a
      ref={anchorRef}
      className={cn(
        `relative flex h-20 flex-row items-center overflow-x-clip rounded bg-white text-[14px]
        transition-transform duration-200 ease-in-out hover:scale-110`,
        props.className,
      )}
      style={{
        transition: 'all 200ms ease-in-out',
        width: props.width,
        opacity: props.entry.reviewId ? 1.0 : 0.5,
        order: order(hasNewComments || hasRequestedChanges),
        pointerEvents: handleOnClick === undefined && props.href === undefined ? 'none' : undefined,
      }}
      href={props.href}
      onClick={handleOnClick}>
      <img
        alt={''}
        src={thumbnail}
        className='h-full w-[120px] shrink-0 grow-0 object-cover'
      />
      {isHover && props.entry.timeLabel && props.entry.time && (
        <div className='absolute bottom-20 right-[190px] whitespace-nowrap text-[#bdbdbd] font-light'>
          {props.entry.timeLabel}:{' '}
          {props.dateFormat === 'datetime' ?
            new Date(props.entry.time).toLocaleString()
          : new Date(props.entry.time).toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          }
        </div>
      )}
      <div className='relative mb-4 p-4 text-start'>
        <EditableStringDiv
          className='bg-white leading-[0.9em] text-black font-medium'
          onSubmit={(value) =>
            handleTitleChange(
              value,
              props.entry.mode ?? (props.entry.ownerUID === props.user?.uid ? 'edit' : 'view'),
            )
          }
          blurOnEnter={true}
          editOn={'dblclick'}
          onClick={handleOnClick}
          translate={'no'}
          disabled={props.entry.mode !== 'edit' || disableEditing}
          placeholder={'Enter a title'}>
          {(reviewMetaStore.title ?? props.entry.title ?? '').length > 70 ?
            (reviewMetaStore.title ?? props.entry.title ?? '').slice(0, 70) + '...'
          : (reviewMetaStore.title ?? props.entry.title ?? '')}
        </EditableStringDiv>
      </div>
      <div
        className='absolute bottom-[9px] left-[135px]'
        style={{
          color: 'rgba(221, 61, 78, 0.44)',
        }}>
        {!isHover && props.entry.isAttachedToStatsRecord && (
          <InlinePill backgroundColor={Colors.color_black}>
            <Image
              width={8}
              src={linkIcon}
            />{' '}
            Linked Stats
          </InlinePill>
        )}
        {isHover && props.entry.isAttachedToStatsRecord && (
          <InlinePill backgroundColor={Colors.color_black}>
            <Image
              width={8}
              src={linkIcon}
            />
          </InlinePill>
        )}
        {!isHover && props.entry.isPublicStatsRecord && (
          <InlinePill>
            <Image
              className='relative top-[3px] h-3 w-3.5'
              src={bulletListIcon}
            />{' '}
            Stats Record
          </InlinePill>
        )}
        {isHover && props.entry.isPublicStatsRecord && (
          <InlinePill>
            <Image
              className='relative top-[3px] h-3 w-3.5'
              src={bulletListIcon}
            />
          </InlinePill>
        )}{' '}
        <span
          className='relative top-1.5 capitalize'
          style={{
            fontVariationSettings: '"wght" 600',
          }}>
          <img
            className='relative mr-[5px] h-5 w-5'
            src={iconSrc}
          />
        </span>
      </div>
      {!isHover && (
        <Box
          position={'absolute'}
          right={4}
          style={{ fontVariationSettings: "'wght' 700" }}
          color={'rgb(84,84,84)'}
          bottom={7}>
          {visitsCount && (
            <>
              {visitsCount}{' '}
              <span style={{ top: '2px', fontVariationSettings: "'wght' 300" }}>
                <Image
                  className='relative right-1 top-1 h-[18px] w-[18px]'
                  src={viewIcon}
                />
              </span>{' '}
            </>
          )}
        </Box>
      )}
      {isHover && (
        <Box
          position={'absolute'}
          right={4}
          style={{ fontVariationSettings: "'wght' 700" }}
          color={'rgb(84,84,84)'}
          bottom={7}>
          {isHover && visitsCount && (
            <>
              {visitsCount}{' '}
              <span style={{ fontVariationSettings: "'wght' 300" }}>
                <Image
                  className='relative right-1 top-1 h-[18px] w-[18px]'
                  src={viewIcon}
                />
              </span>{' '}
              {usersCount}{' '}
              <span style={{ fontVariationSettings: "'wght' 300" }}>
                <Image
                  className='relative right-1 top-0.5 h-3.5 w-3.5'
                  src={userIcon}
                />
              </span>
            </>
          )}
        </Box>
      )}
      {isHover && props.onDelete && (
        <Image
          _hover={{
            transform: 'scale(120%)',
          }}
          src={binIcon}
          className='absolute right-0 top-0 h-5 w-5'
          style={{
            transition: 'all 200ms ease-in-out',
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.onDelete?.()
          }}
        />
      )}
      {(hasNewComments || hasRequestedChanges) && (
        <Flex
          direction={'row'}
          gap={4}
          position={'absolute'}
          width={'fit-content'}
          top={4}
          right={4}>
          {hasRequestedChanges && (
            <EditIcon
              width={8}
              height={8}
              color={'red'}
              borderRadius={99}
            />
          )}
          {hasNewComments && (
            <Box
              width={8}
              height={8}
              backgroundColor={'red'}
              borderRadius={99}
            />
          )}
        </Flex>
      )}
      <Flex
        position={'absolute'}
        justifyContent={'space-evenly'}
        padding={'4px'}
        bottom={0}
        left={0}
        boxSizing={'border-box'}
        width={120}
        gap={'4px'}
        color={'white'}
        alignItems={'center'}>
        <Flex
          minWidth={30}
          height={15}
          alignItems={'center'}
          filter={'drop-shadow(1px 1px 2px rgb(0,0,0,1)) drop-shadow(-1px -1px 2px rgb(0,0,0,1))'}>
          {!!metaStats?.commentCount && (
            <>
              <img
                src={comments_icon}
                style={{ height: '20px' }}
              />
              {metaStats?.commentCount}
            </>
          )}
        </Flex>
        <Flex
          maxWidth={16}
          flexShrink={1}
          filter={
            'drop-shadow(1px 1px 2px rgb(0,0,0,0.5)) drop-shadow(-1px -1px 2px rgb(0,0,0,0.5))'
          }
          flexGrow={0}>
          {!!metaStats?.trackingProgress && (
            <CircularProgressbar
              value={metaStats.trackingProgress * 100}
              strokeWidth={18}
              styles={buildStyles({
                trailColor: 'none',
                strokeLinecap: 'round',
                pathColor: clampColorString({
                  value: metaStats.trackingProgress,
                  maxColor: [109, 64, 55],
                  midColor: [20, 80, 62],
                  minColor: [0, 100, 62],
                }),
              })}
            />
          )}
        </Flex>
        <Flex
          width={'fit-content'}
          flexShrink={1}
          flexGrow={0}
          visibility={
            (
              metaStats?.score &&
              !!(metaStats.score.win || metaStats.score.lose || metaStats.score.draw)
            ) ?
              'visible'
            : 'hidden'
          }>
          <AppScore
            // shadows={false}
            showScoreTitle={false}
            liveScore={
              metaStats ?
                [metaStats.score.win, metaStats.score.lose, metaStats.score.draw]
              : [0, 0, 0]
            }
            scale={0.5}
            style={{
              position: 'relative',
              boxShadow: 'none',
              bottom: 0,
              margin: 'none',
            }}
          />
        </Flex>
      </Flex>
      {isHover && props.entry.ownerName && (
        <div className='absolute -bottom-4 right-3 whitespace-nowrap text-[#bdbdbd] font-light'>
          by{' '}
          {props.entry.isCreatedByStaff ?
            usersDictionary && props.user && usersDictionary[props.user.uid] ?
              `Playback Stats Team ${usersDictionary[props.user.uid]?.nickname || props.entry.ownerName}`
            : 'Playback Stats Team'
          : props.entry.ownerName}
        </div>
      )}
    </a>
  )
}

function InlinePill(props: PropsWithChildren & { backgroundColor?: CSS.Property.Color }) {
  return (
    <span
      className='relative rounded-full px-[5px] py-0 text-white'
      style={{
        backgroundColor: props.backgroundColor ?? Colors.color_playback_crimson,
      }}>
      {props.children}
    </span>
  )
}
