import * as React from 'react'
import { useEffect, useMemo } from 'react'

type BaseEvent = {
  stopPropagation?: () => void
  clientX?: number
  clientY?: number
}
type ClickHandlerOptions<T extends BaseEvent = React.PointerEvent> = {
  actionSingleClick?: (event: T) => void
  actionDoubleClick?: (event: T) => void
  actionLongPress?: (event: T) => void
  stopPointerUpPropagation?: boolean
  stopPointerDownPropagation?: boolean
  doubleClickCheckDelay?: number
  longPressDelay?: number
  moveThreshold?: number
}

export class SingleAndDoubleClickHandler<
  T extends {
    stopPropagation?: () => void
    clientX?: number
    clientY?: number
  } = React.PointerEvent,
> {
  private clicksCount = 0
  private longPressTriggered = false
  private timeout: any
  private startPoint: { x: number; y: number } = { x: 0, y: 0 }
  private listeners: {
    single?: (event: T) => void
    double?: (event: T) => void
    long?: (event: T) => void
  } = {}
  private opts: Required<
    Omit<ClickHandlerOptions, 'actionDoubleClick' | 'actionSingleClick' | 'actionLongPress'>
  >

  constructor(options?: ClickHandlerOptions<T>) {
    const { actionSingleClick, actionDoubleClick, actionLongPress, ...opts } = options ?? {}
    this.listeners = {
      single: actionSingleClick,
      double: actionDoubleClick,
      long: actionLongPress,
    }
    this.opts = {
      longPressDelay: 300,
      doubleClickCheckDelay: 250,
      moveThreshold: 10,
      stopPointerUpPropagation: false,
      stopPointerDownPropagation: false,
      ...opts,
    }
  }

  updateListeners(options: ClickHandlerOptions<T>) {
    this.listeners = {
      single: options.actionSingleClick,
      double: options.actionDoubleClick,
      long: options.actionLongPress,
    }
  }

  private updateClicksCount = (update: (count: number) => number, event: T) => {
    this.clicksCount = update(this.clicksCount)
    if (!this.listeners.double) {
      if (this.clicksCount === 1) this.listeners.single?.(event)
      this.clicksCount = 0
      return
    }

    const timer = setTimeout(() => {
      // simple click
      if (this.clicksCount === 1) this.listeners.single?.(event)
      this.clicksCount = 0
    }, this.opts.doubleClickCheckDelay)

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (this.clicksCount === 2) {
      this.listeners.double?.(event)
      this.clicksCount = 0
    }
    return () => clearTimeout(timer)
  }

  private longPressDown = (event: T, longPressDelay: number = this.opts.longPressDelay) => {
    this.timeout = setTimeout(() => {
      this.listeners.long?.(event)
      this.longPressTriggered = true
    }, longPressDelay)
  }

  private longPressCancel = (event: T, shouldTriggerClick = true) => {
    clearTimeout(this.timeout)
    if (shouldTriggerClick && !this.longPressTriggered) {
      this.updateClicksCount((it) => it + 1, event)
    }
    this.longPressTriggered = false
  }

  handleDown = (
    event: T,
    stopPointerDownPropagation: boolean = this.opts.stopPointerDownPropagation,
  ) => {
    if (stopPointerDownPropagation) {
      event.stopPropagation?.()
    }
    this.longPressDown(event)
    this.startPoint = { x: event.clientX ?? 0, y: event.clientY ?? 0 }
  }

  handleMove = (event: T, moveThreshold: number = this.opts.moveThreshold) => {
    const moveDistance = Math.hypot(
      this.startPoint.x - (event.clientX ?? 0),
      this.startPoint.y - (event.clientY ?? 0),
    )
    if (moveDistance > moveThreshold) {
      this.longPressCancel(event, false)
    }
  }

  handleUp = (event: T, stopPointerUpPropagation: boolean = this.opts.stopPointerUpPropagation) => {
    if (stopPointerUpPropagation) {
      event.stopPropagation?.()
    }
    this.longPressCancel(event)
  }
}

export function useSingleAndDoubleClick<T extends BaseEvent = React.PointerEvent>({
  actionSingleClick,
  actionDoubleClick,
  actionLongPress,
  stopPointerUpPropagation = false,
  stopPointerDownPropagation = false,
  doubleClickCheckDelay = 250,
  longPressDelay = 300,
  moveThreshold = 10,
}: {
  actionSingleClick?: (event: T) => void
  actionDoubleClick?: (event: T) => void
  actionLongPress?: (event: T) => void
  stopPointerUpPropagation?: boolean
  stopPointerDownPropagation?: boolean
  doubleClickCheckDelay?: number
  longPressDelay?: number
  moveThreshold?: number
}) {
  const handler = useMemo(
    () =>
      new SingleAndDoubleClickHandler<T>({
        stopPointerUpPropagation,
        stopPointerDownPropagation,
        doubleClickCheckDelay,
        longPressDelay,
        moveThreshold,
      }), // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    handler.updateListeners({
      actionSingleClick: actionSingleClick,
      actionDoubleClick: actionDoubleClick,
      actionLongPress: actionLongPress,
    })
  }, [actionSingleClick, actionDoubleClick, actionLongPress, handler])

  return {
    onDown: handler.handleDown,
    onMove: handler.handleMove,
    onUp: handler.handleUp,
  }
}
