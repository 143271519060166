import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons'
import { CloseButton, Flex } from '@chakra-ui/react'
import { showDialog } from 'components/common/Dialog'
import { cn } from 'components/common/utils/tailwindUtils'
import { FirebaseGroupDetails, FirebaseGroupPlayerProfileEntry } from 'data/common'
import React, {
  CSSProperties,
  Component,
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  forwardRef,
} from 'react'
import ReactTagManager from 'react-ga4'
import { ShortcutIcon } from '../components/ShortcutIcon'
import { EditableStringDiv } from '../components/common/EditableStringDiv'
import { Player } from '../data/Player'
import plusButtonIcon from '../icons/plus_button.png'

type DisplayStyle = 'icon-only' | 'name+icon' | 'expanding'
export type SelectionState = 'selected' | 'deselected' | 'normal'

type CommonPlayerTileProps = {
  player: Player
  size: number
  playerNameFontSize?: number
  shortcut?: string
  extra?: (player: Player) => ReactNode
  onPlayerClick?: (player: Player) => void
  onPlayerDblClick?: (player: Player) => void
  expandedWidth?: string
  onPlayerNameUpdate?: (player: Player, nameChange: string, approve?: boolean) => void
  onPlayerSubmit?: (player: Player) => void
  onPlayerDelete?: (player: Player) => void
  onFocus?: () => void
  onBlur?: () => void
  onRequestEditMode?: () => void
  selectionState?: SelectionState
  editMode: boolean
  canEnableEditing: boolean
  focusOnMountIfExpanded?: boolean
  hideRequestChangeActions?: boolean
  groupPlayerNames?: string[] | undefined
}

type EditablePlayerTileProps = CommonPlayerTileProps & {
  onPlayerNameUpdate: (
    player: Player & (Player | Pick<Player, 'id' | 'index' | 'name'>),
    nameChange: string,
    approve?: boolean,
  ) => void
  onPlayerDelete?: (player: Player) => void
  onFocus?: () => void
  onBlur?: () => void
} & (
    | {
        displayStyle?: 'name+icon'
        expandedWidth: string
      }
    | {
        displayStyle: 'expanding'
        expandedWidth: string
      }
  )

type NonEditablePlayerTileProps = CommonPlayerTileProps & {
  displayStyle: 'icon-only'
}

type PlayerTileProps = EditablePlayerTileProps | NonEditablePlayerTileProps

interface PlayerTileState {
  expanded: boolean
}

export class PlayerTile extends Component<PlayerTileProps, PlayerTileState> {
  state = {
    expanded: this.props.displayStyle === 'name+icon',
    initialName: this.props.player.name,
  }
  editableRef = React.createRef<EditableStringDiv>()
  iconRef = React.createRef<HTMLDivElement>()

  private getMode = () => {
    return this.props.displayStyle || 'name+icon'
  }

  private handleOnIconClick = (e: React.MouseEvent) => {
    const mode = this.getMode()
    e.stopPropagation()
    this.props.onPlayerClick?.(this.props.player)
    this.setState((state) => {
      return {
        ...state,
        expanded: mode === 'expanding' && !state.expanded,
      }
    })
  }
  getIconRect = () => {
    return this.iconRef.current?.getBoundingClientRect()
  }
  private handleOnIconDblClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.props.onPlayerDblClick?.(this.props.player)
  }
  private handleNameChange = (name: string, approve?: boolean) => {
    if (this.props.groupPlayerNames !== undefined) return
    this.props.onPlayerNameUpdate?.(this.props.player, name.trim(), approve)
  }
  private handleNameFocus = () => {
    this.props.onFocus?.()
  }
  private handleNameBlur = () => {
    const name = this.editableRef.current?.value()
    if (!this.state.initialName.length && name?.length) {
      ReactTagManager.event(
        {
          action: 'player_submitted',
          category: 'player',
        },
        { name },
      )
    } else if (this.state.initialName !== name) {
      ReactTagManager.event({
        action: 'player_update',
        category: 'player',
      })
    }
    this.props.onBlur?.()
  }
  private handleSubmit = (value: string) => {
    if (!value.trim()) {
      this.props.onPlayerDelete?.(this.props.player)
    } else {
      this.props.groupPlayerNames !== undefined &&
        this.props.onPlayerNameUpdate?.(this.props.player, value.trim())
      this.props.onPlayerSubmit?.({
        ...this.props.player,
        name: value.replace(/\s+/g, ' ').trim(),
      })
    }
  }

  private handleRemoveClicked = (onPlayerDelete: (player: Player) => void) => {
    showDialog({
      title: 'Do you want remove this player?',
      positiveButtonProps: {
        text: 'Yes',
        onClicked: () => {
          onPlayerDelete(this.props.player)

          return true
        },
      },
      negativeButtonProps: { text: 'Cancel', onClicked: () => true },
    })
  }

  render() {
    const selectionState: SelectionState = this.props.selectionState || 'normal'
    const mode: DisplayStyle = this.getMode()
    const player = this.props.player
    const ExtraChild = this.props.extra?.(player)
    const expanded = mode === 'name+icon' || (mode === 'expanding' && this.state.expanded)
    let containerWidth: string | undefined
    switch (mode) {
      case 'icon-only':
        containerWidth = `${this.props.size}px`
        break
      case 'name+icon':
        containerWidth = this.props.expandedWidth
        break
      case 'expanding':
        if (this.state.expanded) containerWidth = this.props.expandedWidth
        else containerWidth = `${this.props.size}px`
        break
    }
    return (
      <PlayerTileContainer
        className={cn('group relative')}
        selectionState={selectionState}
        style={{
          height: `${this.props.size}px`,
        }}>
        {expanded && (
          <Flex
            direction={'column'}
            gap={4}
            className='box-border flex h-full w-full flex-wrap content-center justify-center rounded-full
              bg-[hsla(191,39%,25%,80%)] text-center align-middle font-league-spartan text-white'
            style={{
              paddingLeft: (this.props.size * 5) / 4,
              paddingRight: this.props.size / 2,
              maxWidth: this.props.expandedWidth,
            }}
            translate={'no'}>
            {player.requestedChanges?.name.changes ?
              <div
                translate={'no'}
                className='text-[#FF4136] line-through'
                style={{
                  fontSize: (this.props.playerNameFontSize ?? this.props.size * 0.6) * 0.8,
                }}>
                {player.name.trim()}
              </div>
            : undefined}
            <Flex gap={8}>
              <EditableStringDiv
                ref={this.editableRef}
                className='font-leagueSpartan whitespace-nowrap'
                style={{
                  fontSize: this.props.playerNameFontSize ?? this.props.size * 0.6,
                }}
                onDoubleClick={() => {
                  if (this.props.canEnableEditing) {
                    this.props.onRequestEditMode?.()
                  }
                }}
                suggestions={this.props.groupPlayerNames}
                disabled={!this.props.editMode}
                editOn='dblclick'
                onFocus={this.handleNameFocus}
                onChange={this.handleNameChange}
                focusOnMount={this.props.focusOnMountIfExpanded}
                onSubmit={this.handleSubmit}
                blurOnEnter={true}
                onBlur={this.handleNameBlur}
                placeholder={'Enter name'}
                translate={'no'}>
                {player.requestedChanges?.name.changes ?? player.name.trim()}
              </EditableStringDiv>
              {player.requestedChanges?.name.changes &&
                player.source === 'local' &&
                !this.props.hideRequestChangeActions && (
                  <>
                    <CheckCircleIcon
                      className='hover:text-[#2ECC40]'
                      onClick={(e) => {
                        e.stopPropagation()
                        player.requestedChanges?.name.changes &&
                          this.handleNameChange(player.requestedChanges.name.changes, true)
                      }}
                    />
                    <CloseButton
                      className='hover:text-[#2ECC40]'
                      onClick={(e) => {
                        e.stopPropagation()
                        player.requestedChanges?.name.changes &&
                          this.handleNameChange(player.requestedChanges.name.changes, false)
                      }}
                    />
                  </>
                )}
            </Flex>
          </Flex>
        )}
        {ExtraChild && (
          <div
            className={'transition-all duration-200'}
            translate={'no'}>
            {ExtraChild}
          </div>
        )}
        <PlayerTileIcon
          className={cn('left-0', expanded && 'absolute')}
          ref={this.iconRef}
          selectionState={selectionState}
          backgroundColor={this.props.player.color}
          size={this.props.size}
          draggable={true}
          onClick={this.handleOnIconClick}
          onDoubleClick={this.handleOnIconDblClick}>
          <span translate={'no'}>{getPlayerInitials(player)}</span>
        </PlayerTileIcon>
        {this.props.shortcut && (
          <ShortcutIcon
            className='absolute left-0 top-[-8px]'
            shortcut={this.props.shortcut}
            fontSize={`${this.props.size / 3}px`}
          />
        )}
        {player.source === 'stat_record' && (
          <Flex
            className='pointer-events-none absolute bottom-0 left-0 h-full w-full items-end justify-start text-xs'
            style={{
              fontVariationSettings: "'wght' 600",
              transform: 'translateY(30%)',
            }}>
            👁️‍🗨️
          </Flex>
        )}

        {this.props.editMode && this.props.onPlayerDelete && (
          <button
            className='hidden h-6 w-6 cursor-pointer items-center justify-center rounded-full border-none p-2
              group-focus-within:flex group-hover:flex hover:brightness-75'
            aria-label=''
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onClick={() => this.handleRemoveClicked(this.props.onPlayerDelete!)}>
            <DeleteIcon
              color={'black'}
              className='h-full w-full'
            />
          </button>
        )}
      </PlayerTileContainer>
    )
  }
}

export interface AddPlayerTileProps {
  size: number
  onSubmit?: (name: string) => void
  onClick: () => void
  displayStyle?: 'expanded' | 'circle'
  shortcut?: string
}

export class AddPlayerTile extends Component<AddPlayerTileProps> {
  render() {
    const displayStyle = this.props.displayStyle || 'expanded'
    return (
      <div
        className='] relative flex shrink-0 cursor-pointer flex-row items-center justify-center border-4 border-solid
          border-white/25 bg-[rgba(16,15,13,0.50)] font-league-spartan hover:bg-[#282c34]'
        style={{
          border: `${this.props.size / 15}px solid rgba(255, 255, 255, 0.26)`,
          borderRadius: this.props.size / 2,
          height: `${this.props.size}px`,
          minWidth: `${this.props.size}px`,
        }}
        onClick={this.props.onClick}>
        <img
          draggable={false}
          src={plusButtonIcon}
          style={{ width: this.props.size / 2, height: this.props.size / 2 }}
          alt={'assign a player to this event'}
          className='cursor-pointer'
        />
        {this.props.shortcut && (
          <ShortcutIcon
            className='absolute left-0 top-[-8px]'
            shortcut={this.props.shortcut}
            fontSize={`${this.props.size / 3}px`}
          />
        )}
      </div>
    )
  }
}

export function getInitials(words: string[]): string[] {
  // initials except including full multi digit when a word is just numbers
  return words.map((word) => {
    if (word.match(/^\d+$/)) {
      return word
    }
    return word[0]
  })
}

export function getPlayerInitials(player: Player | FirebaseGroupDetails): string {
  const splitName = player.name.split(/\s+/).filter((it) => it.replace(/[^a-zA-Z0-9]/g, ''))
  const initials = getInitials(splitName)

  const limitedInitals: string[] = []

  let initialsLength = 0
  for (const initial of initials) {
    if (initialsLength + initial.length <= 3 || initials.firstOrNull() === initial) {
      limitedInitals.push(initial)
      initialsLength += initial.length
    } else {
      break
    }
  }

  return limitedInitals.join('')
}

export function isNumeric(str: string | undefined) {
  return str && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function PlayerTileContainer(
  props: PropsWithChildren<{
    style?: CSSProperties
    className?: string
    selectionState?: SelectionState
  }>,
) {
  return (
    <div
      className={cn(
        'relative flex flex-row items-center justify-center font-league-spartan hover:opacity-100',
        props.selectionState === 'deselected' && 'opacity-50',
        props.selectionState === 'selected' && 'opacity-100',
        props.className,
      )}
      style={props.style}>
      {props.children}
    </div>
  )
}
const PlayerTileIcon = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    style?: CSSProperties
    className?: string
    selectionState?: SelectionState
    backgroundColor: string
    size: number
  }> &
    HTMLProps<HTMLDivElement>
>(function PlayerTileIcon({ size, selectionState, backgroundColor, ...props }, ref) {
  return (
    <div
      {...props}
      style={{ width: size, height: size, fontSize: size * 0.6, ...props.style }}
      className={cn(
        'pointer-events-auto relative box-border rounded-full p-[2px]',
        props.className,
      )}>
      <div
        ref={ref}
        className={cn(
          `relative flex aspect-square h-full shrink-0 cursor-grab select-none items-center justify-center
          rounded-full uppercase text-white transition-all duration-200 font-semibold group-hover:scale-[120%]`,
          `[&_span]:bg-inherit [&_span]:bg-clip-text [&_span]:text-transparent [&_span]:brightness-[2]
          [&_span]:contrast-[30] [&_span]:grayscale-[1] [&_span]:invert
          [&_span]:[filter:var(--tw-invert)_var(--tw-brightness)_var(--tw-grayscale)_var(--tw-contrast)]`,
          selectionState === 'selected' && 'outline outline-offset-2',
        )}
        style={{ backgroundColor: backgroundColor }}>
        {props.children}
      </div>
    </div>
  )
})
