/* eslint-disable @typescript-eslint/no-namespace */
export namespace Colors {
  export const color_grey = '#737373'
  export const color_blue_grey = '#3E5076'
  export const color_dark_grey = '#131920'
  export const color_green = '#0EAF53'
  export const color_blue = '#004AAD'
  export const color_red = '#BC2026'
  export const color_orange = '#FF9A24'
  export const color_comments_orange = 'rgb(241, 191, 70)'
  export const color_sketch_green = 'rgb(8, 150, 119)'
  export const color_default_draw = 'rgba(0,255,2,0.6)'
  export const color_playback_crimson = '#DD3D4E'
  export const color_black = '#000'
  export const color_white = '#fff'
  export const color_Lime = '#00FF00'
  export const color_lighter_red = '#F00000'
  export const color_Cyan = '#00FFFF'
  export const color_lighter_orange = '#FFA500'
}
