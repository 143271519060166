import { Flex } from '@chakra-ui/react'
import { FirebaseGroupTeamProfileEntry } from 'data/common'
import React, { CSSProperties, PropsWithChildren, useMemo } from 'react'
import { TeamStore } from '../hooks/UseTeamRepo'
import { EditableStringDiv } from './common/EditableStringDiv'
import { cn } from './common/utils/tailwindUtils'

export function LiveTeamEntry({
  teamStore,
  team,
  editable,
  canEnableEditing,
  placeholder,
  groupTeamNames,
}: {
  placeholder?: string
  teamStore: TeamStore
  editable: boolean
  canEnableEditing: boolean
  team: number
  groupTeamNames?: string[] | undefined
}) {
  return (
    <TeamEntry
      canEnableEditing={canEnableEditing}
      editable={editable}
      teamName={teamStore.teamNames?.[team]}
      placeholder={placeholder}
      groupTeamNames={groupTeamNames}
      onChange={(change) => teamStore.setTeamName(team, change)}
    />
  )
}

export function TeamEntry(
  props: InlinePillProps & {
    teamName: string | undefined
  },
) {
  return <InlinePill {...props}>{props.teamName}</InlinePill>
}

export type InlinePillProps = {
  backgroundColor?: CSSProperties['backgroundColor']
  placeholder?: string
  className?: string
  groupTeamNames?: string[] | undefined
} & (
  | {
      canEnableEditing: true
      editable: boolean
      onChange: (changed: string) => void
    }
  | {
      canEnableEditing?: false | undefined
      editable?: boolean
      onChange?: (changed: string) => void
    }
)

export function InlinePill({
  editable,
  canEnableEditing,
  placeholder,
  groupTeamNames,
  children,
  onChange,
  className,
  backgroundColor = '#284277',
}: PropsWithChildren<InlinePillProps>) {
  return (
    <Flex className={cn('flex flex-row items-center gap-1 font-league-spartan', className)}>
      {(children || canEnableEditing) && (
        <div className='hover:brightness-125'>
          <EditableStringDiv
            disabled={!editable || !onChange}
            placeholderStyle={{
              color: 'grey',
              border: '1px solid grey',
              backgroundColor: '#353A44',
            }}
            aria-multiline={false}
            blurOnEnter={true}
            placeholder={placeholder}
            onSubmit={onChange}
            suggestions={groupTeamNames}
            style={{
              backgroundColor,
              padding: '2px 8px',
              borderRadius: '10px',
              color: 'white',
              whiteSpace: 'nowrap',
            }}>
            {children}
          </EditableStringDiv>
        </div>
      )}
    </Flex>
  )
}
