import i18next from 'i18next'
import { Colors } from '../Colors'
import { LayeredIcon } from '../components/common/LayeredIconView'
import { PartialBy } from '../components/common/utils/typescriptUtils'
import '../components/common/utils/typescriptUtils'
import { ViewingMode } from '../data/ViewingMode'
import comment_icon from '../icons/comment.png'
import equals_icon from '../icons/equals_icon.png'
import lose_icon from '../icons/lose.png'
import replay_icon from '../icons/replay_button.png'
import score_icon from '../icons/score.png'
import squiggle_icon from '../icons/squiggle.svg'
import video_icon from '../icons/watch_button.png'
import win_icon from '../icons/win.png'
import GeneralTemplateLocales from './GeneralTemplateLocales'

import color_red = Colors.color_red
import color_white = Colors.color_white

type DataTag = 'Note' | 'Match Reset' | 'Point Win' | 'Point Lose' | 'Point Draw'

export type StatCategory =
  | EventDefinition
  | 'KillEvent'
  | 'Assistable'
  | 'HitDied'
  | 'CatchResult'
  | 'DodgeResult'
  | 'BlockResult'
  | 'DefenseResult'
  | 'RoundEnd'

const childCategoriesCache: Map<StatCategory, Map<boolean, EventDefinition[]>> = new Map()

export function matchChildCategoryKey(
  uiSetup: UISetup,
  category: StatCategory | StatCategory[],
  keyToCompare: string | undefined,
  includeCategoryInResults = true,
  cache: Map<StatCategory, Map<boolean, EventDefinition[]>> = childCategoriesCache,
) {
  if (!keyToCompare) return false
  if (category instanceof Array) {
    return category.any((it) =>
      getChildCategories(uiSetup, it, includeCategoryInResults, cache).any(
        (it) => it.key === keyToCompare,
      ),
    )
  }
  return getChildCategories(uiSetup, category, includeCategoryInResults, cache).any(
    (it) => it.key === keyToCompare,
  )
}

export function getChildCategories(
  uiSetup: UISetup,
  category: StatCategory,
  includeCategoryInResults = true,
  cache: Map<StatCategory, Map<boolean, EventDefinition[]>> = childCategoriesCache,
): EventDefinition[] {
  const directChildren = uiSetup.statCategories.get(category)
  if (!directChildren) {
    if (typeof category !== 'string') {
      return includeCategoryInResults ? [category] : []
    }
    throw new Error('Category not defined in hierarchy')
  }
  if (directChildren.length === 0) {
    if (typeof category !== 'string') {
      return includeCategoryInResults ? [category] : []
    }
    throw new Error('Category defined with no children')
  }

  let children: EventDefinition[]

  if (cache.has(category) && cache.get(category)?.has(includeCategoryInResults)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    children = cache.get(category)!.get(includeCategoryInResults)!
  } else {
    children = directChildren
      .flatMap((child) => getChildCategories(uiSetup, child, true, cache))
      .distinctBy((it) => it.key)

    let resultsMap: Map<boolean, EventDefinition[]>
    if (cache.has(category)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resultsMap = cache.get(category)!
    } else {
      resultsMap = new Map()
      cache.set(category, resultsMap)
    }

    if (typeof category !== 'string' && includeCategoryInResults) children.unshift(category)
    resultsMap.set(includeCategoryInResults, children)
  }

  return children
}

const parentCategoriesCache: Map<StatCategory, Map<boolean, StatCategory[]>> = new Map()

export function getParentCategories(
  uiSetup: UISetup,
  category: StatCategory,
  includeCategoryInResults = true,
  cache: Map<StatCategory, Map<boolean, StatCategory[]>> = parentCategoriesCache,
): StatCategory[] {
  const directParents = uiSetup.statCategories
    .entriesArray()
    .filter(([, children]) =>
      children.find(
        (it) =>
          it === category ||
          (typeof it !== 'string' && typeof category != 'string' && it.key === category.key),
      ),
    )
    .map(([key]) => key)
  if (!directParents || directParents.length === 0) {
    return includeCategoryInResults ? [category] : []
  }

  let parents: StatCategory[]

  if (cache.has(category) && cache.get(category)?.has(includeCategoryInResults)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    parents = cache.get(category)!.get(includeCategoryInResults)!
  } else {
    parents = directParents
      .flatMap((parent) =>
        typeof parent !== 'string' ? getParentCategories(uiSetup, parent, true, cache) : [parent],
      )
      .distinctBy((parent) => (typeof parent !== 'string' ? parent.key : `string:${parent}`))

    let resultsMap: Map<boolean, StatCategory[]>
    if (cache.has(category)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resultsMap = cache.get(category)!
    } else {
      resultsMap = new Map()
      cache.set(category, resultsMap)
    }

    if (includeCategoryInResults) parents.unshift(category)
    resultsMap.set(includeCategoryInResults, parents)
  }

  return parents
}

export interface EventDefinition {
  key: string
  type: 'EventDefinition'
  icon: LayeredIcon | undefined
  fallbackKey?: string
  title: string | (() => string)
  color: string
  description?: string | (() => string)
  dataTags: (DataTag | string)[] | undefined
  getOpposing?(): EventDefinition[]
  disableChoosing?: boolean
}

export function resolveStringOrFn<T extends string | (() => string) | undefined>(
  value: T,
): [undefined] extends [T] ? string | undefined : string {
  if (typeof value === 'undefined' && value == undefined)
    return undefined as [undefined] extends [T] ? string | undefined : string
  return typeof value === 'string' ? value : value()
}

export type FollowUpEvent = {
  event: EventDefinition
  playerAssignment: 'same-person' | 'different'
}
export type ButtonConfig = {
  type: 'ButtonConfig'
  borderColor?: string
  definition: EventDefinition
  shortcut?: string
  disableChoosing?: boolean
  quickKey?: boolean
  titleOverride?: string
  iconOverride?: LayeredIcon
  colorOverride?: string
  followUps?: FollowUpEvent[]
}

export function createEventDefinition(
  eventDefinition: PartialBy<EventDefinition, 'type'>,
): EventDefinition {
  // eslint-disable-line
  return { ...eventDefinition, type: 'EventDefinition' }
}

export function createButtonConfig(config: Omit<ButtonConfig, 'type'>): ButtonConfig {
  return { ...config, type: 'ButtonConfig' }
}

export type LayeredTypedButtonConfig = {
  default: TypedButtonConfig[]
  [id: string]: TypedButtonConfig[]
}

export interface UISetup {
  defaultViewMode: ViewingMode
  baseNodes: LayeredTypedButtonConfig
  buttonHierarchy: Map<EventDefinition, LayeredTypedButtonConfig>
  followUpEvents: Map<EventDefinition, EventDefinition[]>
  statCategories: Map<StatCategory, (StatCategory | EventDefinition)[]>
  definitions: Map<EventDefinition['key'], EventDefinition>
  defaultHighlightSuggestions?: EventDefinition[]
}

export type TypedButtonConfig = EventDefinition | ButtonConfig

export const NOTE_DEFINITION: EventDefinition = createEventDefinition({
  key: 'note',
  title: GeneralTemplateLocales.titleLocalGetter('note'),
  description: GeneralTemplateLocales.descriptionLocaleGetter('note'),
  icon: comment_icon,
  color: '#f1bf46',
  dataTags: ['Note'],
})

export const CLIP_DEFINITION: EventDefinition = createEventDefinition({
  key: 'clip',
  title: GeneralTemplateLocales.titleLocalGetter('clip'),
  description: GeneralTemplateLocales.descriptionLocaleGetter('clip'),
  icon: video_icon,
  color: Colors.color_playback_crimson,
  dataTags: ['clip'],
})

export const SKETCH_NOTE_DEFINITION: EventDefinition = createEventDefinition({
  key: 'note_sketch',
  title: GeneralTemplateLocales.titleLocalGetter('note_sketch'),
  description: GeneralTemplateLocales.descriptionLocaleGetter('note_sketch'),
  icon: squiggle_icon,
  color: '#089677',
  dataTags: ['Note'],
})

export const MATCH_RESET_DEFINITION: EventDefinition = createEventDefinition({
  key: 'match_reset',
  title: 'Match Reset',
  icon: replay_icon,
  color: '#004AAD',
  dataTags: ['Match Reset'],
  description:
    'Experimental - Only use this if there are multiple games in the same replay and you want to reset the stats and players between each match',
})

export const POINT_WIN_DEFINITION: EventDefinition = createEventDefinition({
  key: 'point_win',
  title: GeneralTemplateLocales.titleLocalGetter('point_win'),
  description: GeneralTemplateLocales.descriptionLocaleGetter('point_win'),
  icon: win_icon,
  color: '#ffffff',
  dataTags: ['Point Win'],
})

export const POINT_DRAW_DEFINITION: EventDefinition = createEventDefinition({
  key: 'point_draw',
  title: GeneralTemplateLocales.titleLocalGetter('point_draw'),
  description: GeneralTemplateLocales.descriptionLocaleGetter('point_draw'),
  icon: equals_icon,
  color: '#ffffff',
  dataTags: ['Point Draw'],
})

export const POINT_LOSE_DEFINITION: EventDefinition = createEventDefinition({
  key: 'point_lose',
  title: GeneralTemplateLocales.titleLocalGetter('point_lose'),
  description: GeneralTemplateLocales.descriptionLocaleGetter('point_lose'),
  icon: lose_icon,
  color: '#ffffff',
  dataTags: ['Point Lose'],
})
export const SCORE_DEFINITION: EventDefinition = createEventDefinition({
  key: 'score',
  title: GeneralTemplateLocales.titleLocalGetter('score'),
  description: GeneralTemplateLocales.descriptionLocaleGetter('score'),
  icon: score_icon,
  color: '#ffffff',
  dataTags: undefined,
})

export const SPECIAL_DEFINITIONS: EventDefinition[] = [
  NOTE_DEFINITION,
  POINT_WIN_DEFINITION,
  POINT_LOSE_DEFINITION,
  POINT_DRAW_DEFINITION,
  MATCH_RESET_DEFINITION,
  SKETCH_NOTE_DEFINITION,
  CLIP_DEFINITION,
]
export const BaseTemplate: UISetup = {
  defaultViewMode: 'edit',
  definitions: new Map(SPECIAL_DEFINITIONS.map((it) => [it.key, it])),
  baseNodes: {
    default: [
      createButtonConfig({
        definition: NOTE_DEFINITION,
        borderColor: color_white,
        shortcut: 'y',
        quickKey: true,
      }),
      createButtonConfig({
        definition: SKETCH_NOTE_DEFINITION,
        borderColor: color_white,
        shortcut: 'u',
        quickKey: true,
      }),
      createButtonConfig({
        definition: CLIP_DEFINITION,
        borderColor: color_white,
        shortcut: 'v',
        quickKey: true,
      }),
    ],
    basic: [
      createButtonConfig({
        definition: NOTE_DEFINITION,
        borderColor: color_white,
        shortcut: 'y',
        quickKey: true,
      }),
      createButtonConfig({
        definition: SKETCH_NOTE_DEFINITION,
        borderColor: color_white,
        shortcut: 'u',
        quickKey: true,
      }),
    ],
  },
  buttonHierarchy: new Map<EventDefinition, LayeredTypedButtonConfig>(),
  statCategories: new Map<StatCategory, StatCategory[]>([
    // NOTES
    [NOTE_DEFINITION, [SKETCH_NOTE_DEFINITION, CLIP_DEFINITION]],
  ]),
  followUpEvents: new Map(),
}
export const QwertyKeys = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\']
export const BaseSportsTemplate: UISetup = extendBaseTemplate({
  defaultViewMode: 'edit',
  definitions: new Map(),
  baseNodes: {
    default: [
      createButtonConfig({
        definition: SCORE_DEFINITION,
        borderColor: color_red,
        shortcut: 't',
      }),
    ],
    basic: [
      createButtonConfig({
        definition: SCORE_DEFINITION,
        borderColor: color_red,
        shortcut: 't',
      }),
    ],
  },
  buttonHierarchy: new Map<EventDefinition, LayeredTypedButtonConfig>([
    [
      SCORE_DEFINITION,
      {
        default: [
          createButtonConfig({
            definition: POINT_LOSE_DEFINITION,
            shortcut: 'q',
          }),
          createButtonConfig({
            definition: POINT_WIN_DEFINITION,
            shortcut: 'w',
          }),
          createButtonConfig({
            definition: POINT_DRAW_DEFINITION,
            shortcut: 'e',
          }),
          // createButtonConfig({
          //   definition: MATCH_RESET_DEFINITION,
          //   shortcut: 'r',
          // }),
        ],
        experimental: [
          createButtonConfig({
            definition: POINT_LOSE_DEFINITION,
            shortcut: 'q',
          }),
          createButtonConfig({
            definition: POINT_WIN_DEFINITION,
            shortcut: 'w',
          }),
          createButtonConfig({
            definition: POINT_DRAW_DEFINITION,
            shortcut: 'e',
          }),
          createButtonConfig({
            definition: MATCH_RESET_DEFINITION,
            shortcut: 'r',
          }),
        ],
        basic: [
          createButtonConfig({
            definition: POINT_LOSE_DEFINITION,
            shortcut: 'q',
          }),
          createButtonConfig({
            definition: POINT_WIN_DEFINITION,
            shortcut: 'w',
          }),
          createButtonConfig({
            definition: POINT_DRAW_DEFINITION,
            shortcut: 'e',
          }),
        ],
      },
    ],
  ]),
  statCategories: new Map<StatCategory, StatCategory[]>([
    [
      'RoundEnd',
      [POINT_WIN_DEFINITION, POINT_LOSE_DEFINITION, POINT_DRAW_DEFINITION, MATCH_RESET_DEFINITION],
    ],
  ]),
  followUpEvents: new Map(),
})

export function mergeArrayMaps<T, K, L>(map: Map<T, K[]>, map2: Map<T, L[]>): Map<T, (K | L)[]> {
  const mergedMap = new Map<T, (K | L)[]>(map.entries())
  map2.forEach((value, key) => {
    const list = mergedMap.get(key)
    if (!list) {
      mergedMap.set(key, value)
      return
    }
    list.push(...value)
  })
  return mergedMap
}

export function mergeMaps<T, K, L>(map: Map<T, K>, map2: Map<T, L>): Map<T, K | L> {
  const mergedMap = new Map<T, K | L>(map.entries())
  map2.forEach((value, key) => {
    mergedMap.set(key, value)
  })
  return mergedMap
}

function mergeArrayRecords<T, K extends Record<string, T[]>, L extends Record<string, T[]>>(
  map: K,
  map2: L,
): K & L {
  const mergedMap: Record<string, T[]> = { ...map }
  Object.entries(map2).forEach(([key, value]) => {
    const list = mergedMap[key]
    if (!list) {
      mergedMap[key] = value
      return
    }

    list.push(...value)
  })
  return mergedMap as K & L
}

export function extendBaseSportsTemplate(template: UISetup): UISetup {
  return extendBaseTemplate(template, BaseSportsTemplate)
}

export function extendBaseTemplate(
  template: Partial<UISetup>,
  baseTemplate: UISetup = BaseTemplate,
): UISetup {
  const buttonHierarchy = (template.buttonHierarchy?.keysArray() ?? [])
    .concat(baseTemplate.buttonHierarchy.keysArray())
    .reduce((map, key) => {
      map.set(
        key,
        mergeArrayRecords(
          template.buttonHierarchy?.get(key) ??
            ({ default: [] } satisfies LayeredTypedButtonConfig),
          baseTemplate.buttonHierarchy.get(key) ??
            ({ default: [] } satisfies LayeredTypedButtonConfig),
        ),
      )
      return map
    }, new Map<EventDefinition, LayeredTypedButtonConfig>())
  return {
    defaultViewMode: template.defaultViewMode ?? baseTemplate.defaultViewMode,
    followUpEvents: mergeArrayMaps(
      template.followUpEvents ?? new Map(),
      baseTemplate.followUpEvents,
    ),
    statCategories: mergeArrayMaps(
      template.statCategories ?? new Map(),
      baseTemplate.statCategories,
    ),
    baseNodes: mergeArrayRecords(template.baseNodes ?? {}, baseTemplate.baseNodes),
    definitions: mergeMaps(baseTemplate.definitions, template.definitions ?? new Map()),
    buttonHierarchy,
    defaultHighlightSuggestions: [
      ...(baseTemplate.defaultHighlightSuggestions ?? []),
      ...(template.defaultHighlightSuggestions ?? []),
    ],
  }
}
