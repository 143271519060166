import { Flex } from '@chakra-ui/react'
import { Player } from 'data/Player'
import { POINT_DEFINITION } from 'data/StatsStore'
import { TimelineEvent } from 'data/TimelineEvent'
import { isNoteDefinition } from 'data/common'
import { useMemo } from 'react'
import { EventDefinition, matchChildCategoryKey } from 'templates/TemplateConfig'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import { PlayerTile } from 'ui/PlayerTile'
import { ReviewActivityType } from 'ui/ReviewMetaStore'

export function RoundStartPlayersPanel({
  activityType,
  events,
  roundEndEvent,
}: {
  activityType: ReviewActivityType
  events: TimelineEvent<any>[]
  roundEndEvent: TimelineEvent<any>
}) {
  const round = useMemo(() => {
    return getRoundAtTime(events, roundEndEvent.time)
  }, [events, roundEndEvent])

  const players = useMemo(
    () =>
      round
        .flatMap((it) => it.who)
        .distinctBy((it) => it?.id)
        .filterNotNull(),
    [round],
  )

  const isDeadMap = useMemo(() => {
    return playersDeadMap(activityType, players, round)
  }, [round, players, activityType])

  const teamsElements = useMemo(() => {
    return players
      .filterNotNull()
      .groupByArray((it) => it.team)
      .orderBy(([team, players]) => team)
      .map(([team, players]) =>
        players.map((player) => (
          <Flex
            key={player.id}
            alignItems={'center'}
            justifyContent={'center'}
            w={22}
            h={22}>
            <PlayerTile
              key={player.id}
              player={player}
              size={isDeadMap?.get(player) ? 18 : 22}
              selectionState={isDeadMap?.get(player) ? 'deselected' : undefined}
              editMode={false}
              canEnableEditing={false}
              displayStyle={'icon-only'}
            />
          </Flex>
        )),
      )
  }, [players, isDeadMap])
  return (
    <Flex
      direction={'row'}
      alignItems={'flex-end'}
      justifyContent={'center'}>
      {teamsElements.map((teamElements) => (
        <Flex
          key={0}
          direction={'column'}
          alignItems={'center'}
          gap={2}>
          {teamElements}
        </Flex>
      ))}
    </Flex>
  )
}

export function getRoundAtTime(sortedEvents: TimelineEvent<EventDefinition>[], time: number) {
  if (sortedEvents.length === 0) return []

  const roundStartIndex =
    sortedEvents.findLastIndex(
      (it) => it.time < time && POINT_DEFINITION.includes(it.definition_key),
    ) + 1

  const roundEndIndex = sortedEvents.findIndex(
    (it) => it.time >= time && POINT_DEFINITION.includes(it.definition_key),
  )

  return sortedEvents.slice(
    roundStartIndex,
    roundEndIndex === -1 ? sortedEvents.length - 1 : roundEndIndex + 1,
  )
}

export function playersDeadMap(
  activityType: ReviewActivityType,
  players: Player[],
  round: TimelineEvent<any>[],
): Map<Player, boolean> | undefined {
  if (activityType !== 'dodgeball') return undefined

  const entries = players.map((player) => {
    const playerEvents = round.filter((it) => it.who?.any((it) => it.id === player.id))
    const deaths = playerEvents.filter((it) =>
      matchChildCategoryKey(
        DodgeballTemplate.buttonSetup,
        DodgeballTemplate.Definitions.get('died'),
        it.definition_key,
      ),
    ).length
    const revives = playerEvents.filter((it) =>
      matchChildCategoryKey(
        DodgeballTemplate.buttonSetup,
        DodgeballTemplate.Definitions.get('revive'),
        it.definition_key,
      ),
    ).length

    return [player, deaths - revives > 0] as const
  })
  return new Map(entries)
}
