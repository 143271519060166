import { DragHandleIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { Colors } from 'Colors'
import { DocumentPermissions } from 'data/common'
import { User } from 'firebase/auth'
import { set } from 'firebase/database'
import * as React from 'react'
import {
  CSSProperties,
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import {
  EventDefinition,
  POINT_LOSE_DEFINITION,
  POINT_WIN_DEFINITION,
  SKETCH_NOTE_DEFINITION,
  resolveStringOrFn,
} from 'templates/TemplateConfig'
import { Player } from '../data/Player'
import { TimelineEvent } from '../data/TimelineEvent'
import { ViewingMode } from '../data/ViewingMode'
import { isNoteDefinition } from '../data/common'
import addPersonIcon from '../icons/add_person.png'
import binBlackIcon from '../icons/bin_icon.png'
import binWhiteIcon from '../icons/bin_white_icon.png'
import editIcon from '../icons/edit_button_black.png'
import playIcon from '../icons/play.png'
import { getPlayerInitials } from '../ui/PlayerTile'
import { showCommentPanel } from '../ui/VerticalCommentsPanel'
import { isInputEvent } from '../util/inputUtils'
import { InteractiveMarker } from './Marker'
import { NoteCard } from './NoteCard'
import { ShowPlayerChooser } from './PlayerChooser'
import { InlineShortcutIcon } from './ShortcutIcon'
import { compositeRef } from './UseCompositeRefObject'
import { VideoController } from './VideoController'
import { BackgroundIcon } from './common/BackgroundIcon'
import { EditableStringDiv } from './common/EditableStringDiv'
import { LayeredIconView } from './common/LayeredIconView'
import { RoundButton } from './common/RoundButton'
import { useSingleAndDoubleClick } from './common/UseSingleAndDoubleClick'
import { useMutableRef } from './common/utils/reactUtils'
import { cn } from './common/utils/tailwindUtils'

export interface TimelineMarkerProps<T> {
  event: TimelineEvent<T>
  reversePoints?: boolean
  left?: string
  width?: number
  user: User | undefined
  videoController: VideoController
  showSignInToEditDialog: undefined | (() => void)
  onUpdate?: (event: TimelineEvent<T>) => void
  onPanFinished?: (offsetX: number) => void
  onStretchFinished?: (offsetX: number) => void
  onClick?: (event: TimelineEvent<T>) => void
  onDeleteClicked?: (event: TimelineEvent<T>) => void
  onEditSketchClicked?: (event: TimelineEvent<T>) => void
  onDblClick?: (event: TimelineEvent<T>) => void
  onLongPress?: (event: TimelineEvent<T>) => void
  onExtraClicked?: (event: TimelineEvent<T>) => void
  onBlur?: (event: TimelineEvent<T>) => void
  viewingMode: ViewingMode
  baseViewingMode: ViewingMode
  documentPermissions: DocumentPermissions | undefined
  showPlayerChooser?: ShowPlayerChooser
  selectedPlayers?: Player[]
  getMatchNumberAtTime: (time: number) => number
  eventFilters?: EventDefinition[]
  opacity?: string
  onHoverEnter?: ((e: React.PointerEvent, event: TimelineEvent<T>) => void) | undefined
  onHoverExit?: ((e: React.PointerEvent, event: TimelineEvent<T>) => void) | undefined
  displayStyle: 'above' | 'below' | 'centre'
  editOnMount?: boolean
  simplified?: boolean
  isFocused?: boolean
  isSemiSelected?: boolean
  aboveHoverPanel?: () => ReactElement
  style?: CSSProperties
  className?: string
}

export const TimelineMarker = forwardRef<HTMLDivElement, TimelineMarkerProps<EventDefinition>>(
  function TimelineMarker({ event, ...props }, ref) {
    const { isFocused: isFocusedExternally } = props
    const setPlayerButton = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const deleteButtonRef = useRef<RoundButton>(null)
    const playButtonRef = useRef<RoundButton>(null)
    const drawButtonRef = useRef<RoundButton>(null)
    const playerInitials = useRef<HTMLDivElement>(null)
    const extraEditableDivRef = useRef<EditableStringDiv>(null)
    const [editOnMount, setEditOnMount] = useState(props.editOnMount)
    const isNote = isNoteDefinition((event.tag as EventDefinition).key)
    const viewingMode: ViewingMode = props.viewingMode
    const displayStyle = props.displayStyle
    const [isDeletePending, setIsDeletePending] = useState(false)

    const [panningStateRef, setPanningState] = useMutableRef<{
      dragStartX: number
    }>()
    const [panningOffset, updatePanAnimation] = useState(0)

    const [stretchingStateRef, setStretchingState] = useMutableRef<{
      dragStartX: number
    }>()
    const [stretchOffset, updateStretchAnimation] = useState(0)

    const handlePanDragStart = useCallback(
      (e: React.PointerEvent) => {
        e.currentTarget.setPointerCapture(e.pointerId)
        e.preventDefault()
        e.stopPropagation()
        setPanningState({ dragStartX: e.clientX })
      },
      [setPanningState],
    )
    const { onPanFinished, onStretchFinished } = props

    const handlePanDragEnd = useCallback(
      (e: PointerEvent) => {
        if (!panningStateRef.current) return
        e.preventDefault()
        e.stopPropagation()
        ;(e.target as HTMLElement).releasePointerCapture(e.pointerId)
        const newOffset = Math.round(e.clientX - panningStateRef.current.dragStartX)
        setPanningState(undefined)
        updatePanAnimation(0)
        onPanFinished?.(newOffset)
      },
      [panningStateRef, onPanFinished, setPanningState],
    )

    const handlePanDragMove = useCallback(
      (e: PointerEvent) => {
        if (!panningStateRef.current) return
        e.preventDefault()
        e.stopPropagation()
        if (e.buttons !== 1) return handlePanDragEnd(e)

        const newOffset = e.clientX - panningStateRef.current.dragStartX
        updatePanAnimation(newOffset)
      },
      [handlePanDragEnd, panningStateRef],
    )

    const handleStretchDragStart = useCallback(
      (e: React.PointerEvent) => {
        e.currentTarget.setPointerCapture(e.pointerId)
        e.preventDefault()
        e.stopPropagation()
        setStretchingState({ dragStartX: e.clientX })
      },
      [setStretchingState],
    )

    const handleStretchDragEnd = useCallback(
      (e: PointerEvent) => {
        if (!stretchingStateRef.current) return
        e.preventDefault()
        e.stopPropagation()
        ;(e.target as HTMLElement).releasePointerCapture(e.pointerId)
        const newOffset = Math.round(e.clientX - stretchingStateRef.current.dragStartX)
        setStretchingState(undefined)
        updateStretchAnimation(0)
        onStretchFinished?.(newOffset)
      },
      [stretchingStateRef, setStretchingState, onStretchFinished],
    )

    const handleStretchDragMove = useCallback(
      (e: PointerEvent) => {
        if (!stretchingStateRef.current) return
        e.preventDefault()
        e.stopPropagation()
        if (e.buttons !== 1) return handleStretchDragEnd(e)
        const newOffset = Math.round(e.clientX - stretchingStateRef.current.dragStartX)
        updateStretchAnimation(newOffset)
      },
      [handleStretchDragEnd, stretchingStateRef],
    )

    useEffect(() => {
      window.addEventListener('pointerup', handlePanDragEnd)
      window.addEventListener('pointerup', handleStretchDragEnd)
      window.addEventListener('pointermove', handlePanDragMove)
      window.addEventListener('pointermove', handleStretchDragMove)
      return () => {
        window.removeEventListener('pointerup', handlePanDragEnd)
        window.removeEventListener('pointerup', handleStretchDragEnd)
        window.removeEventListener('pointermove', handlePanDragMove)
        window.removeEventListener('pointermove', handleStretchDragMove)
      }
    })

    const [showPlayerClickInfo, setShowPlayerClickInfo] = useState<() => void>(() => () => {
      toast('Double click to change the Player', {
        position: 'bottom-center',
        type: 'info',
        theme: 'colored',
        autoClose: 5000,
      })

      setShowPlayerClickInfo(() => () => {
        // do nothing
      })
    })
    const [playButtonPosition, _setPlayButtonPosition] = useState('beside')

    const hasFocus = useCallback(() => {
      return (
        containerRef.current?.contains(document.activeElement) ||
        extraEditableDivRef.current?.hasFocus() ||
        deleteButtonRef.current?.hasFocus()
      )
    }, [])

    useEffect(() => {
      if (hasFocus()) return
      if (((event.extra?.message || isNote) && editOnMount) || isFocusedExternally) {
        containerRef.current?.focus()
      }
    }, [event, isNote, hasFocus, editOnMount, isFocusedExternally])

    const handleOnBlur = useCallback(
      (e: React.FocusEvent) => {
        if (!containerRef.current?.contains(e.relatedTarget)) {
          props.onBlur?.(event)
          if (isDeletePending) setIsDeletePending(false)
          // only edit on mount once
          if (editOnMount) {
            setEditOnMount(false)
          }
        }
      },
      [props, isDeletePending, setIsDeletePending, editOnMount, event],
    )

    const handleDeleteButtonBlur = useCallback((e: React.FocusEvent) => {
      e.stopPropagation()
      containerRef.current?.focus()
    }, [])

    const handleOnSetPlayerClicked = useCallback(() => {
      props.showPlayerChooser?.({
        reason: {
          type: 'timeline-marker',
          eventDefinitionKey: event.definition_key,
          time: event.time,
        },
        team: event.team,
        matchNumber: props.getMatchNumberAtTime(event.time),
        displayStyle: displayStyle === 'above' ? 'above' : 'below',
        location: setPlayerButton.current?.getBoundingClientRect(),
        onChoose: (player) => {
          if (player) props.onUpdate?.({ ...event, who: [player] })
        },
      })
    }, [displayStyle, props, event])

    const handleMarkerClick = useSingleAndDoubleClick({
      actionSingleClick: () => {
        props.onClick?.(event)
      },
      actionDoubleClick: () => {
        props.onDblClick?.(event)
      },
      actionLongPress: () => {
        props.onLongPress?.(event)
      },
      stopPointerUpPropagation: true,
    })

    const canDelete =
      (viewingMode === 'edit' || (props.documentPermissions === 'edit' && isNote && props.user)) &&
      event.source === 'local'
    const canEdit =
      (viewingMode === 'edit' || (props.documentPermissions === 'edit' && isNote && props.user)) &&
      event.source === 'local'
    const canPlay = true

    const handlePlayerInitialsClick = useSingleAndDoubleClick({
      actionSingleClick: () => {
        props.onClick?.(event)
        if (canEdit && event.who?.length) showPlayerClickInfo()
        // setNoteExpanded(value => !value)
      },
      actionDoubleClick: () => {
        if (canEdit) {
          if ((event.who?.length ?? 0) <= 1) {
            props.showPlayerChooser?.({
              reason: {
                type: 'timeline-marker',
                eventDefinitionKey: event.definition_key,
                time: event.time,
              },
              team: event.team,
              matchNumber: props.getMatchNumberAtTime(event.time),
              displayStyle: displayStyle === 'above' ? 'above' : 'below',
              location: playerInitials.current?.getBoundingClientRect(),
              onChoose: (player) => {
                if (player)
                  props.onUpdate?.({
                    ...event,
                    who: event.who?.filter((it) => it.id === player.id).length ? [] : [player],
                  })
              },
            })
          } else {
            props.onDblClick?.(event)
            showCommentPanel()
            props.showPlayerChooser?.({
              reason: {
                type: 'timeline-marker',
                eventDefinitionKey: event.definition_key,
                time: event.time,
              },
              team: event.team,
              matchNumber: props.getMatchNumberAtTime(event.time),
              displayStyle: displayStyle === 'above' ? 'above' : 'below',
              location: setPlayerButton.current?.getBoundingClientRect(),
              onChoose: (player) => {
                if (player) {
                  let who = event.who?.slice() ?? []
                  if (who.any((it) => it.id === player.id)) {
                    who = who.distinct()
                    who.remove(player)
                    props?.onUpdate?.({
                      ...event,
                      who: who,
                    })
                  } else {
                    props?.onUpdate?.({
                      ...event,
                      who: who.concat(player).distinct(),
                    })
                  }
                }
              },
            })
          }
        } else {
          props.onDblClick?.(event)
        }
      },
      stopPointerUpPropagation: canEdit,
    })

    const isRoundEnd = ['point_win', 'point_lose', 'point_draw'].includes(
      (event.tag as EventDefinition).key,
    )
    let {
      icon: displayIcon,
      description: displayDescription,
      color: displayColor,
      title: displayTitle,
    } = event
    if (props.reversePoints && isRoundEnd) {
      switch (event.definition_key) {
        case POINT_WIN_DEFINITION.key:
          displayIcon = POINT_LOSE_DEFINITION.icon
          displayTitle = resolveStringOrFn(POINT_LOSE_DEFINITION.title)
          displayDescription = resolveStringOrFn(POINT_LOSE_DEFINITION.description)
          displayColor = POINT_LOSE_DEFINITION.color
          break
        case POINT_LOSE_DEFINITION.key:
          displayIcon = POINT_WIN_DEFINITION.icon
          displayTitle = resolveStringOrFn(POINT_WIN_DEFINITION.title)
          displayDescription = resolveStringOrFn(POINT_WIN_DEFINITION.description)
          displayColor = POINT_WIN_DEFINITION.color
          break
      }
    }
    const isMatchReset = ['match_reset'].includes((event.tag as EventDefinition).key)

    const onDeleteClicked = props.onDeleteClicked
    const handleDeleteClicked = useCallback(() => {
      if (canDelete && isNote && !event.extra?.message?.trim() && !event.extra?.drawing?.length)
        onDeleteClicked?.(event)
      else if (canDelete) setIsDeletePending(true)
    }, [canDelete, isNote, event, onDeleteClicked])
    const handleConfirmDeleteClicked = useCallback(() => {
      if (canDelete) {
        onDeleteClicked?.(event)
        setIsDeletePending(false)
      }
    }, [canDelete, onDeleteClicked, event])
    const handleEditSketchClicked = useCallback(
      (ev: React.MouseEvent) => {
        props.onEditSketchClicked?.(event)
        ev.preventDefault()
        containerRef.current?.blur()
      },
      [event, props],
    )

    const handlePlayClicked = useCallback(
      async (event: TimelineEvent<EventDefinition>) => {
        const playbackRate = props.videoController.currentStatus.playbackRate
        await props.videoController.play()
        await props.videoController.seekTo(event.time - 4 * playbackRate)
      },
      [props.videoController],
    )

    const onKeyDown = useCallback(
      (ev: React.KeyboardEvent) => {
        if (isInputEvent()) return
        if (ev.key === 'Backspace' && !isDeletePending) {
          ev.stopPropagation()
          handleDeleteClicked()
        } else if (ev.key === 'Enter' && isDeletePending) {
          ev.stopPropagation()
          handleConfirmDeleteClicked()
        } else if (ev.key === 'Escape' && isDeletePending) {
          ev.stopPropagation()
          setIsDeletePending(false)
        } else if (ev.key === 'Escape' && !isDeletePending) {
          ev.stopPropagation()
          containerRef.current?.blur()
        }
      },
      [handleDeleteClicked, isDeletePending, handleConfirmDeleteClicked],
    )
    const { onHoverEnter, onHoverExit } = props
    const handleMarkerOnPointerEnter = useCallback(
      (e: React.PointerEvent) => {
        onHoverEnter?.(e, event)
      },
      [onHoverEnter, event],
    )
    const handleMarkerOnPointerOut = useCallback(
      (e: React.PointerEvent) => {
        onHoverExit?.(e, event)
      },
      [onHoverExit, event],
    )
    const handleContainerOnPointerEnter = useCallback((e: React.PointerEvent) => {
      setIsHovering(true)
    }, [])
    const handleContainerOnPointerLeave = useCallback((e: React.PointerEvent) => {
      setIsHovering(false)
    }, [])

    const [isHovering, setIsHovering] = useState(false)

    useEffect(() => {
      const listener = () => {
        if (isHovering) {
          const isActuallyHovered = containerRef.current?.parentElement?.querySelector(':hover')
          if (!isActuallyHovered) {
            setIsHovering(false)
          }
        }
      }

      document.addEventListener('pointermove', listener)
      return () => {
        document.removeEventListener('pointermove', listener)
      }
    }, [isHovering, props.simplified])
    const dontAssignPlayer = isRoundEnd || isMatchReset

    const shouldSimplify = props.simplified && !isHovering && !hasFocus() && !props.isSemiSelected

    return (
      <>
        {shouldSimplify && (
          <div
            ref={ref}
            className={cn(
              `top-[-5px] z-0 flex h-[35px] w-[20px] -translate-x-2/4 translate-y-[24px] cursor-grab justify-center
              rounded-[10px] border-0 border-solid border-white bg-[rgba(141,141,141,0.25)]`,
              isNote ? 'bookmark' : '',
              displayStyle === 'above' ? '!-translate-x-2/4 !-translate-y-[-36px]'
              : displayStyle === 'centre' ?
                '!h-[20px] !-translate-x-2/4 !translate-y-0 !bg-[rgba(141,141,141,0.8)]'
              : '',
              props.className,
            )}
            tabIndex={0}
            style={{
              left: props.left,
              opacity: props.opacity,
            }}
            onPointerDown={handleMarkerClick.onDown}
            onPointerUp={handleMarkerClick.onUp}
            onPointerMove={handleMarkerClick.onMove}
            onPointerOver={handleContainerOnPointerEnter}
            onPointerOut={handleContainerOnPointerLeave}
          />
        )}
        {!shouldSimplify && (
          <>
            <InteractiveMarker
              ref={compositeRef(containerRef, ref)}
              className={cn(
                // !isStretchingRef.current && 'hover:scale-125',
                // !isStretchingRef.current && 'focus-within:scale-150',
                'drop-shadow-md',
                hasFocus() ? 'z-[4]'
                : displayStyle === 'centre' ?
                  !event.duration ?
                    'z-[3]'
                  : 'z-[2]'
                : 'z-[1]',
                displayStyle === 'above' && '-translate-y-full',
                displayStyle === 'below' && 'translate-y-full',
                panningStateRef.current ? 'grabbing' : 'grab',
                props.className,
              )}
              style={{
                opacity: props.opacity,
                left: panningOffset ? `calc(${props.left} + ${panningOffset}px` : props.left,
                ...props.style,
              }}
              displayStyle={
                displayStyle === 'above' ? 'down'
                : displayStyle === 'below' ?
                  'up'
                : 'circle'
              }
              width={(props.width ?? 0) + (stretchOffset ?? 0)}
              draggable={false}
              onKeyDown={onKeyDown}
              backgroundColor={displayColor}
              tabIndex={0}
              onBlur={handleOnBlur}
              onPointerOver={handleContainerOnPointerEnter}
              onPointerOut={handleContainerOnPointerLeave}
              tagBackgroundColor={
                event.who && event.who.length === 1 ? event.who[0].color || undefined : 'black'
              }
              outline={props.isSemiSelected || hasFocus()}
              // outlineColor={event.source === 'stat_record' ? 'black' : undefined}
              tag={
                event.who?.length && props.showPlayerChooser ?
                  () => (
                    <div
                      ref={playerInitials}
                      onPointerDown={handlePlayerInitialsClick.onDown}
                      onPointerUp={handlePlayerInitialsClick.onUp}
                      onPointerMove={handlePlayerInitialsClick.onMove}>
                      <span
                        translate={'no'}
                        style={{
                          textTransform: 'uppercase',
                          backgroundColor: event.who?.[0].color || 'black',
                          filter: 'invert() brightness(2) grayscale(1) contrast(30)',
                        }}
                        className='bg-inherit bg-clip-text text-transparent'>
                        {event.who?.length === 1 ? getPlayerInitials(event.who[0]) : '👬'}
                      </span>
                    </div>
                  )
                : undefined
              }
              verticalUi={() => (
                <>
                  {(!event.who?.length &&
                    !dontAssignPlayer &&
                    props.showPlayerChooser &&
                    canEdit && (
                      <div style={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}>
                        <SetPlayerButton
                          ref={setPlayerButton}
                          onClicked={handleOnSetPlayerClicked}
                        />
                      </div>
                    )) ||
                    undefined}
                  {(event.extra?.message || isNote) &&
                    (isHovering ||
                      hasFocus() ||
                      // isFocusedExternally ||
                      // props.isSemiSelected ||
                      displayStyle === 'below') && (
                      <NoteCard<EventDefinition>
                        ref={extraEditableDivRef}
                        className='text-md'
                        onClicked={props.onExtraClicked}
                        onBlur={props.onBlur}
                        editMode={
                          props.baseViewingMode === 'edit' &&
                          event.source === 'local' &&
                          !event?.isDeleted
                        }
                        canEnableEditing={
                          props.documentPermissions === 'edit' &&
                          event.source === 'local' &&
                          !event?.isDeleted
                        }
                        onDeleteClicked={props.onDeleteClicked}
                        onUpdate={props.onUpdate}
                        event={event}
                        isSemiSelected={hasFocus() || props.isSemiSelected}
                        editOnMount={editOnMount && isFocusedExternally}
                      />
                    )}
                  {isHovering && props.aboveHoverPanel && (
                    <Flex
                      direction={'row'}
                      width={'fit-content'}
                      flexWrap={'nowrap'}
                      style={{
                        overflow: 'visible',
                        paddingBottom: 10,
                        zIndex: 6,
                        gap: 4,
                      }}>
                      {props.aboveHoverPanel?.()}
                    </Flex>
                  )}
                </>
              )}
              horizontalUi={
                (hasFocus() &&
                  !extraEditableDivRef.current?.hasFocus() &&
                  (() => (
                    <div className='z-[6] flex flex-col-reverse justify-center gap-1 overflow-visible'>
                      {canEdit && (
                        <div className='flex'>
                          <div
                            className='pointer-events-auto flex h-[20px] w-fit cursor-ew-resize items-center gap-2 rounded-full bg-white
                              px-2 py-1 font-league-spartan text-black shadow-md'
                            color='black'
                            onPointerDown={handlePanDragStart}
                            // onPointerMove={handlePanDragMove}
                            // onPointerUp={handlePanDragEnd}
                            onClick={() => {
                              // do nothing
                            }}>
                            <DragHandleIcon /> Move
                          </div>

                          {isNote && (
                            <div
                              className='pointer-events-auto flex h-[20px] w-fit cursor-ew-resize items-center gap-2 rounded-full bg-white
                                px-2 py-1 font-league-spartan text-black shadow-md'
                              color='black'
                              onPointerDown={handleStretchDragStart}
                              // onPointerMove={handleStretchDragMove}
                              // onPointerUp={handleStretchDragEnd}
                              onClick={() => {
                                // do nothing
                              }}>
                              <DragHandleIcon /> Stretch
                            </div>
                          )}
                        </div>
                      )}

                      {canEdit && isNote && props.onEditSketchClicked && (
                        <HorizontalUIButton
                          ref={drawButtonRef}
                          alt={'Draw'}
                          backgroundColor={Colors.color_white}
                          color='black'
                          icon={editIcon}
                          onClick={handleEditSketchClicked}>
                          Draw
                        </HorizontalUIButton>
                      )}

                      {canPlay && (
                        <HorizontalUIButton
                          ref={playButtonRef}
                          alt={'Play video where the event was made'}
                          icon={playIcon}
                          iconVerticalPadding='4px'
                          onClick={() => handlePlayClicked(event)}
                          backgroundColor={Colors.color_playback_crimson}
                          color='white'>
                          Play
                        </HorizontalUIButton>
                      )}

                      {!event?.isDeleted && canDelete && props.onDeleteClicked && (
                        <HorizontalUIButton
                          ref={deleteButtonRef}
                          alt={'Confirm delete event'}
                          icon={isDeletePending ? binWhiteIcon : binBlackIcon}
                          onBlur={handleDeleteButtonBlur}
                          backgroundColor={isDeletePending ? '#DD3D4E' : '#ffffff'}
                          color={isDeletePending ? 'white' : 'black'}
                          className={cn('my-2', isDeletePending && 'shadow-black drop-shadow-md')}
                          onClick={
                            isDeletePending ? handleConfirmDeleteClicked : handleDeleteClicked
                          }>
                          {isDeletePending ? 'Confirm?' : ''}
                          {!extraEditableDivRef.current?.hasFocus() && (
                            <InlineShortcutIcon
                              shortcut={isDeletePending ? 'Enter' : 'Backspace'}
                            />
                          )}
                        </HorizontalUIButton>
                      )}
                    </div>
                  ))) ||
                undefined
              }
              icon={
                event.icon && (
                  <>
                    <LayeredIconView
                      src={event.extra?.drawing?.length ? SKETCH_NOTE_DEFINITION.icon : displayIcon}
                      draggable={false}
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        backgroundColor:
                          event.extra?.drawing?.length ?
                            SKETCH_NOTE_DEFINITION.color
                          : displayColor,
                      }}
                      onPointerDown={handleMarkerClick.onDown}
                      onPointerUp={handleMarkerClick.onUp}
                      onPointerMove={handleMarkerClick.onMove}
                      onPointerEnter={handleMarkerOnPointerEnter}
                      onPointerOut={handleMarkerOnPointerOut}
                    />
                    {event.source === 'stat_record' && (
                      <Flex
                        style={{
                          position: 'absolute',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-start',
                          width: '100%',
                          height: '100%',
                          fontVariationSettings: "'wght' 600",
                          fontSize: '10px',
                          transform: 'translateY(30%)',
                        }}>
                        👁️‍🗨️
                      </Flex>
                    )}
                  </>
                )
              }
            />
          </>
        )}
      </>
    )
  },
)

const HorizontalUIButton = forwardRef<
  RoundButton,
  React.PropsWithChildren<{
    onClick: (e: React.MouseEvent) => void
    alt: string
    icon?: ReactNode
    backgroundColor?: string
    style?: CSSProperties
    color?: string
    onBlur?: (e: React.FocusEvent) => void
    className?: string
    iconVerticalPadding?: string
  }>
>(function HorizontalUIButton(props, ref) {
  return (
    <RoundButton
      ref={ref}
      alt={'Draw'}
      className={cn(
        'pointer-events-auto relative h-[20px] w-fit shadow-md hover:scale-[120%]',
        props.className,
      )}
      iconVerticalPadding={props.iconVerticalPadding}
      tabIndex={0}
      icon={props.icon}
      onClick={props.onClick}
      backgroundColor={props.backgroundColor}
      color={props.color}
      onBlur={props.onBlur}
      style={props.style}>
      {props.children}
    </RoundButton>
  )
})

const SetPlayerButton = forwardRef<HTMLDivElement, { onClicked: () => void }>(
  function SetPlayerButton(props, ref) {
    const handleOnSetPlayerClicked = useSingleAndDoubleClick({
      actionSingleClick: props.onClicked,
    })
    return (
      <div
        ref={ref}
        style={{
          zIndex: 1,
          position: 'relative',
          display: 'flex',
          width: '100%',
          aspectRatio: '1 / 1',
          backgroundColor: '#4D4E53',
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'all',
        }}
        onPointerDown={handleOnSetPlayerClicked.onDown}
        onPointerUp={handleOnSetPlayerClicked.onUp}
        onPointerMove={handleOnSetPlayerClicked.onMove}>
        <BackgroundIcon
          draggable={false}
          src={addPersonIcon}
          className='absolute aspect-[1/1] w-[calc(100%_-_6px)]'
          alt={'add a player to this event'}
        />
      </div>
    )
  },
)
