export {}

declare global {
  interface String {
    /**
     * Convert string to proper case by uppercase first letter of each word
     */
    toTitleCase(): string
  }
}

String.prototype.toTitleCase = function (): string {
  if (!this) {
    return ''
  }

  const exceptions = ['of', 'the', 'and']

  return this.toLowerCase()
    .split(' ')
    .map((word, i) => {
      return exceptions.includes(word) && i != 0 ?
          word
        : word.charAt(0).toUpperCase().concat(word.substr(1))
    })
    .join(' ')
}
