import objectHash from 'object-hash'
import { FirebaseGroupLeaderboardEntry } from './leaderboardtypes'
import { FirebaseStatRecordAliasEntry } from './statrecordtypes'
import { ViewingMode } from './ViewingMode'

export type PendingSummaryFirebaseEntry = {
  prompt: string | undefined
  user: string | undefined
} & (
  | {
      type?: undefined
      model?:
        | 'gpt-4'
        | 'gpt-4o'
        | 'gpt-4-0314'
        | 'gpt-4-0613'
        | 'gpt-4-32k'
        | 'gpt-4-32k-0314'
        | 'gpt-4-32k-0613'
        | 'gpt-3.5-turbo'
        | 'gpt-3.5-turbo-16k'
        | 'gpt-3.5-turbo-0301'
        | 'gpt-3.5-turbo-0613'
        | 'gpt-3.5-turbo-16k-0613'
      assistant?: undefined
    }
  | {
      type: 'assistant'
      assistant: string
    }
)

export interface UserInventoryEntry {
  ai_token: number | undefined
}

export interface RunningPaymentInventoryEntry {
  original: number
  claimed: number
}

export interface RunningPaymentInventoryCollection {
  ai_token?: RunningPaymentInventoryEntry

  [id: string]: RunningPaymentInventoryEntry | undefined
}

export interface ReviewExtensionsEntry {
  ai_summary_unlocked: boolean | undefined
}

export function isNoteDefinition(key: string | undefined): boolean {
  return key === 'note' || key === 'note_sketch' || key === 'clip'
}

export function parseMetaDataNumber(value: string | number): number {
  return typeof value === 'string' ? parseInt(value) : value
}

export type VideoSource = 'Youtube' | 'Facebook_url' | 'Youtube_url'

export type VideoDownloadResolution = '1080' | '720' | '480' | '360' 
export namespace DownloadClips {
  export type DownloadClipsRequest = {
    sources: SourceClips[] | SourceClips
    resolution?: VideoDownloadResolution | 'max'
  }

  export type DownloadClipsAsyncResponse = {
    databaseRef: string
    status: string
  }

  export type Clip = { startSeconds: number; endSeconds: number }

  export type SourceClips = YoutubeClips

  export type YoutubeClips = { url: string; clips: Clip[] }
}

export namespace FunctionRequests {
  export type SendLeagueStatsTeamEmailParams = {
    reviewId: string
    groupId: string
    teamNumber: number
    divisionKey: string | undefined
  }
  export type SendLeagueStatsTeamEmailResponse = {
    success: boolean
  }
}

export type FunctionTriggerResult<T> =
  | { type: 'SUCCESS'; payload: T }
  | { type: 'ERROR'; message: string }

export type FunctionTrigger = {
  type: 'DownloadClips'
  userId: string
  payload: DownloadClips.DownloadClipsRequest
  progress?: number
  errors?: string[]
  totalSteps?: number
  result?: FunctionTriggerResult<{ downloadURL: string }>
}

export function stringAsDatabaseKey(name: string): string {
  return objectHash(name)
}

export type FirebaseVideoSource = 'Youtube' | 'Facebook_url' | 'Youtube_url'

export type FirebaseActivityType = 'dodgeball' | 'sports_notes' | 'general_notes'

export interface PlayerFirebaseEntry {
  id: string
  name: string
  color: string
  index: number
  team: number | null
  matchNumber: number | null
  requestedChanges?: {
    name: FirebaseNameChangeEntry
  }
}
export type FirebaseNameChangeEntry = FirebaseSubmittedChangeEntry<string>

export type FirebaseSubmittedChangeEntry<T extends object | string | number> = {
  changes: T
  dateSubmitted: number
  ownerUid: string
  ownerName: string | null
  ownerEmail: string | null
}

export type FirebaseUserReviewEntry = {
  owned: { [id: string]: RecentsFirebaseEntry & MetaStatsCache }
  visited: { [id: string]: RecentsFirebaseEntry & MetaStatsCache }
}

export type FirebaseUserEntry = {
  groups?: { [id: string]: boolean } | null
  fcm?: { [id: string]: boolean } | null
  reviews?: FirebaseUserReviewEntry | null
  inviteId?: string | null
}

export type FirebaseGroupEntry = {
  // public
  details: FirebaseGroupDetails
  owner: string | null
  inviteId: string
  editingUsers: { [uid: string]: any }
  security?: { blockExternalAccessToReviews: boolean }
  inviteRequests?: {
    [id: string]: FirebaseGroupMemberDetails
  } | null
  // private
  leaderboard?: FirebaseGroupLeaderboardEntry | null
  reviews: { [id: string]: RecentsFirebaseEntry & MetaStatsCache } | null
  players?: { [id: string]: FirebaseGroupPlayerProfileEntry | null } | null
  teams?: { [id: string]: FirebaseGroupTeamProfileEntry } | null
  members?: {
    [id: string]: FirebaseGroupMemberDetails
  } | null
  otherSettings?: { [id: string]: boolean } | null
}

export type FirebaseGroupTeamProfileEntry = {
  id: string
  name: string
  division?: { name: string; key: string } | null
  logoUrl?: string | null
  contacts?: { [pushId: string]: { type: 'Email'; email: string } } | null
}

export type FirebaseGroupPlayerProfileEntry = {
  id: string
  index: number
  teams?: { [id: string]: boolean }
  name: string
  avatarUrl?: string
}

export type FirebaseGroupDetails = {
  name: string
  color?: string
  avatarUrl?: string
}

export type FirebaseGroupMemberDetails = {
  id: string
  index: number
  displayName: string | null
  email: string | null
  owner?: boolean
}

export type FirebaseTeamInviteEntry = {
  groupId: string
  details: FirebaseGroupDetails
}

export type FLAG_TYPE = 'general' | 'staff' | 'stats_pro' | 'subscription'

export interface RecentsFirebaseEntry {
  reviewId: string
  ownerUID: string | null
  ownerName: string | null
  videoId: string | null
  source: FirebaseVideoSource | null
  lastVisited: number
  createdTime: number | null
  title: string | null
  mode: DocumentPermissions | null
  isPublicStatsRecord: boolean | null
  isAttachedToStatsRecord: boolean | string | null
  isCreatedByStaff: boolean | null
}

export type ReviewFirebaseEntry = {
  videoId: string
  owner?: string
  ownerName?: string
  isCreatedByStaff?: boolean
  groups?: {
    [groupId: string]: true
  }
  matchup?: {
    identifier: string
    league?: { name: string; id: string }
    teams?: { name: string; id: string }[]
  }
  parentStatRecord?: string | null
  statRecordAlias?: string | FirebaseStatRecordAliasEntry
  editingUsers?: { [uid: string]: boolean }
  players: { [id: string]: PlayerFirebaseEntry }
  events: { [id: string]: TimelineMarkerFirebaseEntry }
  title?: string
  teamToHide?: number
  teamNames?: { [id: string]: string | null }
  bothTeams?: boolean
  createdTime?: number
  source?: FirebaseVideoSource
  thumbnail: string
  activityType?: FirebaseActivityType
  visits?: { [id: string]: number }
  metaStats?: FirebaseEventMetaStatsEntry
  commentSummary?: string
  statsTemplateKey?: string | null
}

export type MetaStatsCache = {
  metaStats?:
    | (FirebaseEventMetaStatsEntry & {
        visits: ReviewFirebaseEntry['visits']
      })
    | null
  metaStatsUserCache?: {
    [uid: string]: {
      commentCount: FirebaseEventMetaStatsEntry['commentCount']
    } | null
  } | null
}
export interface FirebaseEventMetaStatsEntry {
  eventCount: number
  commentCount: number
  requestedChangesCount?: number
  trackingProgress?: number
  score: { win: number; lose: number; draw: number }
}

type UserInfo = { uid: string; displayName: string | null }

export type TimelineMarkerFirebaseEntry =
  | BaseTimelineMarkerFirebaseEntry
  | TimelineMarkerNoteFirebaseEntry

export type BaseTimelineMarkerFirebaseEntry = {
  id: string
  definitionKey: Exclude<string, TimelineMarkerNoteFirebaseEntry['definitionKey']>
  // For custom definitions
  fallbackDefinitionKey: string | null
  title: string
  description: string | null
  time: number
  /** @deprecated */
  timeEnd?: number | null
  duration?: number | null
  who: PlayerFirebaseEntry[] | null
  dataTags: string[] | null
  extra: FirebaseEventExtra | null
  team: number | null
  createdDate: number | null
  modifiedDate: number | null
  createBy: UserInfo | null
  seenBy: { [uid: string]: number } | null
  modifiedBy: UserInfo[] | null
  isDeleted: boolean
}

export type TimelineMarkerNoteFirebaseEntry = Omit<
  BaseTimelineMarkerFirebaseEntry,
  'definitionKey'
> & {
  definitionKey: 'note' | 'note_sketch' | 'clip'
  /**
   * Only used for emoji responses
   */
  replies: { [id: string]: TimelineReplyFirebaseEntry } | null
  reactedDate: number | null
  parentNoteId: string | null
}

export type TimelineMarkerFirebaseDictionary = {
  [id: string]: TimelineMarkerFirebaseEntry
}

export type FirebaseEventExtra =
  | string
  | {
      type: 'object'
      message: string | null
      drawing: FirebaseDrawingEntry | null
    }

interface Point {
  x: number
  y: number
}

export interface FirebaseDrawingEntry {
  paths: FirebaseDrawingPath[]
}

export interface FirebaseDrawingPath {
  paths: Point[]
  strokeWidth: number
  strokeColor: string
  startTimestamp?: number
  endTimestamp?: number
}

export interface TimelineReplyFirebaseEntry {
  id: string
  message: string
  createdDate: number | null
  modifiedDate: number | null
  createBy: UserInfo | null
  modifiedBy: UserInfo[] | null
}

/**
 * The permissions assigned to the user for this document
 */

export type DocumentPermissions = 'view' | 'edit'

export interface FirebaseFeedbackEntry {
  email: string | null
  message: string
  dateCreated: number
  resolved: boolean
  context: {
    userId: string | null
    reviewId: string | null
    documentPermissions: DocumentPermissions | null
    viewingMode: ViewingMode | null
    url: string
  }
}