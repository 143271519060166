import { Box, Flex, Img } from '@chakra-ui/react'
import React from 'react'
import { Colors } from '../Colors'
import { showDialog } from '../components/common/Dialog'
import checkIcon from '../icons/check_icon.png'
import clockIcon from '../icons/clock_icon.svg'
import crownIcon from '../icons/crown_icon.svg'
import lockIcon from '../icons/lock_icon.svg'
import { ReviewActivityType } from './ReviewMetaStore'

export function showActivityTypeChooseDialog(): Promise<ReviewActivityType | undefined> {
  return new Promise((resolve, reject) => {
    showDialog<ReviewActivityType>(
      {
        title: () => <>Choose a Playback type</>,
        children: (FocusSpan, state, setState, triggerPositiveButton, focusPositiveButton) => {
          return (
            <ActivityTypes
              activityType={state}
              onSelected={(activityType) => {
                setState(activityType)
                setTimeout(focusPositiveButton, 500)
              }}
            />
          )
        },
        positiveButtonProps: {
          text: "LET'S GO",
          disabled: (state) => !state,
          onClicked: (state) => {
            if (!state) return false
            resolve(state)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Cancel',
          disabled: (state) => !state,
          onClicked: () => {
            resolve(undefined)
            return true
          },
        },
        user_dismissable: false,
      },
      'dodgeball',
    )
  })
}

function ActivityTypes(props: {
  activityType: ReviewActivityType | undefined
  onSelected: (activityType: ReviewActivityType) => void
}) {
  return (
    <Flex
      direction={'column'}
      gap={16}>
      <ActivityType
        title={'Dodgeball'}
        key={'dodgeball'}
        activityType={'dodgeball'}
        selectedType={props.activityType}
        onClick={props.onSelected}
        options={[
          { icon: checkIcon, title: 'Player Stats' },
          { icon: checkIcon, title: 'Share Video Clips' },
          { icon: checkIcon, title: 'Video Notes' },
          { icon: checkIcon, title: 'Hand Drawn Notes' },
          { icon: crownIcon, title: 'AI Coach Summary' },
          { icon: checkIcon, title: 'Collaborate with your Team' },
          { icon: crownIcon, title: 'Free in beta: Player Spotlight' },
        ]}
      />
      <ActivityType
        title={'Other sports'}
        key={'sports_notes'}
        examples={['Volleyball', 'Tennis', 'Badminton', 'E-sports']}
        activityType={'sports_notes'}
        selectedType={props.activityType}
        onClick={props.onSelected}
        options={[
          { icon: checkIcon, title: 'Share Video Clips' },
          { icon: checkIcon, title: 'Video Notes' },
          { icon: checkIcon, title: 'Hand Drawn Notes' },
          { icon: checkIcon, title: 'Collaborate with your Team' },
          { icon: clockIcon, title: 'Coming Soon: Player Stats' },
          { icon: crownIcon, title: 'AI Coach Summary' },
          { icon: clockIcon, title: 'Coming Soon: Player Spotlight' },
        ]}
      />
      <ActivityType
        key={'general_notes'}
        activityType={'general_notes'}
        selectedType={props.activityType}
        title={'Video notes for anything else'}
        examples={['Personal training', 'Educational videos']}
        onClick={props.onSelected}
        options={[
          { icon: checkIcon, title: 'Share Video Clips' },
          { icon: checkIcon, title: 'Video Notes' },
          { icon: checkIcon, title: 'Hand Drawn Notes' },
          { icon: checkIcon, title: 'Collaborate with your Team' },
          { icon: clockIcon, title: 'Coming Soon: Player Stats' },
          { icon: crownIcon, title: 'AI Notes Summary' },
          { icon: clockIcon, title: 'Coming Soon: Player Spotlight' },
        ]}
      />
      <Flex
        direction={'column'}
        alignItems={'center'}
        gap={16}
        paddingTop={4}
        alignSelf={'center'}>
        <img
          alt={''}
          src={lockIcon}
        />
        Only people with a direct link
        <br />
        can see your playback notes
      </Flex>
    </Flex>
  )
}

function ActivityType(props: {
  title: string
  examples?: string[]
  activityType: ReviewActivityType
  selectedType: ReviewActivityType | undefined
  options: { icon?: string; title: string }[]
  onClick: (type: ReviewActivityType) => void
}) {
  const isSelected = props.selectedType === props.activityType

  return (
    <Flex
      transition={'all 200ms ease-in-out'}
      direction={'column'}
      alignItems={'center'}
      _hover={{
        transition: 'none',
        border: '2px solid rgb(255,255,255,0.3)',
        backgroundColor: isSelected ? Colors.color_blue_grey : 'rgba(62,80,118,0.49)',
      }}
      border={isSelected ? '2px solid white' : '2px dashed rgb(255,255,255,0.3)'}
      borderRadius={20}
      backgroundColor={isSelected ? Colors.color_blue_grey : undefined}
      padding={'20px 20px'}
      onClick={() => props.onClick(props.activityType)}
      gap={20}>
      <Box
        fontSize={'1.1em'}
        fontWeight={550}
        color={'white'}
        filter={'drop-shadow(0 1px 0 white)'}
        textTransform={'uppercase'}
        fontFamily={'LeagueSpartan,sans-serif'}>
        {props.title}
      </Box>
      {props.examples?.length && (
        <Box
          fontSize={'0.8em'}
          fontWeight={550}
          filter={'drop-shadow(0 1px 0 rgba(255,255,255,0.4))'}
          color={'rgba(255,255,255,0.4)'}
          fontFamily={'LeagueSpartan,sans-serif'}
          letterSpacing={1}>
          {props.examples?.join(', ').toUpperCase()} ...etc
        </Box>
      )}
      <Flex
        wrap={'wrap'}
        fontSize={'0.7em'}
        fontFamily={'Montserrat'}
        width={'100%'}>
        {props.options.map((it, index, array) => (
          <>
            <Flex
              minWidth={'50%'}
              boxSizing={'border-box'}
              alignItems={'center'}
              gap={8}>
              {it.icon && (
                <Img
                  src={it.icon}
                  width={16}
                  height={16}
                  objectFit={'contain'}
                />
              )}
              {it.title}
            </Flex>
            {/*{Math.abs(array.length / 2 - index) <= 0.5 && <Box flexBasis={'100%'} />}*/}
          </>
        ))}
      </Flex>
    </Flex>
  )
}
