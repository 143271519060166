import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { TimelineStorage } from 'AppDataRepository'
import { Colors } from 'Colors'
import { generatePlayersAndTeamsFromLeaderboard } from 'UseGroupSelectionStore'
import { TimelineEvent } from 'data/TimelineEvent'
import {
  PlayerFirebaseEntry,
  RecentsFirebaseEntry,
  ReviewFirebaseEntry,
  stringAsDatabaseKey,
} from 'data/common'
import {
  FirebaseBaseLeaderboardEntry,
  FirebaseGroupLeaderboardEntry,
  FirebaseLeaderboardResults,
  FirebasePlayerGameResult,
  FirebasePublicLeaderboardEntry,
  LeaderboardEntry,
  LeaderboardResults,
  NswdlDivFilter,
  NswdlDivFilters,
  NswdlDivs,
  ResultDividers,
  RoundInfo,
} from 'data/leaderboardtypes'
import { StatRecord } from 'data/statrecordtypes'
import { User } from 'firebase/auth'
import { set } from 'firebase/database'
import { useProFeaturesStore } from 'hooks/UseProFeaturesStore'
import { getParentStatRecordEntry } from 'hooks/UseStatRecordReviewId'
import objectHash from 'object-hash'
import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ReactTagManager from 'react-ga4'
import { ThreeDots } from 'react-loading-icons'
import { toast } from 'react-toastify'
import { EventDefinition } from 'templates/TemplateConfig'
import { DodgeballMeasureKeys } from 'templates/dodgeball/MeasureKeys'
import { PillContainer } from 'util/dialogUtils'
import { copyToClipboard } from 'util/links'
import { normaliseName } from '../data/NormaliseName'
import { DodgeballLeaderboardMeasures } from '../templates/dodgeball/DodgeballLeaderboardMeasures'
import { DocumentTitle } from './DocumentTitle'
import { MultiSelect } from './MultiSelectOption'
import { RankingsTable } from './RankingsTable'
import { showDialog } from './common/Dialog'
import {
  FirebaseComponents,
  FirebaseDb,
  FirebaseDbReference,
  MultiKeyedDatabaseResult,
  useDatabaseMultiRefLiveValue,
  useDatabasePathLiveValue,
  useDatabaseRefLiveValueMemo,
} from './common/Firebase'
import { RoundButton } from './common/RoundButton'
import { useFeatureFlag } from './common/hooks/useFeatureFlags'
import { useQueryString } from './common/utils/QueryString'
import { cn } from './common/utils/tailwindUtils'
import './common/utils/typescriptUtils'

export function mapToLeaderboardEntryResults(
  resultsByPlayer: FirebaseLeaderboardResults,
): LeaderboardEntry['resultsByPlayer'] {
  const acc: LeaderboardEntry['resultsByPlayer'] = {}

  Object.entries(resultsByPlayer ?? {}).reduce((acc, [measureKey, playerResults]) => {
    if (!acc[measureKey as DodgeballMeasureKeys]) {
      acc[measureKey as DodgeballMeasureKeys] = []
    }

    const measureResults = Object.values(playerResults ?? {}).flatMap((resultsObject) =>
      Object.values(resultsObject ?? {}),
    )

    acc[measureKey as DodgeballMeasureKeys]?.push(...measureResults)
    return acc
  }, acc)

  return acc
}

export function mapToLeaderBoardEntry(entry: FirebaseBaseLeaderboardEntry): LeaderboardEntry {
  return {
    ...entry,
    title: entry.title,
    groupColor: entry.groupColor,
    resultsHashesByDivision: entry.resultsHashesByDivision ?? {},
    resultsByPlayer: mapToLeaderboardEntryResults(entry.resultsByPlayer),
    // resultsByTeam: mapToLeaderboardEntryResults(entry.resultsByTeam),
  }
}
export function RankingsByDiv(
  props: { backgroundColor: string } & (
    | {
        type: 'private'
        title: string
        firebase: FirebaseComponents
        groupId: string
        publicLeaderboardId?: undefined
        isGroupMember: boolean
        groupColor?: string
        reviews: RecentsFirebaseEntry[]
        user: User | undefined
      }
    | {
        type: 'public'
        firebase: FirebaseComponents
        groupId?: undefined
        publicLeaderboardId: string
        isGroupMember: boolean
        user: User | undefined
      }
  ),
) {
  const [queryMeasure, setQueryMeasure] = useQueryString('measure')
  const [queryDiv, setQueryDiv] = useQueryString('div')
  const [queryShowBy, setQueryShowBy] = useQueryString('showby')

  const selectedMeasure = useMemo(() => {
    return (
      DodgeballLeaderboardMeasures.find((it) => it.key === queryMeasure) ??
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      DodgeballLeaderboardMeasures.find((it) => !it.hidden && !it.forfun)!
    )
  }, [queryMeasure])

  const measureStates = useMemo(() => {
    return [
      selectedMeasure,
      (action: SetStateAction<Measure>) => {
        action instanceof Function ?
          setQueryMeasure(action(selectedMeasure).key, true)
        : setQueryMeasure(action.key, true)
      },
    ] as [Measure, Dispatch<SetStateAction<Measure>>]
  }, [selectedMeasure, setQueryMeasure])
  const showByStates = useMemo(() => {
    const showBy = queryShowBy === 'team' ? 'team' : 'player'
    return [
      showBy,
      (action: SetStateAction<'team' | 'player'>) => {
        action instanceof Function ?
          setQueryShowBy(action(showBy), true)
        : setQueryShowBy(action, true)
      },
    ] as ['team' | 'player', Dispatch<SetStateAction<'team' | 'player'>>]
  }, [queryShowBy, setQueryShowBy])

  const [generateInProgress, setGenerateInProgress] = useState<{
    progress: number
    display?: string | undefined
  }>({ progress: 1.0 })

  const [publishInProgress, setPublishInProgress] = useState<{
    progress: number
    display?: string | undefined
  }>({ progress: 1.0 })

  useUnloadAlert(
    generateInProgress.progress < 1.0,
    'The leaderboard is still generating. Are you sure you want to leave?',
  )

  useUnloadAlert(
    publishInProgress.progress < 1.0,
    'The leaderboard is still publishing. Are you sure you want to leave?',
  )
  const divState = useMemo(() => {
    const selectedDiv = NswdlDivFilters.find((it) => it.key === queryDiv) ?? NswdlDivFilters[0]

    return [
      selectedDiv,
      (action: SetStateAction<NswdlDivFilter>) => {
        action instanceof Function ?
          setQueryDiv(action(selectedDiv).key, true)
        : setQueryDiv(action.key, true)
      },
    ] as [NswdlDivFilter, Dispatch<SetStateAction<NswdlDivFilter>>]
  }, [setQueryDiv, queryDiv])

  const [showForFunMetrics, setShowForFunMetrics] = useState(false)
  const [divSelection] = divState

  const leaderboardRef: FirebaseDbReference<FirebaseBaseLeaderboardEntry> = useMemo(
    () =>
      (props.type === 'private' ?
        props.firebase.firebaseDb.getRef<FirebaseGroupLeaderboardEntry>(
          `groups/${props.groupId}/leaderboard`,
        )
      : props.firebase.firebaseDb.getRef<FirebasePublicLeaderboardEntry>(
          `public_leaderboards/${props.publicLeaderboardId}`,
        )) as FirebaseDbReference<FirebaseGroupLeaderboardEntry | FirebasePublicLeaderboardEntry>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.type, props.groupId, props.publicLeaderboardId],
  )

  const leaderboardResultsByPlayer = useDatabaseMultiRefLiveValue<
    DodgeballMeasureKeys,
    NonNullable<NonNullable<FirebaseBaseLeaderboardEntry['resultsByPlayer']>[DodgeballMeasureKeys]>
  >(() => {
    const resultKeys = [selectedMeasure.key, ...(selectedMeasure?.dependencies ?? [])]
    return resultKeys.map((it) => ({
      key: it,
      ref: leaderboardRef.child(`resultsByPlayer/${it}`),
    }))
  }, [selectedMeasure, leaderboardRef])

  const publicLeaderBoardId = useDatabasePathLiveValue<string>(
    props.firebase.firebaseDb,
    props.type === 'private' ?
      `groups/${props.groupId}/leaderboard/publicLeaderboardId`
    : undefined,
  )
  const leaderboardGroupId = useDatabasePathLiveValue<string>(
    props.firebase.firebaseDb,
    props.type === 'public' ?
      `public_leaderboards/${props.publicLeaderboardId}/groupId`
    : undefined,
  )

  const leaderboardTitle = useDatabaseRefLiveValueMemo<string>(
    () => leaderboardRef.child('title'),
    [leaderboardRef],
  )

  const leaderboardGroupColor = useDatabaseRefLiveValueMemo<string>(
    () => leaderboardRef.child('groupColor'),
    [leaderboardRef],
  )

  const leaderboardHash = useDatabaseRefLiveValueMemo<
    FirebaseBaseLeaderboardEntry['resultsHashesByDivision']
  >(() => leaderboardRef.child('resultsHashesByDivision'), [leaderboardRef])

  const publicLeaderBoardHash = useDatabasePathLiveValue<
    FirebaseBaseLeaderboardEntry['resultsHashesByDivision']
  >(
    props.firebase.firebaseDb,
    props.type !== 'public' && publicLeaderBoardId ?
      `public_leaderboards/${publicLeaderBoardId}/resultsHashesByDivision`
    : undefined,
  )

  const hashesMatch = useMemo(() => {
    return (
      publicLeaderBoardHash !== undefined &&
      leaderboardHash !== undefined &&
      objectHash(publicLeaderBoardHash) === objectHash(leaderboardHash)
    )
  }, [publicLeaderBoardHash, leaderboardHash])

  const leaderboard: FirebasePublicLeaderboardEntry | FirebaseGroupLeaderboardEntry | undefined =
    useMemo(() => {
      const leaderboardResultByPlayerEntries = Object.entries(leaderboardResultsByPlayer)
      const errors = leaderboardResultByPlayerEntries
        .mapProp('1')
        .filter((value) => value instanceof Error)
      if (errors.length) {
        console.error(errors)
        return undefined
      }

      const castedResultsByPlayer = leaderboardResultByPlayerEntries.reduce(
        (acc, [key, value]) => {
          acc[key as DodgeballMeasureKeys] = value as Exclude<typeof value, Error>
          return acc
        },
        {} as NonNullable<FirebaseBaseLeaderboardEntry['resultsByPlayer']>,
      )

      if (props.type === 'public') {
        return leaderboardGroupId && leaderboardTitle && leaderboardHash && leaderboardGroupColor ?
            ({
              groupId: leaderboardGroupId,
              type: 'public',
              title: leaderboardTitle,
              groupColor: leaderboardGroupColor,
              resultsHashesByDivision: leaderboardHash,
              resultsByPlayer: castedResultsByPlayer,
            } satisfies FirebasePublicLeaderboardEntry)
          : undefined
      } else {
        return leaderboardTitle && leaderboardGroupColor ?
            ({
              publicLeaderboardId: publicLeaderBoardId,
              type: 'private',
              title: leaderboardTitle,
              groupColor: leaderboardGroupColor,
              resultsHashesByDivision: leaderboardHash,
              resultsByPlayer: castedResultsByPlayer,
            } satisfies FirebaseGroupLeaderboardEntry)
          : undefined
      }
    }, [
      leaderboardGroupId,
      leaderboardTitle,
      leaderboardResultsByPlayer,
      leaderboardHash,
      leaderboardGroupColor,
      publicLeaderBoardId,
      props.type,
    ])

  const castedResults: LeaderboardEntry['resultsByPlayer'] | undefined = useMemo(() => {
    return leaderboard ? mapToLeaderBoardEntry(leaderboard).resultsByPlayer : undefined
  }, [leaderboard])

  const { value: enableShowByTeamPublic } = useFeatureFlag('leaderboardShowByTeam', false, true)
  const {
    featureFlags: {
      publishPublicLeaderboardEnabled,
      maxGamesOnLeaderboard,
      enableLeaderboardShowByTeam: subscriptionEnabledLeageusProLeaderboard,
    },
  } = useProFeaturesStore({
    user: props.user,
    groupId: props.groupId ?? (leaderboard?.type === 'public' ? leaderboard.groupId : undefined),
  })

  const handleRegenerate = useCallback(
    async (
      groupId: string,
      reviews: RecentsFirebaseEntry[],
      title: string,
      groupColor: string | undefined,
      maxGamesOnLeaderboard: number,
    ) => {
      if (!props.isGroupMember) return

      const confirm = await showDialog({
        title: 'Regenerate leaderboard',
        children: (Red) => (
          <>
            This may take a few seconds.
            <br />
            <br />
            <Red>Please refrain from refreshing more than once a day</Red>
          </>
        ),
        positiveButtonProps: 'Regenerate',
        negativeButtonProps: 'Cancel',
        user_dismissable: true,
      })
      if (!confirm) return

      setGenerateInProgress({ progress: 0.0 })

      try {
        await leaderboardRef.childFromKey('title').set(title)
        await leaderboardRef.childFromKey('groupColor').set(groupColor)

        /**
         * START: Clean up old results
         */
        await leaderboardRef.childFromKey('resultsHashesByDivision').remove()
        await leaderboardRef.childFromKey('resultsHashes').remove()
        await leaderboardRef.childFromKey('resultsByPlayer').remove()
        /**
         * END: Clean up old results
         */

        let processed = 0
        await reviews
          .orderByDesc((it) => it.createdTime ?? 0)
          .slice(0, maxGamesOnLeaderboard)
          .mapAwait(async (review, index, array) => {
            const sortedEventsEntry = await getSortedEventsEntry({
              reviewId: review.reviewId,
              firebaseDb: props.firebase.firebaseDb,
            })
            const measurableEventsByReview = mapFromReviewEventToMeasureableEventsByReview([
              sortedEventsEntry,
            ])

            const dbUpdate = DodgeballLeaderboardMeasures.reduce<{ [pushKey: string]: any }>(
              (acc, measure) => {
                const measureResults = calculateMeasureResults(measure, measurableEventsByReview)
                if (measureResults.length) {
                  const resultsByPlayerUpdates = measureResults.reduce<{ [pushKey: string]: any }>(
                    (acc, measureResult) => {
                      const teamNameHash = stringAsDatabaseKey(
                        measureResult.teamName ?? (measureResult.team ?? 0).toString(),
                      )
                      const pushKey = `${measureResult.reviewId}_${teamNameHash}`
                      if (!pushKey) return acc
                      acc[
                        `resultsByPlayer/${measure.key}/${stringAsDatabaseKey(measureResult.player)}/${pushKey}`
                      ] = measureResult

                      return acc
                    },
                    {},
                  )
                  const hashUpdates = reviewResultsToHashesByDivision(measureResults, measure)
                  return Object.assign(acc, resultsByPlayerUpdates, hashUpdates)
                }
                return acc
              },
              {},
            )

            // async update
            await leaderboardRef.update(dbUpdate).then(() => {
              processed++

              setGenerateInProgress({
                progress: processed / array.length,
                display: `${processed}/${array.length}`,
              })
            })
          }, 10)

        await generatePlayersAndTeamsFromLeaderboard(props.firebase.firebaseDb, groupId)
      } catch (e) {
        console.error(e)
      } finally {
        setGenerateInProgress({ progress: 1.0 })
      }
    },
    [props.isGroupMember, props.firebase.firebaseDb, leaderboardRef],
  )

  const handlePublish = useCallback(
    async (
      groupLeaderboardRef: FirebaseDbReference<FirebaseGroupLeaderboardEntry>,
      leaderboard: FirebaseGroupLeaderboardEntry,
      groupId: string,
    ) => {
      if (!props.isGroupMember) return

      if (!publishPublicLeaderboardEnabled) {
        toast(
          `To make your leaderboard public, you'll need to sign up as a League. Reach out to us for more info`,
          {
            type: 'info',
            position: 'bottom-center',
          },
        )
        return
      }

      let publicLeaderboardId: string | null = leaderboard.publicLeaderboardId ?? null
      const roundsAvailable = getExistingRounds(
        mapToLeaderboardEntryResults(leaderboard.resultsByPlayer),
      ).filter((it) => it.category === 'R')
      const confirm = await showPublishConfirmationDialog(publicLeaderboardId, roundsAvailable)
      if (!confirm) return

      setPublishInProgress({ progress: 0.0 })

      const publicLeaderboard = {
        type: 'public',
        title: leaderboard.title,
        resultsByPlayer: {},
        resultsHashesByDivision: leaderboard.resultsHashesByDivision,
        groupId: groupId,
        groupColor: leaderboard.groupColor,
      } satisfies FirebasePublicLeaderboardEntry

      if (!publicLeaderboardId) {
        publicLeaderboardId = props.firebase.firebaseDb
          .getRef(`public_leaderboards`)
          .push(publicLeaderboard).key
        if (!publicLeaderboardId) {
          console.error('Failed to create public leaderboard')
          return
        }
        groupLeaderboardRef.update({
          publicLeaderboardId,
        } satisfies Partial<FirebaseGroupLeaderboardEntry>)
      }
      props.firebase.firebaseDb.getRef(`public_leaderboards/${publicLeaderboardId}`).update({
        groupId: publicLeaderboard.groupId,
        title: publicLeaderboard.title,
        type: publicLeaderboard.type,
        groupColor: publicLeaderboard.groupColor,
      } satisfies Omit<
        FirebasePublicLeaderboardEntry,
        'resultsByPlayer' | 'resultsHashesByDivision'
      >)

      const isRoundsFiltered =
        objectHash(confirm.roundsSelected, {
          unorderedArrays: true,
          unorderedObjects: true,
        }) !== objectHash(roundsAvailable, { unorderedArrays: true, unorderedObjects: true })
      const hashesUpdates = await DodgeballLeaderboardMeasures.map((it) => it)
        .mapAwait(async (measure, index, array) => {
          const leaderboardMeasureResults = await props.firebase.firebaseDb
            .getRef(`groups/${groupId}/leaderboard/resultsByPlayer`)
            .child(measure.key)
            .getVal<FirebaseBaseLeaderboardEntry['resultsByPlayer'][DodgeballMeasureKeys]>()

          const filteredMeasureResults =
            isRoundsFiltered ?
              leaderboardMeasureResults &&
              filterMeasureResultsByRound(leaderboardMeasureResults, confirm.roundsSelected)
            : leaderboardMeasureResults

          await props.firebase.firebaseDb
            .getRef(`public_leaderboards/${publicLeaderboardId}/resultsByPlayer`)
            .child(measure.key)
            .set(filteredMeasureResults ?? {})

          setPublishInProgress({
            progress: (index + 1) / (array.length + 1),
            display: `${index + 1}/${array.length + 1}`,
          })

          return resultsByMeasureToHashesByDivision(filteredMeasureResults, measure)
        })
        .then((it) => it.reduce((acc, it) => Object.assign(acc, it), {}))

      try {
        props.firebase.firebaseDb
          .getRef(`public_leaderboards/${publicLeaderboardId}`)
          .update(
            isRoundsFiltered ? hashesUpdates : (
              { resultsHashesByDivision: leaderboard.resultsHashesByDivision }
            ),
          )
      } catch (e) {
        console.error(e)
      }

      setPublishInProgress({ progress: 1.0 })
    },
    [props.firebase.firebaseDb, props.isGroupMember, publishPublicLeaderboardEnabled],
  )

  const currentRound: RoundInfo | undefined = useMemo(() => {
    const results = castedResults?.[selectedMeasure.key] ?? []
    const roundCategories = results
      .distinctBy((it) => {
        return it.dividers.round.category
      })
      .map((it) => it.dividers.round.category)
    return results
      .distinctBy((it) => it.reviewId)
      .filter((it) => !roundCategories?.includes('R') || it.dividers.round.category === 'R')
      .map((it) => it.dividers.round)
      .maxByOrNull((it) => it.number)
  }, [castedResults, selectedMeasure])

  return (
    <DocumentTitle
      title={`${leaderboard?.title ? leaderboard?.title : 'PlayBack Review'} ${leaderboard?.type === 'public' ? 'Public' : 'Private'} - Rankings`}>
      <>
        <h1
          style={{
            color:
              leaderboard?.groupColor ??
              (props.type === 'private' ? props.groupColor : undefined) ??
              'white',
          }}>
          {leaderboard?.title} Rankings
        </h1>
        {leaderboard || (props.type === 'private' && props.reviews?.length) ?
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              width: '100%',
            }}>
            <Flex
              direction={'column'}
              alignItems={'center'}
              style={{
                width: '700px',
                maxWidth: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
              <div style={{ width: '100%' }}>
                <ImportantLeaderBoardInfo
                  funStatEnabled={showForFunMetrics}
                  onShowForFunStats={async () => {
                    ReactTagManager.event('leaderboard_for_fun_dialog_shown', {
                      category: 'leaderboard',
                    })
                    const accept = await showDialog({
                      title: 'Congrats!',
                      children: (Red) => (
                        <>
                          Thanks for loving Playback! You&apos;ve found the{' '}
                          <Red>secret &apos;fun&apos; metrics.</Red>
                          <br />
                          <br />
                          Disclaimer:
                          <HighlightedNoteBox>
                            These metrics should not be taken seriously and are just for fun. By
                            accepting this, you agree to not take these metrics seriously.
                          </HighlightedNoteBox>
                        </>
                      ),
                      positiveButtonProps: `ACCEPT`,
                      user_dismissable: true,
                      negativeButtonProps: 'DECLINE',
                    })
                    if (accept) {
                      ReactTagManager.event('leaderboard_for_fun_dialog_accepted', {
                        category: 'leaderboard',
                      })
                      setShowForFunMetrics(true)
                    } else {
                    }
                  }}
                />
              </div>

              <br />
              {props.type === 'private' && (
                <>
                  <Flex
                    width={'fit-content'}
                    gap={8}>
                    {maxGamesOnLeaderboard && (
                      <RoundButton
                        className='mx-auto w-fit'
                        onClick={() =>
                          handleRegenerate(
                            props.groupId,
                            props.reviews,
                            props.title,
                            props.groupColor,
                            maxGamesOnLeaderboard,
                          )
                        }
                        aria-disabled={generateInProgress.progress !== 1.0}>
                        {generateInProgress.progress === 1.0 ?
                          `Regenerate Leaderboard`
                        : `Processing ${generateInProgress.display ? generateInProgress.display + ' matches' : Math.round(generateInProgress.progress * 100) + '%'}`
                        }
                      </RoundButton>
                    )}
                    {leaderboard && props.isGroupMember && leaderboard.type === 'private' && (
                      <>
                        {publishPublicLeaderboardEnabled && (
                          <RoundButton
                            className={cn(hashesMatch && 'opacity-50')}
                            color='white'
                            backgroundColor={
                              hashesMatch ? Colors.color_green : Colors.color_playback_crimson
                            }
                            onClick={() =>
                              handlePublish(leaderboardRef, leaderboard, props.groupId)
                            }
                            aria-disabled={
                              generateInProgress.progress !== 1.0 ||
                              publishInProgress.progress !== 1.0
                            }>
                            {publishInProgress.progress === 1.0 ?
                              hashesMatch ?
                                `Published`
                              : `Publish Leaderboard`
                            : `Publishing ${publishInProgress.display ? publishInProgress.display : Math.round(publishInProgress.progress * 100) + '%'}`
                            }
                          </RoundButton>
                        )}
                        {leaderboard.publicLeaderboardId && (
                          <RoundButton
                            onClick={() => {
                              return copyToClipboard({
                                canonical:
                                  window.location.origin +
                                  `/leaderboards?id=${leaderboard.publicLeaderboardId}`,
                                pageTitle: leaderboard.title ?? 'Playback League Leaderboard',
                                thumbnail: undefined,
                                socialMediaText: `Check out this ${leaderboard.title} public leaderboard!`,
                              })
                            }}>
                            Share Link to Public Leaderboard
                          </RoundButton>
                        )}
                      </>
                    )}
                  </Flex>
                  <br />
                </>
              )}
              <div className='text-center font-league-spartan text-2xl text-white font-bold'>
                Metric
              </div>
              <br />
              <div className='max-w-500 flex w-full justify-center p-5'>
                <PillContainer<Measure>
                  useState={measureStates}
                  pills={DodgeballLeaderboardMeasures.filter((it) => !it.forfun && !it.hidden).map(
                    (it) => ({
                      title: it.title,
                      state: it,
                      selectedColor: it.forfun ? Colors.color_green : undefined,
                    }),
                  )}
                />
              </div>
              {showForFunMetrics && (
                <>
                  <br />
                  <div className='text-center font-league-spartan text-2xl text-white font-bold'>
                    For fun metrics
                  </div>
                  <br />
                  <div className='max-w-500 flex w-full justify-center p-5'>
                    <PillContainer<Measure>
                      useState={measureStates}
                      pills={DodgeballLeaderboardMeasures.filter(
                        (it) => it.forfun && !it.hidden,
                      ).map((it) => ({
                        title: it.title,
                        state: it,
                        selectedColor: it.forfun ? Colors.color_green : undefined,
                      }))}
                    />
                  </div>
                </>
              )}
              <br />
              <div className='text-center font-league-spartan text-2xl text-white font-bold'>
                Divisions
              </div>
              <br />
              <div className='max-w-500 flex w-full justify-center p-5'>
                <PillContainer<NswdlDivFilter>
                  useState={divState}
                  pills={NswdlDivFilters.map((it) => ({
                    title: it.title,
                    state: it,
                  }))}
                />
              </div>
              <br />

              {(showForFunMetrics || enableShowByTeamPublic) &&
                subscriptionEnabledLeageusProLeaderboard && (
                  <>
                    <Flex
                      style={{
                        width: 500,
                        maxWidth: '100%',
                        justifyContent: 'center',
                        gap: 8,
                      }}>
                      <div className='text-center font-league-spartan text-2xl text-white font-bold'>
                        Mode
                      </div>
                      <PillContainer<'player' | 'team'>
                        useState={showByStates}
                        pills={[
                          { title: 'By Player', state: 'player' },
                          { title: 'By Team (experimental)', state: 'team' },
                        ]}
                      />
                    </Flex>
                    <br />
                  </>
                )}
              <HighlightedNoteBox backgroundColor='rgb(0,0,0,0)'>
                {selectedMeasure.description}
              </HighlightedNoteBox>
              <br />
              {leaderboard && castedResults && (
                <RankingsTable
                  byTeam={showByStates[0] === 'team'}
                  leaderboardTitle={leaderboard.title ?? undefined}
                  leaderboardRef={leaderboardRef}
                  groupId={leaderboard.type === 'public' ? leaderboard.groupId : props.groupId}
                  backgroundColor={props.backgroundColor}
                  referrerUrl={window.location.pathname + window.location.search}
                  firebase={props.firebase}
                  user={props.user}
                  isGroupMember={props.isGroupMember}
                  measure={selectedMeasure}
                  round={currentRound}
                  division={divSelection}
                  results={castedResults}
                  onMeasureSelected={measureStates[1]}
                  onDivisionSelected={divState[1]}
                  includeForFun={showForFunMetrics}
                />
              )}
              {leaderboard && !castedResults?.[selectedMeasure.key] && props.isGroupMember && (
                <HighlightedNoteBox>Need to regenerate results</HighlightedNoteBox>
              )}
            </Flex>
          </div>
        : <ThreeDots />}
      </>
    </DocumentTitle>
  )
}

export function ImportantLeaderBoardInfo(props: {
  funStatEnabled: boolean
  onShowForFunStats: () => void
}) {
  const [count, setCount] = useState(0)

  return (
    <HighlightedNoteBox>
      <ExpandableContent collapsedContent={<>Important! Help us make corrections</>}>
        <>Help us make corrections</>
        <h2>Important Note for Leaderboard Accuracy</h2>
        <p>To ensure your position on the leaderboard is accurate, please follow these steps:</p>
        <Flex
          direction={'column'}
          gap={10}>
          <Flex
            direction={'column'}
            gap={10}
            paddingLeft={20}>
            <div>
              <strong>Check for Missing Games</strong>
              <br />
              Verify if there are any games missing under your name. Remember, the most recent round
              of stats might not be available yet.
            </div>
            <div>
              <strong>Find the linked review</strong>
              <br /> If any games are missing that should be there, contact your captain to get a
              link to the stats for that game. Ensure all players on your team are named correctly.
            </div>
            <div>
              <strong>Submit name changes</strong>
              <br />
              Submit player name changes through the Playback Review that is linked to the nswdl
              stats. Feel free to correct your team mate&apos;s names too
            </div>
          </Flex>
        </Flex>
        <p>
          The most important thing is to have your name be consistent throughout the games. The{' '}
          <b>default format is &quot;Davey Tran 29.&quot;</b> If you have different numbers across
          different teams, consider using a format like &quot;Davey Tran 17/29.&quot;. With the
          smallest number in front.
        </p>
        <p>
          This is especially important if you had rounds where your jersey number was not present.
          This will help update and correct the leaderboard.
        </p>
        {!props.funStatEnabled && (
          <>
            {count <= 2 && (
              <RoundButton
                className='w-fit'
                onClick={(e) => {
                  e.stopPropagation()
                  setCount((it) => it + 1)
                }}>
                I &lt;3 playback
              </RoundButton>
            )}

            {count > 2 && (
              <RoundButton
                className='w-fit'
                color={'white'}
                backgroundColor={Colors.color_playback_crimson}
                onClick={props.onShowForFunStats}>
                Show for fun stats
              </RoundButton>
            )}
          </>
        )}
      </ExpandableContent>
    </HighlightedNoteBox>
  )
}

export function HighlightedNoteBox(props: PropsWithChildren<{ backgroundColor?: string }>) {
  return (
    <div
      style={{
        padding: 20,
        color: 'white',
        background: props.backgroundColor ?? Colors.color_blue_grey,
        fontFamily: 'MontSerrat,sans-serif',
      }}>
      {props.children}
    </div>
  )
}
export function ExpandableContent({
  children,
  collapsedHeight,
  collapsedContent,
  className,
}: {
  className?: string
  children: React.ReactNode
  collapsedHeight?: number
  collapsedContent?: React.ReactNode
}) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <div
      className={className}
      onClick={toggleExpanded}
      style={{
        transition: 'all 300ms ease-in-out',
      }}>
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: 'fit-content',
          maxHeight: expanded ? 'fit-content' : `${collapsedHeight}px`,
          transition: 'all 300ms ease-in-out',
          paddingRight: expanded ? 0 : 30,
        }}>
        {expanded ? children : (collapsedContent ?? children)}
        {!expanded && (
          <ChevronDownIcon
            position={'absolute'}
            right={0}
            top={0}
            bottom={0}
            marginTop={'auto'}
            marginBottom={'auto'}
            width={30}
            height={30}></ChevronDownIcon>
        )}
      </div>
      {expanded && (
        <ChevronUpIcon
          alignSelf={'right'}
          width={30}
          height={30}></ChevronUpIcon>
      )}
    </div>
  )
}
export type MeasureResult = {
  value: number | undefined
  displayValue?: string | undefined
  displayBreakdown?: string | undefined
}

export type MeasurePlayerResult = MeasureResult & {
  player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
}

export type Measure = {
  key: DodgeballMeasureKeys
  title: string
  forfun?: boolean
  hidden?: boolean
  description: ReactNode
  highlightEventKeys: string[]
  disableTopGameRecords?: boolean
  playerValuesByGame: (
    events: MeasureableEvent[],
    players: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>[],
  ) => MeasurePlayerResult[]
} & (
  | {
      mode: 'sum' | 'average'
      dependencies?: undefined
      playerValueByLeagueResult?: undefined
    }
  | {
      mode?: undefined
      dependencies: DodgeballMeasureKeys[]
      playerValueByLeagueResult: (
        filteredResults: LeaderboardEntry['resultsByPlayer'],
        playerName: string,
      ) => MeasureResult | undefined
    }
)

function getDividers(reviewId: string, title: string): ResultDividers {
  return {
    matchingDivisions: NswdlDivFilters.filter((it) => it.match(title)).map((it) => ({
      title: it.title,
      key: it.key,
    })),
    division:
      NswdlDivs.filter((it) => it.match(title))
        ?.map((it) => ({
          title: it.title,
          key: it.key,
        }))
        .firstOrNull() ?? null,
    round: parseRoundTitle(title),
  }
}

function parseRoundTitle(title: string): RoundInfo {
  const roundRegex = /(Pre-Round \d+|R\d+)/ // Updated regex to match "Pre-Round 1" or "R1", "R2", etc.
  const match = title.match(roundRegex)
  if (match) {
    const round = match[0]
    const isPreRound = round.startsWith('Pre-Round')
    const firstMatch = round.match(/\d+/)?.firstOrNull()
    const number = firstMatch ? parseInt(firstMatch, 10) : 0 // Extract the number part
    const category = isPreRound ? 'Pre-Round' : 'R'
    return {
      name: round,
      number: number,
      category: category,
    }
  } else {
    return { name: 'No round info', number: 0, category: 'None' } // Default object for titles without round info
  }
}

export async function getCascadedReviewData<T>(
  firebase: FirebaseDb,
  reviewId: string,
  on: (
    reviewRef: FirebaseDbReference<ReviewFirebaseEntry>,
    rules: StatRecord['rules'] | undefined,
  ) => Promise<T>,
): Promise<{
  review: T
  statrecordResult: T | undefined
}> {
  const reviewRef = firebase.getRef(`reviews/${reviewId}`)
  const statRecord = await getParentStatRecordEntry({ reviewId, firebase })
  const statRecordReviewRef =
    statRecord ? firebase.getRef(`reviews/${statRecord.reviewId}`) : undefined
  return {
    review: await on(reviewRef, undefined),
    statrecordResult:
      statRecordReviewRef && statRecord ?
        await on(statRecordReviewRef, statRecord.rules)
      : undefined,
  }
}

export async function getReviewEventsIncludingStatRecords({
  reviewId,
  firebaseDb,
}: {
  reviewId: string
  firebaseDb: FirebaseDb
}) {
  const events = await getCascadedReviewData(firebaseDb, reviewId, async (ref, rules) => {
    return Object.entries((await ref.childFromKey('events').getVal()) ?? {})
      .filter(([id, event]) => {
        return !rules || (rules[event.team ?? 0] ?? 'hidden') !== 'hidden'
      })
      .toObject()
  })

  // TODO(Davey): Merge conflicting events
  return Object.assign({}, events.review, events.statrecordResult)
}
export async function getReviewPlayersIncludingStatRecords({
  reviewId,
  firebaseDb,
}: {
  reviewId: string
  firebaseDb: FirebaseDb
}) {
  const players = await getCascadedReviewData(firebaseDb, reviewId, async (ref, rules) =>
    Object.entries((await ref.childFromKey('players').getVal()) ?? {})
      .filter(([id, player]) => {
        return !rules || rules[player.team ?? 0] !== 'hidden'
      })
      .toObject(),
  )

  // TODO(Davey): Merge conflicting players
  return Object.assign({}, players.review, players.statrecordResult)
}

export async function getReviewTeamNamesIncludingStatRecords({
  reviewId,
  firebaseDb,
}: {
  reviewId: string
  firebaseDb: FirebaseDb
}) {
  const teamNames = await getCascadedReviewData(firebaseDb, reviewId, async (ref, rules) =>
    Object.entries((await ref.childFromKey('teamNames').getVal()) ?? {})
      .filter(([team, teamName]) => {
        return !rules || rules[parseInt(team)] !== 'hidden'
      })
      .toObject(),
  )

  // TODO(Davey): Merge conflicting teamNames
  return Object.assign({}, teamNames.review, teamNames.statrecordResult)
}

async function getSortedEventsEntry({
  reviewId,
  ...props
}: {
  reviewId: string
  firebaseDb: FirebaseDb
}): Promise<
  readonly [
    reviewId: string,
    events: ReviewEvent[],
    teamNames: NonNullable<ReviewFirebaseEntry['teamNames']>,
  ]
> {
  const title = await props.firebaseDb.getVal<string>(`reviews/${reviewId}/title`)
  const events = await getReviewEventsIncludingStatRecords({
    reviewId,
    firebaseDb: props.firebaseDb,
  })
  const players = await getReviewPlayersIncludingStatRecords({
    reviewId,
    firebaseDb: props.firebaseDb,
  })
  const teamNames = await getReviewTeamNamesIncludingStatRecords({
    reviewId,
    firebaseDb: props.firebaseDb,
  })

  const mappedEvents: ReviewEvent[] =
    events ?
      Object.values(events)
        .map(TimelineStorage.mapFirebaseToTimelineEvent('local'))
        .flatMap((event) => {
          const withoutPlayer = {
            event: event,
            player: undefined,
            title,
            reviewId,
          } satisfies ReviewEvent
          if (!event.who?.length) return [withoutPlayer]

          return event.who?.map((player) => {
            const playerId = player.id
            const finalPlayer = players ? players[playerId] : event.who?.firstOrNull()

            return {
              ...withoutPlayer,
              player: finalPlayer && {
                name: normaliseName(finalPlayer.name),
                id: playerId,
                team: finalPlayer.team,
              },
            } satisfies ReviewEvent
          })
        })
        .orderBy((it) => it.event.time)
    : []

  return [reviewId, mappedEvents, teamNames ?? {}] as const
}

async function getSortedEventsByReview(props: {
  reviews: RecentsFirebaseEntry[]
  firebaseDb: FirebaseDb
}): Promise<
  (readonly [
    reviewId: string,
    events: ReviewEvent[],
    teamNames: NonNullable<ReviewFirebaseEntry['teamNames']>,
  ])[]
> {
  const reviewEventsByReview = await Promise.all(
    props.reviews.map(async ({ reviewId }) =>
      getSortedEventsEntry({ reviewId, firebaseDb: props.firebaseDb }),
    ),
  )
  return reviewEventsByReview
}

type ReviewEvent = {
  player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'> | undefined
  title: string | undefined
  reviewId: string
  event: TimelineEvent<EventDefinition>
}

type MeasureableEvent = TimelineEvent<EventDefinition> & {
  player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'> | undefined
  reviewTitle: string | undefined
}
type MeasureableEventsByReview = (readonly [
  reviewId: string,
  events: MeasureableEvent[],
  players: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>[],
  teamNames: NonNullable<ReviewFirebaseEntry['teamNames']>,
])[]

function mapFromReviewEventToMeasureableEventsByReview(
  sortedEventsByReview: (readonly [
    reviewId: string,
    events: ReviewEvent[],
    teamNames: NonNullable<ReviewFirebaseEntry['teamNames']>,
  ])[],
): MeasureableEventsByReview {
  const measureableEventsByReview = sortedEventsByReview.map(([reviewId, events, teamNames]) => {
    const sortedEvents: MeasureableEvent[] = events.map((it) => ({
      ...it.event,
      reviewTitle: it.title,
      player: it.player,
    }))
    const players: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>[] = sortedEvents
      .distinctBy((it) => it.player?.id)
      .mapNotNull((it) => it.player)
    return [reviewId, sortedEvents, players, teamNames] as const
  })
  return measureableEventsByReview
}

function calculateMeasureResults(
  measure: Measure,
  sortedEventsByReview: MeasureableEventsByReview,
): (FirebasePlayerGameResult & {
  dividers: ResultDividers
})[] {
  const measureResults = sortedEventsByReview.flatMap(([reviewId, events, players, teamNames]) => {
    return calculateMeasureResultsByReview(measure, reviewId, events, players, teamNames)
  })

  return measureResults
}

function calculateMeasureResultsByReview(
  measure: Measure,
  reviewId: string,
  events: MeasureableEvent[],
  players: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>[],
  teamNames: NonNullable<ReviewFirebaseEntry['teamNames']>,
): (FirebasePlayerGameResult & {
  dividers: ResultDividers
})[] {
  const measureResultsByReview = measure.playerValuesByGame(events, players).mapNotNull((entry) => {
    if (!events[0].reviewTitle) return undefined
    const team = entry.player.team ?? 0

    const [otherTeam, otherTeamName] =
      Object.entries(teamNames)
        .filter(([entryTeam]) => entryTeam !== team.toString())
        .firstOrNull() ?? []

    return {
      title: events[0].reviewTitle,
      reviewId: reviewId,
      playerId: entry.player.id,
      player: entry.player.name,
      value: entry.value ?? null,
      team: team,
      teamName: teamNames[team] ? normaliseName(teamNames[team]) : null,
      opponentName: otherTeamName ? normaliseName(otherTeamName) : null,
      dividers: getDividers(reviewId, events[0].reviewTitle),
      displayValue: entry.displayValue ?? null,
      displayBreakdown: entry.displayBreakdown ?? null,
    } satisfies FirebasePlayerGameResult & { dividers: ResultDividers }
  })
  return measureResultsByReview
}

function useUnloadAlert(shouldAlert: boolean, message: string) {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldAlert) {
        // Standard for most browsers
        event.preventDefault()
        // Required for some browsers
        event.returnValue = ''
        // Custom message for older browsers (not always supported in modern ones)
        return message
      }
    }

    // Add event listener
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Clean up
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [shouldAlert, message])
}

export function ignoreMultiKeyedResultErrors<K extends string, T>(
  result: MultiKeyedDatabaseResult<K, T>,
): { results: { [k in K]?: T | undefined }; errors: { [k in K]?: Error | undefined } } {
  return Object.entries(result).reduce(
    (acc, [key, value]) => {
      if (value instanceof Error) {
        acc.errors[key as K] = value as Error
        return acc
      }
      acc.results[key as K] = (value ?? undefined) as T | undefined
      return acc
    },
    { errors: {}, results: {} } as { results: { [k in K]?: T | undefined } } & {
      errors: { [k in K]?: Error | undefined }
    },
  )
}

function getExistingRounds(results: LeaderboardResults): RoundInfo[] {
  return Object.entries(results)
    .reduce<RoundInfo[]>((acc, [measureKey, measureResults]) => {
      Object.entries(measureResults).forEach(([pushKey, result]) => {
        if (!matchRoundInfo(acc, result.dividers.round)) acc.push(result.dividers.round)
      })
      return acc
    }, [])
    .orderByDesc((it) => it.number)
}

function showPublishConfirmationDialog(
  publicLeaderboardId: string | null,
  roundsAvailable: RoundInfo[],
) {
  return new Promise<
    | {
        roundsAvailable: RoundInfo[]
        roundsSelected: RoundInfo[]
      }
    | undefined
  >((resolve) => {
    showDialog<{
      roundsAvailable: RoundInfo[]
      roundsSelected: RoundInfo[]
    }>(
      {
        title: 'Publish leaderboard',
        children: (Red, state, setState) => (
          <>
            {!publicLeaderboardId && (
              <>
                This will make the leaderboard <Red>available to the public</Red>
              </>
            )}
            {publicLeaderboardId && (
              <>
                This will <Red>update the public leaderboard</Red> to match this private leaderboard
              </>
            )}
            <MultiSelect<RoundInfo>
              getKey={(it) => `${it.category}_${it.name}_${it.number}`}
              options={state.roundsAvailable
                // .filter((it) => it.category === 'R')
                .map((it) => ({
                  children: (
                    <>
                      {it.category} {it.number}
                    </>
                  ),
                  value: it,
                }))}
              selected={state.roundsSelected}
              setSelected={(selected) =>
                setState((it) => ({
                  ...it,
                  roundsSelected:
                    typeof selected === 'function' ? selected(it.roundsSelected) : selected,
                }))
              }
            />
          </>
        ),
        positiveButtonProps: {
          text: 'Publish',
          onClicked: (state) => {
            resolve(state)
            return true
          },
        },
        negativeButtonProps: 'Cancel',
        onDismiss: () => {
          resolve(undefined)
        },
        user_dismissable: true,
      },
      { roundsAvailable, roundsSelected: roundsAvailable },
    )
  })
}
function filterMeasureResultsByRound(
  results: NonNullable<FirebaseLeaderboardResults[DodgeballMeasureKeys]>,
  rounds: RoundInfo[],
): FirebaseLeaderboardResults[DodgeballMeasureKeys] {
  return Object.entries(results).reduce(
    (acc, [nameHash, value]) => {
      acc[nameHash as string] = Object.entries(value)
        .filter(([pushKey, result]) => matchRoundInfo(rounds, result.dividers.round))
        .toObject()
      return acc
    },
    {} as NonNullable<FirebaseLeaderboardResults[DodgeballMeasureKeys]>,
  )
}

function matchRoundInfo(rounds: RoundInfo[], round: RoundInfo) {
  return rounds.some(
    (it) => it.number === round.number && it.category === round.category && it.name === round.name,
  )
}

export function resultsByMeasureToHashesByDivision(
  measureResultsByPlayer: FirebaseBaseLeaderboardEntry['resultsByPlayer'][DodgeballMeasureKeys],
  measure: Measure,
): { [k: string]: string } {
  return Object.values(measureResultsByPlayer ?? {})
    .flatMap((resultsByPlayer) => Object.values(resultsByPlayer))
    .groupBy((it) => it.reviewId)
    .mapValues((reviewResults) => reviewResultsToHashesByDivision(reviewResults, measure))
    .valuesArray()
    .reduce<{ [k: string]: string }>((acc, updates) => {
      return Object.assign(acc, updates)
    }, {})
}

function reviewResultsToHashesByDivision(
  reviewResults: (FirebasePlayerGameResult & { dividers: ResultDividers })[],
  measure: Measure,
): { [k: string]: string } {
  const measureResultsHash = objectHash(reviewResults)
  return reviewResults.reduce<{ [pushKey: string]: any }>((acc, reviewResult) => {
    const teamNameHash = stringAsDatabaseKey(
      reviewResult.teamName ?? (reviewResult.team ?? 0).toString(),
    )
    const pushKey = `${reviewResult.reviewId}_${teamNameHash}`
    if (!pushKey) return acc
    acc[
      `resultsHashesByDivision/${reviewResult.dividers.division?.key ?? '_'}/${reviewResult.reviewId}/${teamNameHash}/${measure.key}`
    ] = measureResultsHash
    return acc
  }, {})
}
