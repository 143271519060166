import { Flex, Image } from '@chakra-ui/react'
import React, { forwardRef, useRef } from 'react'
import closeIcon from '../icons/close_button.png'
import { useCompositeRefObject } from './UseCompositeRefObject'

export const SearchInput = forwardRef<
  HTMLInputElement,
  Omit<React.HTMLProps<HTMLInputElement>, 'onSubmit'> & {
    id?: string
    submitText?: string
    onSubmit: (value: string) => void
    onClose?: () => void
  }
>(function SearchInput({ id, submitText = 'Go', onSubmit, onClose, ...props }, ref) {
  const inputRef = useRef<HTMLInputElement>()
  const refs = useCompositeRefObject(ref, inputRef)
  return (
    <Flex
      gap={4}
      h={35}
      w={'100%'}>
      <input
        id={id}
        className={
          'box-border h-[35px] w-[calc(100%_-_50px)] max-w-[500px] rounded-[4px] border-none p-[8px]'
        }
        type={'text'}
        ref={refs}
        onKeyDown={(e) => e.key === 'Enter' && inputRef.current && onSubmit(e.currentTarget.value)}
        {...props}
      />
      <div
        className={`flex h-[35px] items-center rounded-[4px] bg-[#d63e4f] px-6 py-0 font-league-spartan text-[14px]
          font-bold !text-[#e8e8e8] hover:brightness-[120%]`}
        onClick={() => inputRef.current && onSubmit(inputRef.current.value)}>
        {submitText}
      </div>
      {onClose && (
        <Image
          draggable={false}
          src={closeIcon}
          onClick={onClose}
          w={35}
          h={35}
          padding={6}
          _hover={{ opacity: 0.8 }}
        />
      )}
    </Flex>
  )
})
