// returns a string in the format "HH:MM:SS" or "MM:SS"
export function displayTime(time: number, padZeros = 2): string {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.floor(time % 60)

  // Add leading zeros if needed
  const formattedHours = hours.toString().padStart(padZeros, '0')
  const formattedMinutes = minutes.toString().padStart(padZeros, '0')
  const formattedSeconds = seconds.toString().padStart(padZeros, '0')

  // Return the formatted string
  if (time >= 3600) return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  else return `${formattedMinutes}:${formattedSeconds}`
}

export function displayTimeRange(time: number, end?: number): string {
  if (end !== undefined) {
    return `${displayTime(time)} - ${displayTime(end)}`
  }
  return displayTime(time)
}
