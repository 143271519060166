import attack from '../../assets/img/dodgeballicons/attack.png'
import attack_black from '../../assets/img/dodgeballicons/attack_black.png'
import attack_died from '../../assets/img/dodgeballicons/attack_died_white_purple.png'
import block from '../../assets/img/dodgeballicons/block.png'
import catch1 from '../../assets/img/dodgeballicons/catch.png'
import catch_died from '../../assets/img/dodgeballicons/catch_died_white_purple.png'
import catch_kill from '../../assets/img/dodgeballicons/catch_kill.png'
import counter_rush from '../../assets/img/dodgeballicons/counter_rush.png'
import counter_rush_black_red from '../../assets/img/dodgeballicons/counter_rush_black_red.png'
import defend from '../../assets/img/dodgeballicons/defend.png'
import defend_black from '../../assets/img/dodgeballicons/defend_black.png'
import died from '../../assets/img/dodgeballicons/died_white.png'
import dodge from '../../assets/img/dodgeballicons/dodge.png'
import dodge_died from '../../assets/img/dodgeballicons/dodge_died_white_purple.png'
import foot_foul_died from '../../assets/img/dodgeballicons/foot_foul_died.png'
import hit from '../../assets/img/dodgeballicons/hit.png'
import miss from '../../assets/img/dodgeballicons/miss.png'
import ontarget from '../../assets/img/dodgeballicons/ontarget.png'
import opp_shot from '../../assets/img/dodgeballicons/oppshot.png'
import oppshot_red from '../../assets/img/dodgeballicons/oppshot_red.png'
import punish from '../../assets/img/dodgeballicons/punish.png'
import punish_black_red from '../../assets/img/dodgeballicons/punish_black_red.png'
import revive from '../../assets/img/dodgeballicons/revive.png'
import set_up_kill from '../../assets/img/dodgeballicons/set_up_kill.png'
import setthrow from '../../assets/img/dodgeballicons/setthrow.png'
import setthrow_black from '../../assets/img/dodgeballicons/setthrow_black.png'
import setthrow_black_red from '../../assets/img/dodgeballicons/setthrow_black_red.png'
import trade from '../../assets/img/dodgeballicons/trade.png'
import trade_died from '../../assets/img/dodgeballicons/trade_died.png'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace DodgeballIcons {
  export const hit_icon = hit
  export const miss_icon = miss
  export const ontarget_icon = ontarget
  export const attack_icon = attack
  export const trade_icon = trade
  export const trade_died_icon = trade_died
  export const attack_black_icon = attack_black
  export const setthrow_black_icon = setthrow_black
  export const attack_died_icon = attack_died
  export const block_icon = block
  export const catch_icon = catch1
  export const catch_died_icon = catch_died
  export const defend_icon = defend
  export const defend_black_icon = defend_black
  export const died_icon = died
  export const dodge_icon = dodge
  export const dodge_died_icon = dodge_died
  export const opp_shot_icon = opp_shot
  export const foot_foul_died_icon = foot_foul_died
  export const punish_icon = punish
  export const catch_kill_icon = catch_kill
  export const oppshot_red_icon = oppshot_red
  export const punish_black_red_icon = punish_black_red
  export const set_up_kill_icon = set_up_kill
  export const setthrow_black_red_icon = setthrow_black_red
  export const setthrow_icon = setthrow
  export const revive_icon = revive
  export const counter_rush_black_red_icon = counter_rush_black_red
  export const counter_rush_icon = counter_rush
}

export default DodgeballIcons
