// import layouts
import { StoryBook } from 'NavigationBar'
import { Suspense, lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { PageLoader } from './components/PageLoader'

// export const routes = (
//   <Route path="/" element={<MetaLayout />}>
//     <Route path="/" element={<WelcomeView />} />
//     <Route path="editor" element={<PlaybackView />}></Route>
//     <Route path="signin" element={<SignInView />} />
//     <Route path="*" element={<Navigate to="home" />} />
//   </Route>
// );

const About = lazy(() => import('./About'))
const BookAReview = lazy(() => import('./BookAReview'))
const MediaPage = lazy(() => import('./MediaPage'))
const App = lazy(() => import('./App'))
const DeleteAccountPage = lazy(() => import('./DeleteAccountPage'))
const Home = lazy(() => import('./Home'))
const StatRecordPage = lazy(() => import('./StatsRecordPage'))
const InsightsPage = lazy(() => import('./InsightsPage'))
const WatcherPage = lazy(() => import('./WatcherPage'))
const InternalSupportPage = lazy(() => import('./InternalSupportPage'))
const SignInPage = lazy(() => import('./SignInPage'))
const GroupRankingPage = lazy(() => import('./GroupRankingPage'))
const PublicLeaderboardPage = lazy(() => import('./PublicLeaderboardPage'))
const TeamStatsRegistrationPage = lazy(() => import('./TeamStatsRegistrationPage'))
const TeamInvitePage = lazy(() => import('./TeamInvitePage'))
const AdminPage = lazy(() => import('./AdminPage'))
const LeagueStatsDeckPage = lazy(() => import('./LeagueStatsDeckPage'))

export const routes = (
  <>
    <Route
      path='/'
      element={
        <Suspense fallback={<PageLoader />}>
          <Home />
        </Suspense>
      }
    />
    <Route
      path='editor'
      element={
        <Suspense fallback={<PageLoader />}>
          <App />
        </Suspense>
      }
    />
    <Route
      path='watch'
      element={
        <Suspense fallback={<PageLoader />}>
          <WatcherPage />
        </Suspense>
      }
    />
    <Route
      path='insights'
      element={
        <Suspense fallback={<PageLoader />}>
          <InsightsPage />
        </Suspense>
      }
    />
    <Route
      path='statsrecord'
      element={
        <Suspense fallback={<PageLoader />}>
          <StatRecordPage />
        </Suspense>
      }
    />
    <Route
      path='signin'
      element={
        <Suspense fallback={<PageLoader />}>
          <SignInPage />
        </Suspense>
      }
    />
    <Route
      path='about'
      element={
        <Suspense fallback={<PageLoader />}>
          <About />
        </Suspense>
      }
    />
    <Route
      path='media'
      element={
        <Suspense fallback={<PageLoader />}>
          <MediaPage />
        </Suspense>
      }
    />
    <Route
      path='bookareview'
      element={
        <Suspense fallback={<PageLoader />}>
          <BookAReview />
        </Suspense>
      }
    />
    <Route
      path='deleteaccount'
      element={
        <Suspense fallback={<PageLoader />}>
          <DeleteAccountPage />
        </Suspense>
      }
    />
    <Route
      path='internalsupport'
      element={
        <Suspense fallback={<PageLoader />}>
          <InternalSupportPage />
        </Suspense>
      }
    />
    <Route
      path='rankings'
      element={
        <Suspense fallback={<PageLoader />}>
          <GroupRankingPage />
        </Suspense>
      }
    />
    <Route
      path='leaderboards'
      element={
        <Suspense fallback={<PageLoader />}>
          <PublicLeaderboardPage />
        </Suspense>
      }
    />
    <Route
      path='league/statsregistration'
      element={
        <Suspense fallback={<PageLoader />}>
          <TeamStatsRegistrationPage />
        </Suspense>
      }
    />
    <Route
      path='teaminvite'
      element={
        <Suspense fallback={<PageLoader />}>
          <TeamInvitePage />
        </Suspense>
      }
    />
    <Route
      path='admin'
      element={
        <Suspense fallback={<PageLoader />}>
          <AdminPage />
        </Suspense>
      }
    />
    <Route
      path='storybook'
      element={
        <Suspense fallback={<PageLoader />}>
          <StoryBook />
        </Suspense>
      }
    />
    <Route
      path='leagues'
      element={
        <Suspense fallback={<PageLoader />}>
          <LeagueStatsDeckPage />
        </Suspense>
      }
    />

    <Route
      path='*'
      element={
        <Suspense fallback={<PageLoader />}>
          <Navigate to='/' />
        </Suspense>
      }
    />
  </>
)
