import { Box, BoxProps } from '@chakra-ui/react'
import * as React from 'react'
import { PropsWithChildren, forwardRef, useCallback, useRef } from 'react'
import { useCompositeRefObject } from './UseCompositeRefObject'
import { cn } from './common/utils/tailwindUtils'

export const ScrollBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren &
    React.HTMLProps<HTMLDivElement> & {
      direction: 'horizontal' | 'vertical' | 'both'
      startPos?: 'center'
      handleScrollWheel?: boolean
    }
>(function ScrollBox(
  { children, direction, startPos, handleScrollWheel, className, ...htmlDivProps },
  forwardedRef,
) {
  const ref = useRef<HTMLDivElement>()
  const handleWheel = useCallback(
    (event: React.WheelEvent) => {
      if (ref.current) {
        if (direction === 'horizontal' && handleScrollWheel) {
          // noinspection JSSuspiciousNameCombination
          ref.current?.scrollBy({ left: event.deltaY })
          event.stopPropagation()
        }
      }
    },
    [direction, handleScrollWheel],
  )
  const scrollToStart = useCallback(
    (instance: HTMLDivElement | null) => {
      if (startPos === 'center' && instance)
        setTimeout(() => {
          instance.scrollTo({
            left: (instance.scrollWidth - instance.clientWidth) / 2,
            behavior: 'smooth',
          })
        }, 2000)
    },
    [startPos],
  )
  const multiRef = useCompositeRefObject(ref, forwardedRef, scrollToStart)
  return (
    <div
      ref={multiRef}
      {...htmlDivProps}
      className={cn(
        'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-950 ',
        direction === 'horizontal' || direction === 'both' ? 'overflow-x-auto' : 'overflow-x-clip',
        direction === 'vertical' || direction === 'both' ? 'overflow-y-auto' : 'overflow-y-clip',
        className,
      )}
      onWheel={handleWheel}>
      {children}
    </div>
  )
})
