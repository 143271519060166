// Redecalare forwardRef
import * as React from 'react'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { EventDefinition, SKETCH_NOTE_DEFINITION } from 'templates/TemplateConfig'
import { emojiDisplay } from 'ui/ReplyBar'
import { TimelineEvent } from '../data/TimelineEvent'
import { showCommentPanel } from '../ui/VerticalCommentsPanel'
import { InlineShortcutIcon } from './ShortcutIcon'
import { useCompositeRefObject } from './UseCompositeRefObject'
import { EditableStringDiv } from './common/EditableStringDiv'
import { RoundButton } from './common/RoundButton'
import { useSingleAndDoubleClick } from './common/UseSingleAndDoubleClick'
import { cn } from './common/utils/tailwindUtils'

declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement
}
export const NoteCard = React.forwardRef(NoteCardImpl)

function NoteCardImpl<T>(
  {
    event,
    ...props
  }: {
    className?:string
    event: TimelineEvent<T>
    editOnMount?: boolean
    isSemiSelected?: boolean
    editMode: boolean
    canEnableEditing: boolean
    onDeleteClicked?: (event: TimelineEvent<T>) => void
    onUpdate?: (event: TimelineEvent<T>) => void
    onClicked?: (event: TimelineEvent<T>) => void
    onBlur?: (event: TimelineEvent<T>) => void
    style?: CSSProperties
  },
  forwardedRef: React.ForwardedRef<EditableStringDiv>,
) {
  const repliesByEmoji = useMemo(
    () =>
      event.specialType === 'note' ?
        Object.values(event.replies).groupBy((it) => it.message)
      : undefined,
    [event],
  )
  const emojiEntries = useMemo(() => repliesByEmoji?.entriesArray(), [repliesByEmoji])
  const emojiCount = useMemo(
    () => emojiEntries?.map(([, arrray]) => arrray.length).sum(),
    [emojiEntries],
  )

  const extraEditableDivRef = useRef<EditableStringDiv>(null)
  const [editOnMount, setEditOnMount] = useState(props.editOnMount)
  const isSketch =
    (event.tag as EventDefinition).key === SKETCH_NOTE_DEFINITION.key ||
    event.extra?.drawing?.length

  const handleExtraMessageChange = (message: string) => {
    props.onUpdate?.({
      ...event,
      extra: {
        ...event.extra,
        message: message.trim(),
      },
    })
  }
  const handleExtraSubmit = (extra: string) => {
    if (!extra && !event.extra?.drawing?.length) {
      props.onDeleteClicked?.(event)
    }
  }
  const handleExtraClick = useCallback(
    (e: React.MouseEvent) => {
      if (extraEditableDivRef.current?.hasFocus()) return
      e.stopPropagation()
      props.onClicked?.(event)
    },
    [props, event],
  )
  const extraClickHandler = useSingleAndDoubleClick({
    actionDoubleClick: () => {
      if (extraEditableDivRef.current?.hasFocus()) return
      showCommentPanel()
    },
    stopPointerUpPropagation: true,
  })
  const handleBlur = useCallback(() => {
    props.onBlur?.(event)
  }, [props, event])
  const multiRef = useCompositeRefObject(forwardedRef, extraEditableDivRef)
  return (
    <div
      className={props.className}
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'transform 200ms ease-in-out, background-color 200ms ease-in-out',
        ...props.style,
      }}>
      {!!emojiCount && (
        <div
          style={{
            top: '-10px',
            borderRadius: '9px',
            padding: '2px',
            width: 'fit-content',
            fontSize: '10px',
            color: 'white',
            fontFamily: 'LeagueSpartan,sans-serif',
          }}>
          {emojiEntries?.map(([key, array]) => emojiDisplay.get(key) ?? `${key}`)}
          {emojiEntries?.map(([, arrray]) => arrray.length).sum()}
        </div>
      )}
      <EditableStringDiv
        ref={multiRef}
        translate={'no'}
        focusOnMount={editOnMount}
        className={cn(
          `top-[60px] block max-h-[60px] min-w-[100px] origin-bottom cursor-text items-start justify-center
          justify-items-center overflow-hidden text-ellipsis whitespace-pre-wrap rounded-[10px] bg-[#353a44]
          px-[8px] pb-[30px] pt-[20px] text-[1.2em] normal-case text-white transition-all duration-[0.3s]
          ease-in-out empty:bg-[rgba(51,52,56,0.79)] hover:max-h-[unset] hover:overflow-y-auto
          hover:opacity-[100%] hover:brightness-[110%] focus:max-h-[unset] focus:overflow-y-auto
          focus:opacity-[100%]`,
          props.isSemiSelected ? 'outline outline-2 outline-[rgba(255,255,255,0.3)]' : '',
        )}
        onChange={handleExtraMessageChange}
        onEnter={handleExtraSubmit}
        placeholder={
          props.editMode || props.canEnableEditing ?
            isSketch ?
              'Add a note to this sketch'
            : 'Add note'
          : 'Empty comment'
        }
        blurOnEnter={!isMobile}
        style={{}}
        placeholderStyle={{
          color: props.editMode || props.canEnableEditing ? 'rgb(241,191,70)' : 'rgb(101,101,101)',
        }}
        disabled={!(props.editMode || props.canEnableEditing || editOnMount)}
        onPointerUp={extraClickHandler.onUp}
        onPointerDown={extraClickHandler.onDown}
        onPointerMove={extraClickHandler.onMove}
        onBlur={handleBlur}
        onClick={handleExtraClick}>
        {event?.isDeleted ? 'deleted comment' : event.extra?.message}
      </EditableStringDiv>
      {extraEditableDivRef.current?.hasFocus() && (
        <RoundButton
          alt={'Done'}
          className='right-0 top-0 h-[20px] translate-x-1/2 translate-y-1/2 transform p-[4px] text-[1em]'
          style={{ boxShadow: '0 0 0 2px rgb(255 255 255 / 10%)' }}
          backgroundColor={'rgba(11,14,28)'}
          color={'white'}
          onClick={() => props.onBlur?.(event)}>
          Done{' '}
          <InlineShortcutIcon
            shortcut={'Enter'}
            quickKey={true}
          />
        </RoundButton>
      )}
    </div>
  )
}
