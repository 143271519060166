import { Box, Flex } from '@chakra-ui/react'
import * as React from 'react'
import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { isInputEvent } from '../util/inputUtils'
import { cn } from './common/utils/tailwindUtils'

export function CompositeMarker<T>({
  left,
  opacity,
  shouldSimplify,
  isNote,
  displayStyle,
  ...props
}: PropsWithChildren<{
  shouldSimplify: boolean
  isNote: boolean
  displayStyle: 'above' | 'centre' | 'below'
  left?: string
  opacity?: string
}>) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isHovering, setIsHovering] = useState(false)

  const hasFocus = useCallback(() => {
    return containerRef.current?.contains(document.activeElement)
  }, [])
  const handleContainerOnPointerEnter = useCallback((e: React.PointerEvent) => {
    setIsHovering(true)
  }, [])
  const handleContainerOnPointerLeave = useCallback((e: React.PointerEvent) => {
    setIsHovering(false)
  }, [])

  const onKeyDown = useCallback((ev: React.KeyboardEvent) => {
    if (isInputEvent()) return
    if (ev.key === 'Escape') {
      ev.stopPropagation()
      containerRef.current?.blur()
    }
  }, [])
  return (
    <>
      <Box
        style={{
          position: 'absolute',
          left: left,
        }}
        onPointerOver={handleContainerOnPointerEnter}
        onPointerOut={handleContainerOnPointerLeave}>
        <div
          className={cn(
            `top-[-5px] flex h-[35px] w-[20px] -translate-x-2/4 translate-y-[24px] cursor-grab justify-center
            rounded-[10px] border-0 border-solid border-white bg-[rgba(141,141,141,0.25)]`,
            isNote ? 'bookmark' : '',
            displayStyle === 'above' ? '!-translate-x-2/4 !-translate-y-[-36px]'
            : displayStyle === 'centre' ?
              '!h-[20px] !-translate-x-2/4 !translate-y-0 !bg-[rgba(141,141,141,0.8)]'
            : '',
          )}
          tabIndex={0}
          style={{
            // zIndex: isNote ? (props.event.extra ? 10 : 100) : undefined,
            // rotate:isNote ?`180deg`:undefined
            position: 'relative',
            opacity: !(isHovering || hasFocus()) ? opacity : 0,
            zIndex: 0,
          }}
          onPointerOver={handleContainerOnPointerEnter}
          onPointerOut={handleContainerOnPointerLeave}
        />
        {(isHovering || hasFocus()) && (
          <Flex
            direction={'row'}
            justifyContent={'flex-start'}
            style={{
              overflow: 'visible',
              width: 'fit-content',
              zIndex: 6,
              pointerEvents: 'auto',
            }}>
            {props.children}
          </Flex>
        )}
      </Box>
    </>
  )
}
