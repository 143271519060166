import { Flex } from '@chakra-ui/react'
import { P } from 'About'
import { GroupSelectionStore, useGroupSelectionStore } from 'UseGroupSelectionStore'
import { AvatarDisplay } from 'components/UploadAvatar'
import { FirebaseGroupDetails } from 'data/common'
import { User } from 'firebase/auth'
import { useCallback } from 'react'
import ReactTagManager from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { showDialog } from '../components/common/Dialog'
import { EditableStringDiv } from '../components/common/EditableStringDiv'
import { FirebaseDb } from '../components/common/Firebase'
import { generateRandomColor } from './PlayerStore'

export function useGroupDialog(
  firebaseDb: FirebaseDb,
  user: User | undefined,
): {
  showCreateNewGroupDialog: () => Promise<
    Awaited<ReturnType<GroupSelectionStore['createNewGroup']>>
  >
} {
  const randomColor = generateRandomColor()
  const groupSelectionStore = useGroupSelectionStore({
    firebaseDb,
    user: user ?? undefined,
  })

  const showCreateNewGroupDialog = useCallback(() => {
    ReactTagManager.event('create_new_group_dialog_shown', { category: 'group' })
    return new Promise<Awaited<ReturnType<GroupSelectionStore['createNewGroup']>>>((resolve) => {
      showDialog<FirebaseGroupDetails>({
        title: 'Create new team',
        positiveButtonProps: {
          text: 'Create',
          disabled: (groupState) => !groupState?.name,
          onClicked: async (groupState) => {
            ReactTagManager.event('create_new_group_dialog_create_clicked', { category: 'group' })
            if (!groupState?.name) return false

            const result = await groupSelectionStore.createNewGroup({
              ...groupState,
              color: randomColor,
            })

            resolve(result)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Cancel',
          onClicked: () => true,
        },
        onDismiss: () => resolve(undefined),
        children: (Red, groupState, setGroupState) => GroupDialogBody(randomColor, setGroupState),
      })
    })
  }, [groupSelectionStore, randomColor])

  return {
    showCreateNewGroupDialog,
  }
}

function GroupDialogBody(
  randomColor: string,
  setGroupState: React.Dispatch<React.SetStateAction<FirebaseGroupDetails | undefined>>,
) {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={20}>
      <AvatarDisplay
        backgroundColor={randomColor}
        onChange={(url) => setGroupState((state) => ({ ...state, avatarUrl: url }))}
      />
      <EditableStringDiv
        placeholder={'Team short name'}
        onChange={(value) => setGroupState((state) => ({ ...state, name: value?.trim() }))}
      />
    </Flex>
  )
}
