import {
  BaseSportsTemplate,
  BaseTemplate,
  SPECIAL_DEFINITIONS,
  UISetup,
} from 'templates/TemplateConfig'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import '../components/common/utils/typescriptUtils'
import { ReviewActivityType } from '../ui/ReviewMetaStore'

export const ALL_DEFINITIONS = DodgeballTemplate.buttonSetup.definitions
  .valuesArray()
  .concat(SPECIAL_DEFINITIONS)
  .distinct()
export const ActivityTemplates: Record<ReviewActivityType, UISetup> = {
  sports_notes: BaseSportsTemplate,
  general_notes: BaseTemplate,
  dodgeball: DodgeballTemplate.buttonSetup,
}
