import { Box } from '@chakra-ui/react'
import { CSSProperties } from 'react'
import { VideoController, useVideoPlayerStatus } from '../components/VideoController'
import { PlayersStatisticsState } from '../data/StatsStore'

export function LiveAppScore({
  statisticsState,
  videoController,
  ...props
}: {
  scale?: number
  videoController: VideoController
  statisticsState: PlayersStatisticsState
  positionMode?: 'normal' | 'lower'
  style?: CSSProperties
  showScoreTitle?: boolean
  shadows?: boolean
}) {
  const liveScore = useVideoPlayerStatus<[number, number, number]>(
    videoController,
    30,
    true,
    (state) => statisticsState.scoreAtTime(state.currentTime),
  )
  return (
    <AppScore
      {...props}
      liveScore={liveScore}
    />
  )
}

export function AppScore({
  liveScore,
  positionMode = 'normal',
  style,
  showScoreTitle = true,
  shadows = true,
  scale = 0.8,
}: {
  scale?: number
  liveScore: [number, number, number]
  positionMode?: 'normal' | 'lower'
  style?: CSSProperties
  showScoreTitle?: boolean
  shadows?: boolean
}) {
  return (
    <Box
      style={{
        ...style,
        zIndex: style?.zIndex ?? (positionMode === 'normal' ? 0 : 1),
        bottom:
          style?.bottom ?? (positionMode === 'normal' ? `${8 * scale}px` : `-${20 * scale}px`),
        fontVariationSettings: "'wght' 700",
        fontSize: `${24 * scale}px`,
      }}
      pointerEvents='none'
      transition='all 500ms ease-in-out'
      fontFamily='Montserrat, sans-serif'
      display='flex'
      lineHeight={1}
      justifyContent='center'
      alignItems='center'
      position='absolute'>
      <Box
        textAlign={'center'}
        borderRadius={`999px 0 0 999px`}
        backgroundColor={'rgba(255, 255, 255, 1)'}
        color={'black'}
        style={{
          boxShadow: shadows ? `0 0 ${10 * scale}px rgba(0,0,0,0.34)` : undefined,
          minWidth: `${24 * scale}px`,
          padding: `${8 * scale}px ${8 * scale}px ${8 * scale}px ${16 * scale}px`,
        }}>
        {liveScore[0]}
      </Box>
      {liveScore[2] ?
        <Box
          textAlign={'center'}
          backgroundColor={'rgb(89,89,89)'}
          color={'white'}
          style={{
            width: `${24 * scale}px`,
            padding: `${8 * scale}px ${8 * scale}px ${8 * scale}px ${8 * scale}px`,
            boxShadow: shadows ? `0 0 ${10 * scale}px rgba(0,0,0,0.34)` : undefined,
          }}>
          {liveScore[2]}
        </Box>
      : undefined}
      <Box
        textAlign={'center'}
        borderRadius={`0 999px 999px 0`}
        backgroundColor={'rgba(221, 61, 78, 1)'}
        color={'white'}
        style={{
          padding: `${8 * scale}px ${16 * scale}px ${8 * scale}px ${8 * scale}px`,
          boxShadow: shadows ? `0 0 ${10 * scale}px rgba(0,0,0,0.34)` : undefined,
          minWidth: `${24 * scale}px`,
        }}>
        {liveScore[1]}
      </Box>
      {showScoreTitle && (
        <Box
          className={'absolute text-center'}
          position={'absolute'}
          textAlign={'center'}
          style={{
            padding: `${scale}px ${8 * scale}px 0`,
            borderRadius: `${5 * scale}px`,
            border: `${2 * scale}px solid #DD3D4E`,
            bottom: `-${5 * scale}px`,
            fontSize: `${10 * scale}px`,
            fontVariationSettings: "'wght' 600",
            minWidth: `${24 * scale}px`,
          }}
          textTransform={'uppercase'}
          color={'#DD3D4E'}
          background={'rgb(255, 255, 255)'}>
          score
        </Box>
      )}
    </Box>
  )
}
