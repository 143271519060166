import { Flex } from '@chakra-ui/react'
import { User } from 'firebase/auth'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { WrappedAuth } from './Firebase'
import { RoundButton } from './RoundButton'
import { SquashingBox } from './SquashingBox'
import { hydrateUserUI } from './UseFirebaseUi'
import { randomUUID } from './utils/randomUUID'
import { cn } from './utils/tailwindUtils'

interface UseLoginParams {
  auth: WrappedAuth
  allowAnonymous?: boolean
  welcomeMessage?: string
}

const BROWSER_ID_KEY = 'BROWSER_ID'

export function useBrowserId(localStorage: Storage) {
  const browserId = useMemo(() => {
    let storedId = localStorage.getItem(BROWSER_ID_KEY)

    if (!storedId) {
      storedId = randomUUID()
      localStorage.setItem(BROWSER_ID_KEY, storedId)
    }
    return storedId
  }, [localStorage])
  return { browserId }
}

export function useUser({ auth, welcomeMessage = 'Welcome!' }: UseLoginParams) {
  const { user, navigateToSignIn, SignIn } = useUserNullable({
    auth: auth,
    welcomeMessage,
  })
  return useMemo(() => {
    return { user: user ?? undefined, navigateToSignIn, SignIn }
  }, [user, navigateToSignIn, SignIn])
}

export function useUserNullable({ auth, welcomeMessage = 'Welcome!' }: UseLoginParams) {
  const [user, setUser] = useState<User | null>()
  const location = useLocation()
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (user: User | null) => {
        if (user === null) {
          setUser(null)
          return
        }

        if (user.isAnonymous) {
          setUser(user)
          return
        }

        const hydratedUser = await hydrateUserUI(user, auth, welcomeMessage)
        setUser(hydratedUser)
      },
      (error) => {
        console.log(error)
      },
    )
    return () => unsubscribe()
  }, [auth, welcomeMessage])
  const navigate = useNavigate()
  const signOut = useCallback(async () => {
    await auth.signOut()
  }, [auth])
  const navigateToSignIn = useCallback(async () => {
    navigate(
      '/signin?redirect=' + encodeURIComponent(location.pathname + location.search + location.hash),
    )
  }, [location, navigate])
  const SignIn = useCallback(
    (props: { className?: string; displayStyle?: 'large' | 'small' }) => {
      return (
        <div
          className={cn(
            `group pointer-events-auto relative flex min-w-0 items-center justify-center gap-[4px] bg-transparent px-[8px] py-0
            font-league-spartan text-white`,
            props.className,
          )}>
          {user && !user.isAnonymous ?
            <>
              <SquashingBox
                className='min-w-0 text-nowrap'
                squashedChildrenArray={[user.displayName ?? 'Account']}>
                {user.displayName ? `Welcome, ${user.displayName}` : 'Account'}
              </SquashingBox>
              <RoundButton
                className='bg-[rgba(177, 182, 183, 0.34)] h-[2.5em] max-w-0 -translate-x-full scale-x-0 transform text-[0.8em]
                  text-black opacity-0 transition-all duration-200 ease-in-out group-hover:max-w-[999px]
                  group-hover:transform-none group-hover:opacity-100 group-hover:filter-none hover:bg-playback-crimson
                  hover:text-white hover:filter-none'
                onClick={signOut}
                alt={'Log out'}
                displayStyle={'small'}>
                Log out
              </RoundButton>
            </>
          : <RoundButton
              alt={'Sign in'}
              className='bg-[rgba(177, 182, 183, 0.34)] h-[2.5em] w-full min-w-0 max-w-fit text-[0.8em] text-black
                transition-all duration-200 ease-in-out group-hover:filter-none hover:bg-playback-crimson
                hover:text-white hover:filter-none'
              displayStyle={'small'}
              onClick={navigateToSignIn}>
              Sign in
            </RoundButton>
          }
        </div>
      )
    },
    [user, navigateToSignIn, signOut],
  )
  return { SignIn, navigateToSignIn, user }
}
