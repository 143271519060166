export function getAspectRatioFitDimensions(
  // width/height
  aspectRatio: number,
  maxWidth: number,
  maxHeight: number,
): [number, number] {
  const actualMaxHeight = maxWidth / aspectRatio
  const actualMaxWidth = maxHeight * aspectRatio
  return [Math.min(actualMaxWidth, maxWidth), Math.min(actualMaxHeight, maxHeight)]
}
