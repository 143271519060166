import {
  FirebasePlayerGameResult,
  LeaderboardResults,
  NswdlDivFilter,
  ResultDividers,
  RoundInfo,
} from 'data/leaderboardtypes'
import { useMemo } from 'react'
import { Measure } from '../components/RankingsByDiv'
import {
  RankedPlayerMeasureResults,
  calculateRankedResults,
  filterResults,
  gameResultsFilters,
  rankBy,
} from '../components/RankingsTable'
import { useLeaderboardFilterStrategy } from '../components/RankingsTableFilterStrategy'

export function useRankingsResults(
  results: LeaderboardResults,
  selectedRound: RoundInfo | undefined,
  selectedDivision: NswdlDivFilter,
  selectedMeasure: Measure,
  searchPlayerTerm?: string | undefined,
  searchTeamsTerm?: string | undefined,
  byTeam?: boolean,
) {
  return useRankingsResultsInternal(
    !byTeam ? results : (
      Object.entries(results)
        .map(
          ([measureKey, gameResults]) =>
            [
              measureKey,
              gameResults.map((result) => {
                return {
                  ...result,
                  player: `${result.teamName ?? 'No team'}${result.dividers.division ? ` | ${result.dividers.division.title}` : ''}`,
                  playerId: `${result.reviewId}_${result.team}`,
                } satisfies FirebasePlayerGameResult & {
                  dividers: ResultDividers
                }
              }),
            ] as const,
        )
        .toObject()
    ),
    selectedRound,
    selectedDivision,
    selectedMeasure,
    searchPlayerTerm,
    searchTeamsTerm,
  )
}
export function useRankingsResultsInternal(
  results: LeaderboardResults,
  selectedRound: RoundInfo | undefined,
  selectedDivision: NswdlDivFilter,
  selectedMeasure: Measure,
  searchPlayerTerm?: string | undefined,
  searchTeamsTerm?: string | undefined,
) {
  const filteredResults = useMemo(() => {
    return filterResults(
      results,
      selectedRound ?
        gameResultsFilters(selectedRound.category, selectedDivision.key, selectedRound.number)
      : () => false,
    )
  }, [results, selectedRound, selectedDivision])

  const rankings = useMemo(() => {
    return calculateRankedResults(filteredResults, selectedMeasure)
  }, [filteredResults, selectedMeasure])

  const {
    commonRankings,
    leaderboardTableRankings: filteredTableRankings,
    profileCarouselRankings,
  } = useLeaderboardFilterStrategy({ searchPlayerTerm, searchTeamsTerm })

  const rankingsByMeasure = useMemo(() => {
    // TODO(Davey): Figure out how to show all rankings
    const map = new Map<Measure, RankedPlayerMeasureResults[]>()
    map.set(selectedMeasure, commonRankings(rankings) ?? [])

    return map
  }, [selectedMeasure, rankings, commonRankings])

  const filterdCarouselRankingsByMeasure = useMemo(() => {
    return rankingsByMeasure.mapValues((rankings) => profileCarouselRankings(rankings))
  }, [rankingsByMeasure, profileCarouselRankings])

  const previousFilteredResults = useMemo(() => {
    return filterResults(
      results,
      selectedRound ?
        gameResultsFilters(selectedRound.category, selectedDivision.key, selectedRound.number - 1)
      : () => false,
    )
  }, [results, selectedRound, selectedDivision])

  const previousRankings = useMemo(() => {
    return calculateRankedResults(previousFilteredResults, selectedMeasure)
  }, [selectedMeasure, previousFilteredResults])

  const top3Record = useMemo(() => {
    const filteredMeasureResults = filteredResults[selectedMeasure.key]
    return (
      filteredMeasureResults &&
      rankBy(filteredMeasureResults, (it) => it.value ?? -1).filter((it) => it.rank <= 3)
    )
  }, [filteredResults, selectedMeasure])

  return {
    rankings,
    rankingsByMeasure,
    previousRankings,
    top3Record,
    filterdCarouselRankingsByMeasure,
    filteredTableRankings,
  }
}
