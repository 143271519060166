import { CSSProperties } from 'react'
import { cn } from './common/utils/tailwindUtils'

export const InlineShortcutIcon = (props: ShortcutIconProps) => {
  return (
    <ShortcutIcon
      {...props}
      className={`relative inline-flex ${props.className}`}
      style={{ ...props.style }}
    />
  )
}

export interface ShortcutIconProps {
  shortcut: string
  quickKey?: boolean
  invert?: boolean
  fontSize?: string | number
  className?: string
  style?: CSSProperties
}

export function ShortcutIcon({
  fontSize = '10px',
  quickKey,
  shortcut,
  className,
  invert = false,
  style,
}: ShortcutIconProps) {
  if (invert) {
    return (
      <div
        translate={'no'}
        className={cn(
          `absolute flex flex-col flex-nowrap content-center items-center justify-center rounded-xl p-0.5
          shadow-[0_0_0_4px_rgba(56,45,0,0.4)]`,
          quickKey ? 'bg-white text-[rgb(38,77,182)]' : 'bg-black text-[rgb(255,220,79)]',
          className,
        )}
        style={{
          minWidth: fontSize,
          ...style,
        }}>
        <span
          translate={'no'}
          className='relative whitespace-nowrap text-center font-[LeagueSpartan] uppercase text-inherit'
          style={{
            fontSize: fontSize,
            lineHeight: fontSize,
          }}>
          {shortcut}
        </span>
      </div>
    )
  }
  return (
    <div
      translate={'no'}
      className={cn(
        `absolute flex flex-col flex-nowrap content-center items-center justify-center rounded-xl p-0.5
        font-semibold shadow-[0_0_0_4px_rgba(56,45,0,0.4)]`,
        quickKey ? 'bg-[rgb(38,77,182)] text-white' : 'bg-[rgb(255,220,79)] text-black',
        className,
      )}
      style={{
        minWidth: fontSize,
        ...style,
      }}>
      <span
        translate={'no'}
        className='relative whitespace-nowrap text-center font-[LeagueSpartan] uppercase text-inherit'
        style={{
          fontSize: fontSize,
          lineHeight: fontSize,
        }}>
        {shortcut}
      </span>
    </div>
  )
}
