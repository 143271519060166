import React from 'react'
import { Helmet } from 'react-helmet'

export function DocumentTitle(props: { title: string; children: React.ReactNode }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {props.children}
    </React.Fragment>
  )
}
