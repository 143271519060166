import i18next from 'locales/i18n'
import I18nPaths from 'locales/i18nPaths'
import '../util/stringUtils'

export default class GeneralTemplateLocales {
  static titleLocalGetter(eventKey: string): () => string {
    return () => {
      const titleLocale =
        i18next.t(I18nPaths.Templates_General.concat('.', eventKey, '.title')) ?? eventKey
      return titleLocale.toTitleCase()
    }
  }

  static descriptionLocaleGetter(eventKey: string): () => string {
    return () => {
      const descriptionLocale =
        i18next.t(I18nPaths.Templates_General.concat('.', eventKey, '.description')) ?? eventKey
      return descriptionLocale.toTitleCase()
    }
  }
}
