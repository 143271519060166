import { Box } from '@chakra-ui/react'
import { ReactNode, createRef } from 'react'
import YouTube, { YouTubeEvent, YouTubePlayer } from 'react-youtube'
import PlayerStates from 'youtube-player/dist/constants/PlayerStates'
import { getYoutubeHqThumbnailLink } from '../util/links'
import { BaseVideoPlayer } from './BaseVideoPlayer'
import {
  DEFAULT_VIDEO_PLAYER_STATUS,
  VideoPlaybackState,
  VideoPlayerStatus,
} from './VideoPlayerStatus'

function mapYoutubePlaybackState(state: PlayerStates): VideoPlaybackState {
  switch (state) {
    case PlayerStates.BUFFERING:
      return 'BUFFERING'
    case PlayerStates.ENDED:
      return 'ENDED'
    case PlayerStates.PAUSED:
      return 'PAUSED'
    case PlayerStates.PLAYING:
      return 'PLAYING'
    case PlayerStates.UNSTARTED:
      return 'UNSTARTED'
    case PlayerStates.VIDEO_CUED:
      return 'VIDEO_CUED'
  }
}

export class YoutubeVideoPlayer extends BaseVideoPlayer<YouTubePlayer> {
  youtubeRef = createRef<YouTube>()
  currentStatus: VideoPlayerStatus = { ...DEFAULT_VIDEO_PLAYER_STATUS }

  getStatusOwner(): YouTubePlayer | undefined {
    return this.youtubeRef.current?.getInternalPlayer() || undefined
  }

  async play(): Promise<void> {
    await this.youtubeRef.current?.getInternalPlayer()?.playVideo()
  }

  async pause(): Promise<void> {
    await this.youtubeRef.current?.getInternalPlayer()?.pauseVideo()
  }

  async seekToInternal(time: number): Promise<void> {
    await this.youtubeRef.current?.getInternalPlayer()?.seekTo(time, true)
  }

  async scrubToInternal(time: number): Promise<void> {
    await this.youtubeRef.current?.getInternalPlayer()?.seekTo(time, true)
  }

  async setPlaybackRate(suggestedRate: number): Promise<void> {
    await this.youtubeRef.current?.getInternalPlayer()?.setPlaybackRate(suggestedRate)
  }

  protected async getPlayerStatusInternal(player: YouTubePlayer): Promise<VideoPlayerStatus> {
    const title = (player as any)['videoTitle'] as string | undefined
    const [currentTime, playerState, duration, playbackRate] = await Promise.all([
      player.getCurrentTime(),
      player.getPlayerState(),
      player.getDuration(),
      player.getPlaybackRate(),
    ])
    return {
      currentTime,
      playbackState: mapYoutubePlaybackState(playerState),
      duration,
      playbackRate,
      title,
      thumbnail: getYoutubeHqThumbnailLink(this.props.videoId),
    }
  }

  handlePlayerReady = async (event: YouTubeEvent) => {
    const player = event.target
    await player.mute()
    this.props.onPlayerReady(this, await this.getPlayerStatus(player))
  }

  render(): ReactNode {
    // let [maxWidth, maxHeight] = getAspectRatioFitDimensions(
    //   2 / 1,
    //   this.props.width ?? 999999,
    //   this.props.height ?? 9999999,
    // )
    // maxWidth = Math.floor(maxWidth)
    // maxHeight = Math.floor(maxHeight)
    return (
      <Box
        style={{
          width: this.props.width,
          maxHeight: this.props.height,
        }}>
        <YouTube
          ref={this.youtubeRef}
          videoId={this.props.videoId}
          onReady={this.handlePlayerReady}
          onPause={this.props.onPause}
          onPlay={this.props.onPlay}
          onPlaybackRateChange={(event) => this.props.onPlaybackRateChange(event.data)}
          onPlaybackQualityChange={(event) => this.props.onPlaybackQualityChange(event.data)}
          opts={{
            width: this.props.width,
            height: this.props.height,
            playerVars: {
              controls: 1,
              rel: 0,
              modestbranding: 1,
              iv_load_policy: 3,
              playsinline: 1,
              fs: 0,
              origin: 'https://playbackreview.com',
              // enablejsapi: 1
            },
          }}
        />
      </Box>
    )
  }
}
