import { EditableStringDiv } from 'components/common/EditableStringDiv'
import {
  FirebaseDb,
  useDatabaseNullableRefLiveValue,
  useDatabasePathLiveValue,
  useDatabaseRef,
  useMappedDatabaseRefLiveValue,
  useNullableDatabasePathLiveValue,
} from 'components/common/Firebase'
import { FirebaseFlagEntry } from 'components/common/hooks/useFeatureFlags'
import { UnreachableError } from 'components/common/utils/error'
import { normaliseName } from 'data/NormaliseName'
import { FLAG_TYPE } from 'data/common'
import React, { useCallback, useState } from 'react'
import { showDialog } from '../components/common/Dialog'
import { isNumeric } from './PlayerTile'

export type DeleteSettingProps = {
  flagType: FLAG_TYPE
  setting: { key: string; childrenKey?: string }
  listType?: 'users' | 'groups'
}

type DictionaryType<T> = {
  type: 'PENDING' | 'ERROR' | 'SUCCESS'
  error?: Error
  value?: T | null | undefined
}

export type SettingValueType = {
  key?: string
  value?: string
  uid?: string
  nickname?: string
  groupId?: string
  subscriptionTypeKey?: string
  admin?: boolean
}

export type HandleAddSettingProps = {
  flagType: FLAG_TYPE
  setting: SettingValueType
}

type UseFeatureFlagAdminStoreReturn = {
  generalDictionary: FirebaseFlagEntry['general']
  usersDictionary: FirebaseFlagEntry['users']
  groupsDictionary: FirebaseFlagEntry['groups']
  showAddSettingDialog: (flagType: FLAG_TYPE) => Promise<boolean | undefined> & {
    dismiss: () => void
  }
  showDeleteSettingDialog: (props: DeleteSettingProps) => Promise<boolean | undefined> & {
    dismiss: () => void
  }
}

export function useFeatureFlagAdminStore(firebaseDb: FirebaseDb): UseFeatureFlagAdminStoreReturn {
  const flagRef = useDatabaseRef<FirebaseFlagEntry>(firebaseDb, 'flags')
  const generalDictionary = useMappedDatabaseRefLiveValue(
    () => flagRef?.childFromKey('general'),
    [flagRef],
  )
  const usersDictionary = useMappedDatabaseRefLiveValue(
    () => flagRef?.childFromKey('users'),
    [flagRef],
  )
  const groupsDictionary = useMappedDatabaseRefLiveValue(
    () => flagRef?.childFromKey('groups'),
    [flagRef],
  )

  const handleGetValue = useCallback(<T,>(dictionary: DictionaryType<T>): T | undefined | null => {
    switch (dictionary.type) {
      case 'PENDING':
        return undefined
      case 'ERROR':
        return null
      case 'SUCCESS':
        return dictionary.value
      default:
        return undefined
    }
  }, [])

  const handleAddSetting = useCallback(
    async ({ flagType, setting }: { flagType: FLAG_TYPE; setting: SettingValueType }) => {
      if (!flagRef) return false

      switch (flagType) {
        case 'general':
          if (!setting?.key || !setting?.value) return false
          const newValue =
            ['true', 'false'].includes(setting.value.toLowerCase()) ?
              setting.value.toLowerCase() === 'true'
            : isNumeric(setting.value) ? Number(setting.value)
            : setting.value
          await flagRef.child(flagType).update({ [setting.key]: newValue })
          return true
        case 'staff':
          if (!setting?.uid) return false
          await flagRef
            .child('users')
            .child(setting.uid)
            .update(
              Object.assign({
                [flagType]: true,
                admin: setting.admin,
                nickname: setting.nickname,
              }),
            )
          return true
        case 'stats_pro':
          if (!setting?.uid) return false
          await flagRef
            .child('users')
            .child(setting.uid)
            .update({
              [flagType]: true,
              nickname: setting.nickname,
            })
          return true
        case 'subscription':
          if (!setting?.subscriptionTypeKey || !(setting?.uid || setting?.groupId)) return false

          setting.uid &&
            (await flagRef
              .child('users')
              .child(setting.uid)
              .update({ [setting.subscriptionTypeKey]: true }))

          setting.groupId &&
            (await flagRef
              .child('groups')
              .child(setting.groupId)
              .update({ [setting.subscriptionTypeKey]: true }))

          return true
        default:
          return false
      }
    },
    [flagRef],
  )

  const showAddSettingDialog = useCallback(
    (flagType: FLAG_TYPE) => {
      return showDialog<SettingValueType>(
        {
          title: `Add ${['stats_pro', 'league_pro'].includes(flagType) ? flagType.replace('_', ' ') : flagType}`,
          positiveButtonProps: {
            text: 'Add',
            disabled: (state) =>
              flagType === 'general' ? !state?.key || !state?.value
              : flagType === 'subscription' ?
                !state?.subscriptionTypeKey || !(state?.uid || state?.groupId)
              : !state?.uid,
            onClicked: (state) => handleAddSetting({ flagType, setting: state }),
          },
          negativeButtonProps: {
            text: 'Cancel',
            onClicked: () => true,
          },
          children: (_, state, setState) => AddSettingDialogBody(flagType, setState),
        },
        {
          key: '',
          value: undefined,
          uid: '',
          groupId: '',
          subscriptionTypeKey: '',
          admin: false,
        },
      )
    },
    [handleAddSetting],
  )

  const handleDeleteSetting = useCallback(
    async ({ flagType, setting, listType }: DeleteSettingProps) => {
      if (!flagRef || !setting?.key || (flagType !== 'general' && !listType)) {
        return false
      }

      switch (flagType) {
        case 'general':
          await flagRef.childFromKey('general').childFromKey(setting.key).update(null)
          return true
        case 'staff':
          await flagRef
            .childFromKey('users')
            .childFromKey(setting.key)
            .update({ staff: null, admin: null })
          return true
        case 'stats_pro':
          await flagRef
            .childFromKey('users')
            .childFromKey(setting.key)
            .update({ staff: null, admin: null })
          return true
        case 'subscription':
          if (!listType) return false
          await flagRef
            .childFromKey(listType)
            .childFromKey(setting.childrenKey || setting.key)
            .update({
              [setting.childrenKey || setting.key]: null,
            })
          return true
        default:
          return false
      }
    },
    [flagRef],
  )

  const showDeleteSettingDialog = useCallback(
    ({ flagType, setting, listType }: DeleteSettingProps) => {
      return showDialog<SettingValueType>({
        title: 'Delete',
        positiveButtonProps: {
          text: 'Delete',
          disabled: (_) => false,
          onClicked: (_) => handleDeleteSetting({ flagType, setting, listType }),
        },
        negativeButtonProps: {
          text: 'Cancel',
          onClicked: () => {
            return true
          },
        },
        children: (Red) => (
          <>
            Are you sure you want to remove <Red>{setting?.childrenKey || setting?.key}</Red>?
          </>
        ),
      })
    },
    [handleDeleteSetting],
  )

  return {
    generalDictionary: handleGetValue(generalDictionary),
    usersDictionary: handleGetValue(usersDictionary),
    groupsDictionary: handleGetValue(groupsDictionary),
    showAddSettingDialog,
    showDeleteSettingDialog,
  }
}

function AddSettingDialogBody(
  flagType: FLAG_TYPE,
  setState: React.Dispatch<React.SetStateAction<SettingValueType>>,
) {
  const [selectedRole, setSelectedRole] = useState('staff')

  const handleInputChange = useCallback(
    (field: keyof SettingValueType) => (value: string) =>
      setState((state) => ({ ...state, [field]: value.trim() })),
    [setState],
  )

  return (
    <div className='flex flex-col gap-8'>
      {flagType === 'general' && (
        <>
          <EditableStringDiv
            placeholder={'Key'}
            onChange={(value) => setState((state) => ({ ...state, key: value.trim() }))}
          />
          <EditableStringDiv
            placeholder={'Value'}
            onChange={(value) => setState((state) => ({ ...state, value: value.trim() }))}
          />
        </>
      )}

      {flagType !== 'general' && (
        <>
          <EditableStringDiv
            placeholder={'Nickname'}
            onChange={handleInputChange('nickname')}
          />
          <EditableStringDiv
            placeholder={'UID'}
            onChange={handleInputChange('uid')}
          />
          {flagType === 'staff' && (
            <div className='flex gap-4'>
              {['staff', 'admin'].map((it) => (
                <label key={it}>
                  <input
                    type='radio'
                    value={it}
                    checked={selectedRole === it}
                    onChange={(e) => {
                      setSelectedRole(e.target.value)
                      setState((state) => ({ ...state, admin: e.target.value === 'admin' }))
                    }}
                  />
                  {normaliseName(it)}
                </label>
              ))}
            </div>
          )}
          {flagType === 'subscription' && (
            <>
              <EditableStringDiv
                placeholder={'Group ID'}
                onChange={handleInputChange('groupId')}
              />
              <EditableStringDiv
                placeholder={'Subscription type key'}
                onChange={handleInputChange('subscriptionTypeKey')}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export function useFlagsStore<T extends 'users' | 'groups'>(
  firebaseDb: FirebaseDb,
  flagType: T,
  id: string | undefined,
) {
  return useDatabasePathLiveValue<FirebaseFlagEntry[T]>(
    firebaseDb,
    flagType && id && `flags/${flagType}/${id}`,
  )
}
