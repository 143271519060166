import React, { CSSProperties, PropsWithChildren, ReactNode, createRef } from 'react'
import { BackgroundIcon } from './BackgroundIcon'
import { SquashingBox } from './SquashingBox'
import { cn } from './utils/tailwindUtils'

type FlatRoundButtonProps = PropsWithChildren & {
  className?: string
  displayStyle?: 'large' | 'small'
  squashedChildrenFallback?: ReactNode[]
  backgroundColor?: CSSProperties['backgroundColor']
  color?: CSSProperties['color']
  padding?: CSSProperties['padding']
  style?: CSSProperties
  onClick?: (e: React.MouseEvent) => unknown | Promise<unknown>
  translate?: 'no' | 'yes'
  tabIndex?: number
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  ['aria-disabled']?: boolean
  iconVerticalPadding?: string
  iconInvert?: boolean
} & (
    | {
        icon: string | ReactNode
        alt: string
      }
    | {
        icon?: undefined
        alt?: string
      }
  )

export class RoundButton extends React.Component<FlatRoundButtonProps> {
  ref = createRef<SquashingBox>()
  state: Readonly<{ disabledOverride: boolean }> = { disabledOverride: false }
  hasFocus = () => {
    return document.activeElement === this.ref.current
  }

  blur = () => {
    return this.ref.current?.blur()
  }
  focus = () => {
    return this.ref.current?.focus()
  }
  contentRef = createRef<HTMLDivElement>()

  private handleOnClick = async (e: React.MouseEvent) => {
    if (this.props.onClick) {
      const result = this.props.onClick(e)
      if (result instanceof Promise) {
        this.setState({ disabledOverride: true })
        try {
          await result
        } finally {
          this.setState({ disabledOverride: false })
        }
      }
    }
    if (this.props.children && this.hasFocus()) {
      this.ref.current?.blur()
    }
  }

  render() {
    const {
      icon,
      alt,
      displayStyle = 'large',
      iconInvert,
      squashedChildrenFallback,
      backgroundColor,
      color,
      padding,
      'aria-disabled': ariaDisabled,
      className,
      onBlur,
      onFocus,
      onClick,
      style,
      translate,
      tabIndex,
      children,
    } = this.props

    const mapChildren = (child: ReactNode): ReactNode => {
      return (
        <div
          className={`relative flex h-[1.5em] max-h-full items-center transition-all
            ${displayStyle !== 'small' ? 'gap-[5px]' : undefined} `}>
          {icon && typeof icon === 'string' && (
            <BackgroundIcon
              draggable={false}
              src={icon}
              style={{
                filter: iconInvert ? `invert()` : undefined,
                paddingTop: this.props.iconVerticalPadding,
                paddingBottom: this.props.iconVerticalPadding,
              }}
              className={`h-[1.5em] max-h-full w-[25px] object-contain
              round-button-icon${displayStyle === 'large' ? '' : 'small'}`}
              alt={alt}
            />
          )}
          {icon && typeof icon !== 'string' && icon}
          {child}
        </div>
      )
    }

    return (
      <button
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={this.handleOnClick}
        tabIndex={tabIndex}
        aria-disabled={this.state.disabledOverride || ariaDisabled}
        translate={translate}
        className={cn(
          `pointer-events-auto flex h-[40px] cursor-pointer items-center justify-center gap-[4px]
          whitespace-nowrap rounded-full border-0 bg-white p-[8px] text-sm text-zinc-900 transition-all
          aria-disabled:pointer-events-none aria-disabled:cursor-default aria-disabled:opacity-30
          hover:contrast-50 hover:filter disabled:pointer-events-none disabled:cursor-default
          disabled:opacity-30`,
          className,
        )}
        style={{
          fontVariationSettings: "'wght' 500",
          backgroundColor: backgroundColor,
          color: color,
          padding: padding ?? (displayStyle === 'small' ? '8px 10px' : undefined),
          ...style,
        }}>
        <SquashingBox
          ref={this.ref}
          className='font-league-spartan'
          squashedChildrenArray={squashedChildrenFallback?.map(mapChildren)}>
          {mapChildren(children)}
        </SquashingBox>
      </button>
    )
  }
}
