import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@chakra-ui/icons'
import { FirebaseBaseLeaderboardEntry, NswdlDivFilter, RoundInfo } from 'data/leaderboardtypes'
import { User } from 'firebase/auth'
import { Suspense, lazy, useMemo, useRef, useState } from 'react'
import { ThreeDots } from 'react-loading-icons'
import { PlayerLeaderboardStatProfile } from './PlayerLeaderboardStatProfile'
import { Measure } from './RankingsByDiv'
import { MeasureDividersTitle, RankedPlayerMeasureResults } from './RankingsTable'
import { SnappingThreeItemCarousel } from './SnappingThreeItemCarousel'
import { compositeRef } from './UseCompositeRefObject'
import { FirebaseComponents, FirebaseDbReference } from './common/Firebase'
import { RoundButton } from './common/RoundButton'
import { useElementBoundingRectSize } from './common/utils/reactUtils'
import { cn } from './common/utils/tailwindUtils'

const StatsProfileShareButton = lazy(() => import('./PlayerStatsProfileShareButton'))

export default function PlayerStatProfileCarousel({
  MAX_RANKING,
  firebase,
  user,
  groupId,
  leaderboardTitle,
  selectedDivision,
  selectedPlayer,
  selectedMeasure,
  onClose,
  rankingsByMeasure,
  leaderboardRef,
  isGroupMember,
  onMeasureSelected,
  onPlayerSelected,
  onDivisionSelected,
  selectedRound,
  includeForFun,
}: {
  onClose: () => void
  selectedPlayer: string
  leaderboardTitle: string | undefined
  firebase: FirebaseComponents
  user: User | undefined
  groupId: string
  selectedDivision: NswdlDivFilter
  selectedMeasure: Measure
  selectedRound: RoundInfo | undefined
  rankingsByMeasure: Map<Measure, RankedPlayerMeasureResults[]>
  MAX_RANKING: number
  leaderboardRef: FirebaseDbReference<FirebaseBaseLeaderboardEntry>
  isGroupMember?: boolean
  includeForFun?: boolean
  onMeasureSelected: (measure: Measure) => void
  onDivisionSelected: (division: NswdlDivFilter) => void
  onPlayerSelected?: (player: string) => void
}) {
  const previousPlayerRanking = useMemo(() => {
    const index = rankingsByMeasure
      .get(selectedMeasure)
      ?.findIndex((it) => it.player === selectedPlayer)
    if (index === undefined || index === -1) return undefined
    return rankingsByMeasure.get(selectedMeasure)?.[index - 1]
  }, [rankingsByMeasure, selectedMeasure, selectedPlayer])

  const nextPlayerRanking = useMemo(() => {
    const index = rankingsByMeasure
      .get(selectedMeasure)
      ?.findIndex((it) => it.player === selectedPlayer)

    if ((index === undefined || index === -1) && !previousPlayerRanking)
      return rankingsByMeasure.get(selectedMeasure)?.firstOrNull()

    if (index === undefined || index === -1) return undefined
    return rankingsByMeasure.get(selectedMeasure)?.[index + 1]
  }, [rankingsByMeasure, selectedMeasure, selectedPlayer, previousPlayerRanking])

  const playerProfileRef = useRef<HTMLDivElement>(null)
  const playerProfileThumbnailRef = useRef<HTMLDivElement>(null)
  const [animationKey, setAnimationKey] = useState(0)

  const [statprofilecontainerref, size] = useElementBoundingRectSize()

  const aspectRatio = 360 / 600
  const zoom = useMemo(() => {
    const { width, height: containerHeight } = size
    // Implement visible vertical scrolling and increase the minimum zoom level
    const zoom = Math.min(1.0, Math.max(0.3, width / 360))

    const height = width / aspectRatio

    if (zoom * height * 0.8 > containerHeight) {
      return Math.min(1.0, Math.max(0.3, containerHeight / (height * 0.8)))
    }

    return zoom
  }, [aspectRatio, size])
  return (
    <div
      className='fixed left-0 top-0 z-10 flex h-svh w-svw flex-col items-center justify-center gap-4 bg-black/80
        shadow-2xl backdrop-blur-sm'>
      <MeasureDividersTitle
        className='mt-4 h-fit'
        measure={selectedMeasure}
        selectedDivision={selectedDivision}
        selectedRound={selectedRound}
      />
      <div className='mb-20 flex min-h-0 w-full flex-1 flex-col items-center justify-stretch gap-2'>
        <div className='flex w-full flex-row justify-center gap-4'>
          <RoundButton
            className='aspect-square'
            onClick={onClose}
            icon={<CloseIcon />}
            alt='close player profile'
          />
          <Suspense fallback={<ThreeDots />}>
            <StatsProfileShareButton
              firebase={firebase.firebaseDb}
              groupId={groupId}
              animationRequest={() => setAnimationKey((key) => key + 1)}
              leaderboardTitle={leaderboardTitle}
              playerProfileRef={playerProfileRef}
              playerProfileThumbnailRef={playerProfileThumbnailRef}
              selectedPlayer={selectedPlayer}
            />
          </Suspense>
        </div>
        <div className='flex max-h-[600px] min-h-0 max-w-[100svw] flex-1 flex-row items-end gap-4'>
          <RoundButton
            className='z-10 aspect-square w-fit'
            onClick={() =>
              previousPlayerRanking && onPlayerSelected?.(previousPlayerRanking.player)
            }
            aria-disabled={!previousPlayerRanking}
            icon={
              <ChevronLeftIcon
                w={30}
                h={30}
              />
            }
            alt='close player profile'
          />
          <div
            className='relative box-content h-full max-h-full min-h-0 w-svw min-w-0 max-w-[360px] flex-1'
            ref={statprofilecontainerref}>
            <SnappingThreeItemCarousel<string>
              onSwipe={(direction) => {
                if (direction === 'next' && nextPlayerRanking) {
                  onPlayerSelected?.(nextPlayerRanking.player)
                } else if (direction === 'previous' && previousPlayerRanking) {
                  onPlayerSelected?.(previousPlayerRanking.player)
                }
              }}
              currentItem={selectedPlayer}
              nextItem={nextPlayerRanking?.player}
              previousItem={previousPlayerRanking?.player}
              renderPreview={(selectedPlayer, ref) => (
                <PlayerLeaderboardStatProfile
                  // animationKey={animationKey}
                  ref={ref}
                  className={cn('h-[600px] min-h-[600px] w-[360px] max-w-[360px]')}
                  firebase={firebase}
                  user={user}
                  groupId={groupId}
                  includeForFun={includeForFun}
                  leaderboardRef={leaderboardRef}
                  isMemberOfGroup={isGroupMember}
                  playerName={'loading...'}
                  maxRanking={MAX_RANKING}
                  zoom={zoom}
                />
              )}
              render={(selectedPlayer, ref) => {
                return (
                  <PlayerLeaderboardStatProfile
                    ref={compositeRef<HTMLElement, HTMLDivElement>(playerProfileRef, ref)}
                    key={selectedPlayer}
                    animationKey={animationKey}
                    className={cn('h-[600px] min-h-[600px] w-[360px] max-w-[360px]')}
                    firebase={firebase}
                    user={user}
                    groupId={groupId}
                    selectedDivision={selectedDivision}
                    selectedMeasure={selectedMeasure}
                    includeForFun={includeForFun}
                    leaderboardRef={leaderboardRef}
                    isMemberOfGroup={isGroupMember}
                    playerName={selectedPlayer}
                    onMeasureSelected={onMeasureSelected}
                    onDivisionSelected={onDivisionSelected}
                    measureRankings={rankingsByMeasure}
                    maxRanking={MAX_RANKING}
                    zoom={zoom}
                  />
                )
              }}
            />
          </div>
          <div className='pointer-events-none absolute opacity-0'>
            <PlayerLeaderboardStatProfile
              ref={playerProfileThumbnailRef}
              // key={selectedPlayer}
              animationKey={animationKey}
              className={cn('pointer-events-none h-[350px] max-h-fit min-h-[350px] w-full')}
              firebase={firebase}
              user={user}
              groupId={groupId}
              selectedDivision={selectedDivision}
              selectedMeasure={selectedMeasure}
              includeForFun={includeForFun}
              leaderboardRef={leaderboardRef}
              isMemberOfGroup={isGroupMember}
              playerName={selectedPlayer}
              measureRankings={rankingsByMeasure}
              maxRanking={MAX_RANKING}
              onlySpotlight={true}
              zoom={zoom}
            />
          </div>

          <RoundButton
            className='z-10 aspect-square w-fit'
            onClick={() => nextPlayerRanking && onPlayerSelected?.(nextPlayerRanking.player)}
            aria-disabled={!nextPlayerRanking}
            icon={
              <ChevronRightIcon
                w={30}
                h={30}
              />
            }
            alt='close player profile'
          />
        </div>
        <div
          className='pointer-events-none relative flex w-svw max-w-[500px] select-none flex-row items-center
            justify-between gap-4 px-4'>
          <div className='pointer-events-none flex flex-row items-center gap-4 rounded-full bg-black/60 p-2'>
            <span className='pointer-events-none select-none text-nowrap font-montserrat text-sm leading-4 font-semibold'>
              {previousPlayerRanking && (
                <>
                  <span>{previousPlayerRanking.player}</span>
                  {previousPlayerRanking.displayRank <= 20 && (
                    <> 🏆 {previousPlayerRanking.displayRank}</>
                  )}
                </>
              )}
            </span>
          </div>
          <div className='pointer-events-none flex flex-row items-center gap-4 rounded-full bg-black/60 p-2'>
            {nextPlayerRanking && (
              <span className='pointer-events-none select-none text-nowrap font-montserrat text-sm leading-4 font-semibold'>
                {nextPlayerRanking.displayRank <= 20 && <>{nextPlayerRanking.displayRank} 🏆 </>}
                <span className=''>{nextPlayerRanking.player}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
