import {
  EventDimension,
  EventDimensionFromTypedDefinition,
  TypedEventDefinition,
  VariantKeyByDimension,
  VariantKeyByDimensionFilter,
} from 'templates/MetaEvents'
import { EventDefinition } from 'templates/TemplateConfig'

export const ANY = 'any'
export const DEFINED = 'defined'

export class Definitions<T extends TypedEventDefinition<string, EventDimension<string, string>>> {
  typedDefinitionsMap: Map<T['key'], T>
  definitionsMap: Map<EventDefinition['key'], EventDefinition>

  constructor(definitions: T[]) {
    this.definitionsMap = new Map(
      definitions.map(({ descriptor, ...it }) => [it.key, { ...it, type: 'EventDefinition' }]),
    )
    this.typedDefinitionsMap = new Map(definitions.map((it) => [it.key, it]))
  }

  addDefinitions<K extends TypedEventDefinition<string, EventDimension<string, string>>>(
    newDefinitions: K[],
  ) {
    newDefinitions.forEach(({ descriptor, ...it }) => {
      this.definitionsMap.set(
        it.key,
        Object.assign(this.definitionsMap.get(it.key) ?? {}, {
          ...it,
          type: 'EventDefinition',
        } satisfies EventDefinition),
      )
      this.typedDefinitionsMap.set(
        it.key,
        Object.assign(this.typedDefinitionsMap.get(it.key) ?? {}, it) as unknown as T,
      )
    })
  }

  get = (key: T['key']) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.definitionsMap.get(key)!
  }
  getAll = (...keys: (T['key'] | undefined)[]) => {
    return keys.map((it) => (it ? this.get(it) : undefined))
  }
  getTyped = (key: T['key']) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.typedDefinitionsMap.get(key)!
  }

  getByDimensions = <
    E extends EventDimension<string, string> = EventDimensionFromTypedDefinition<T>,
    THISKEY extends string = T['key'],
  >(
    dimension: VariantKeyByDimension<E>,
  ) => {
    return this.asTypedArray().find((it) => {
      return (
        !dimension ||
        Object.entries(dimension).all(([dimension, variant]) => {
          const castedDimension = dimension as E[0]
          return it.descriptor[castedDimension] === variant
        })
      )
    }) as T | undefined
  }
  findContains = <E extends EventDimensionFromTypedDefinition<T>, THISKEY extends T['key']>({
    key,
    dimension,
    exclude,
  }: {
    key?: THISKEY
    dimension?: VariantKeyByDimensionFilter<E>
    exclude?: THISKEY
  }): TypedEventDefinition<THISKEY, E>[] => {
    return this.asTypedArray().filter((it) => {
      return (
        (!key || it.key.includes(key)) &&
        (!exclude || it.key !== exclude) &&
        (!dimension ||
          Object.entries(dimension).all(([dimension, variant]) => {
            const castedDimension = dimension as E[0]
            return (
              it.descriptor[castedDimension] !== undefined &&
              (variant === ANY ||
                (variant === DEFINED && it.descriptor[castedDimension] !== null) ||
                it.descriptor[castedDimension] === variant)
            )
          }))
      )
    }) as unknown as TypedEventDefinition<THISKEY, E>[]
  }
  mapAsObject = <
    E extends EventDimensionFromTypedDefinition<T>,
    THISKEY extends T['key'],
    NV,
    NK extends string,
  >(
    filter: {
      key?: THISKEY
      dimension?: VariantKeyByDimensionFilter<E>
      exclude?: THISKEY
    },
    mapper: (key: THISKEY, def: TypedEventDefinition<THISKEY, E>) => [NK, NV],
  ): Record<NK, NV> => {
    return this.findContains(filter).reducePartial<Record<NK, NV>>(
      (previousValue, currentValue) => {
        const [nk, nv] = mapper(currentValue.key, currentValue)
        previousValue[nk] = nv
        return previousValue
      },
    )
  }
  filterMap = <K>(
    filter: VariantKeyByDimensionFilter<EventDimensionFromTypedDefinition<T>>,
    mapper: (
      key: T['key'],
      def: EventDefinition,
      typedDef: TypedEventDefinition<T['key'], EventDimensionFromTypedDefinition<T>>,
      index: number,
    ) => K,
  ): K[] => {
    return this.findContains<EventDimensionFromTypedDefinition<T>, T['key']>({
      dimension: filter,
    }).map((it, index) => {
      return mapper(it.key, this.get(it.key), it, index)
    })
  }
  filter = <K>(
    filter: VariantKeyByDimensionFilter<EventDimensionFromTypedDefinition<T>>,
  ): EventDefinition[] => {
    return this.filterTyped(filter).map((it) => this.get(it.key))
  }
  filterTyped = <K>(
    filter: VariantKeyByDimensionFilter<EventDimensionFromTypedDefinition<T>>,
  ): TypedEventDefinition<T['key'], EventDimensionFromTypedDefinition<T>>[] => {
    return this.findContains<EventDimensionFromTypedDefinition<T>, T['key']>({
      dimension: filter,
    }).map((it, index) => {
      return it
    })
  }
  getOrNull = (key: string) => {
    return this.definitionsMap.get(key)
  }
  has = (key: string) => {
    return this.definitionsMap.has(key)
  }
  findByDimension = <D extends T['descriptor']>(dimension: D): T[] => {
    return this.typedDefinitionsMap.valuesArray().filter((it) => {
      return Object.entries(dimension)
        .filter(([dimension, variantKey]) => variantKey !== undefined)
        .all(([dimension, variantKey]) => {
          return (
            variantKey !== undefined ||
            (it.descriptor as { [dimension: string]: string | undefined })[dimension] === variantKey
          )
        })
    })
  }
  asMap = (...def: EventDefinition[]) => {
    const result = new Map(this.definitionsMap as Map<EventDefinition['key'], EventDefinition>)
    def.forEach((it) => result.set(it.key, it))
    return result
  }
  asArray = (): EventDefinition[] => {
    return this.definitionsMap.valuesArray()
  }
  asTypedArray = (): T[] => {
    return this.typedDefinitionsMap.valuesArray()
  }
}
