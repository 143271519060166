import { Flags } from 'FLAGS'
import { useGroupDetailsStore } from 'UseGroupSelectionStore'
import { UnreachableError } from 'components/common/utils/error'
import {
  FLAGS_CONFIG,
  FLAG_DEFAULTS,
  ProFeatureFlagsDefinition,
  SubscriptionContext,
} from 'data/ProFeatureFlagConfig'
import { FirebaseGroupDetails, FirebaseGroupEntry, ReviewFirebaseEntry } from 'data/common'
import { Subscription } from 'data/stripe/StripeSubscription'
import { User } from 'firebase/auth'
import { where } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useFlagsStore } from 'ui/AdminManagementDialog'
import {
  FirebaseComponents,
  useDatabasePathLiveValue,
  useFirebase,
  useFirestoreNullableQueryLiveValue,
} from '../components/common/Firebase'
import { FirebaseFlagEntry } from 'components/common/hooks/useFeatureFlags'

export type FlagProvider<P extends ViewContext, T> = (
  props: P,
) => Promise<T | undefined> | T | undefined

export type SubscriptionConfig = {
  [key in SubscriptionType]: (
    context: Extract<SubscriptionContext, { type: key }>,
  ) => ProFeatureFlagsDefinition
}

export type BaseProFeatureFlagsDefinition = {
  [id: string]:
    | GroupBasedFlagDefinition<any>
    | ReviewBasedFlagDefinition<any>
    | GeneralFlagDefinition<any>
}

export type ProFeatureFlags = {
  [key in keyof Required<ProFeatureFlagsDefinition>]:
    | Awaited<ReturnType<NonNullable<ProFeatureFlagsDefinition[key]>['provider']>>
    | undefined
}

export type BaseSubscriptionContext = { type: string }
export type SubscriptionType = SubscriptionContext['type']

type UseProFeaturesStoreReturn = {
  featureFlags: ProFeatureFlags
  subscriptionSources: SubscriptionContext[]
  refresh: () => void
}

const mergeFeatureFlags = (
  baseFlags: ProFeatureFlags,
  additionalFlags: ProFeatureFlags,
): ProFeatureFlags => {
  const mergedFlags: Required<ProFeatureFlags> = { ...baseFlags }
  for (const key in additionalFlags) {
    const castedKey = key as keyof ProFeatureFlags
    if (additionalFlags[castedKey] !== undefined) {
      const mergedFlagsValue = mergedFlags[castedKey]
      const additionalFlagsValue = additionalFlags[castedKey]
      Object.assign(mergedFlags, {
        [castedKey]:
          typeof mergedFlagsValue === 'number' && typeof additionalFlagsValue === 'number' ?
            Math.max(mergedFlagsValue, additionalFlagsValue)
          : additionalFlagsValue || mergedFlagsValue,
      })
    }
  }
  return mergedFlags
}

export const useProFeaturesStore = ({
  user,
  groupId,
  reviewId,
}: {
  user?: User | undefined | null
  // the group dashboard or leaderboard the user is viewing
  // NOTE: NOT!! the group that the review belongs to since this can be obtained using the reviewId
  groupId?: string | undefined
  // the review the user is viewing
  reviewId?: string | undefined
}): UseProFeaturesStoreReturn => {
  const firebase = useFirebase(firebaseConfig)
  const [featureFlags, setFeatureFlags] = useState<ProFeatureFlags>({ ...FLAG_DEFAULTS })
  const [subscriptionContexts, setSubscriptionContexts] = useState<SubscriptionContext[]>([])
  const groupDetail = useGroupDetailsStore(firebase.firebaseDb, groupId)
  const userFlags = useFlagsStore(firebase.firebaseDb, 'users', user?.uid)
  const groupFlags = useFlagsStore(firebase.firebaseDb, 'groups', groupId)

  const groupOwner = useDatabasePathLiveValue<FirebaseGroupEntry['owner']>(
    firebase.firebaseDb,
    groupId ? `groups/${groupId}/owner` : undefined,
  )

  const groupMembers = useDatabasePathLiveValue<FirebaseGroupEntry['editingUsers']>(
    firebase.firebaseDb,
    groupId ? `groups/${groupId}/editingUsers` : undefined,
  )

  const reviewGroupIds = useDatabasePathLiveValue<ReviewFirebaseEntry['groups']>(
    firebase.firebaseDb,
    reviewId ? `reviews/${reviewId}/groups` : undefined,
  )

  const reviewOwner = useDatabasePathLiveValue<ReviewFirebaseEntry['owner']>(
    firebase.firebaseDb,
    reviewId ? `reviews/${reviewId}/owner` : undefined,
  )

  const reviewEditingUsers = useDatabasePathLiveValue<ReviewFirebaseEntry['editingUsers']>(
    firebase.firebaseDb,
    reviewId ? `reviews/${reviewId}/editingUsers` : undefined,
  )

  const userGroups = useDatabasePathLiveValue<{ [groupId: string]: boolean }>(
    firebase.firebaseDb,
    user ? `users/${user.uid}/groups` : undefined,
  )

  const userSubscriptions = useFirestoreNullableQueryLiveValue<Subscription>(() => {
    if (!groupOwner) return undefined
    return firebase.firestoreDb
      .collection('users')
      .doc(groupOwner)
      .collection('subscriptions')
      .query(where('status', 'in', ['active']))
  }, [groupOwner, firebase.firestoreDb])

  const createFlagContextFactory = useMemo(() => {
    return subscriptionContextToFlagContextFactory({
      user: user,
      groupId: groupId,
      reviewId: reviewId,
      reviewAccess: { owner: reviewOwner, editors: reviewEditingUsers },
      groupAccess: { owner: groupOwner ?? null, members: groupMembers ?? {} },
      userGroups: userGroups,
    })
  }, [
    groupId,
    reviewId,
    userGroups,
    reviewOwner,
    reviewEditingUsers,
    groupOwner,
    groupMembers,
    user,
  ])

  const updateFeatureFlags = useCallback(async () => {
    const mergedFlags = await renderCombinedFlags(subscriptionContexts, createFlagContextFactory)
    setFeatureFlags(mergedFlags)
  }, [createFlagContextFactory, subscriptionContexts])

  useEffect(() => {
    updateFeatureFlags()
  }, [updateFeatureFlags])

  const refresh = useCallback(async () => {
    // Re-fetch or recheck the subscription sources and update the state
    // Implement your logic here to recheck the sources
    // Example placeholder:
    const newSubscriptionSources = await fetchSubscriptionSources(
      firebase,
      user,
      groupId,
      groupDetail,
      Array.isArray(userSubscriptions) ? userSubscriptions : [],
      userFlags,
      groupFlags,
    )
    setSubscriptionContexts(newSubscriptionSources)
  }, [firebase, groupDetail, groupFlags, groupId, user, userFlags, userSubscriptions])

  useEffect(() => {
    refresh()
  }, [groupDetail, refresh, userSubscriptions])

  // TODO(chien): Add "check if a group has pro-features enabled"

  return { featureFlags, subscriptionSources: subscriptionContexts, refresh }
}

function getSubscriptionByPlanType(
  userSubscriptions: Subscription[],
  groupId: string | undefined,
  planType: string,
) {
  return userSubscriptions.find(
    (s) => groupId && s.metadata.groupId === groupId && s.metadata.plan_type === planType,
  )
}

// Example function to fetch subscription sources
const fetchSubscriptionSources = async (
  firebase: FirebaseComponents,
  user: User | undefined | null,
  groupId: string | undefined,
  groupDetail: FirebaseGroupDetails | undefined,
  userSubscriptions: Subscription[],
  userFlags: FirebaseFlagEntry['users'],
  groupFlags: FirebaseFlagEntry['groups'],
): Promise<SubscriptionContext[]> => {
  // TODO(Davey): CHECK SUBSCRIPTION SOURCES
  const teamSubscriptionPlan = getSubscriptionByPlanType(userSubscriptions, groupId, 'team')
  const leagueSubscriptionPlan = getSubscriptionByPlanType(userSubscriptions, groupId, 'league')
  return (
    [
      user && (Flags.STAFF.includes(user.uid) || userFlags?.staff) ?
        {
          type: 'staff',
          userId: user.uid,
        }
      : undefined,
      (
        user &&
        (userSubscriptions.length || Flags.PRO_TRIAL.includes(user.uid) || userFlags?.stats_pro)
      ) ?
        {
          type: 'statsPro',
          userId: user.uid,
        }
      : undefined,
      groupId && teamSubscriptionPlan ?
        {
          type: 'teamPro',
          groupId: groupId,
          interval: teamSubscriptionPlan.metadata?.interval,
          // TODO: Check subscription for number of members
          seats: teamSubscriptionPlan.quantity ?? 10,
        }
      : undefined,
      (
        groupId &&
        (leagueSubscriptionPlan || Flags.LEAGUE_PRO.includes(groupId) || groupFlags?.league_pro)
      ) ?
        {
          type: 'leaguePro',
          groupId: groupId,
          interval: leagueSubscriptionPlan?.metadata?.interval ?? null,
          // TODO: Check subscription for number of members
          seats: leagueSubscriptionPlan?.quantity ?? 10,
        }
      : undefined,
    ] satisfies (SubscriptionContext | undefined)[]
  ).filterNotNull()
}

export async function renderCombinedFlags(
  subscriptionContexts: SubscriptionContext[],
  flagContext: ViewContext,
  subscriptionConfig: SubscriptionConfig = FLAGS_CONFIG,
  flagDefaults = FLAG_DEFAULTS,
) {
  let mergedFlags: ProFeatureFlags = { ...flagDefaults }
  await subscriptionContexts.mapAwait(async (subscriptionContext) => {
    mergedFlags = mergeFeatureFlags(
      mergedFlags,
      await renderFlags(subscriptionContext, flagContext, subscriptionConfig),
    )
  })

  return mergedFlags
}

export async function renderFlags(
  subscriptionContext: SubscriptionContext,
  flagContext: ViewContext,
  config: SubscriptionConfig = FLAGS_CONFIG,
): Promise<ProFeatureFlags> {
  const provider = config[subscriptionContext.type](subscriptionContext as any)
  const mapped = (
    await Object.entries(provider).mapAwait(async ([key, value]) => {
      const castedKey = key as keyof ProFeatureFlagsDefinition
      const castedValue =
        value as BaseProFeatureFlagsDefinition[keyof BaseProFeatureFlagsDefinition]
      switch (castedValue.type) {
        case 'group':
          return flagContext.groupInView ?
              ([
                castedKey,
                await castedValue.provider({
                  ...flagContext,
                  groupInView: flagContext.groupInView,
                }),
              ] as [keyof ProFeatureFlags, ProFeatureFlags[keyof ProFeatureFlags]])
            : undefined
        case 'review':
          return flagContext.reviewInView ?
              ([
                castedKey,
                await castedValue.provider({
                  ...flagContext,
                  reviewInView: flagContext.reviewInView,
                }),
              ] as [keyof ProFeatureFlags, ProFeatureFlags[keyof ProFeatureFlags]])
            : undefined
        case 'general':
          return flagContext.reviewInView ?
              ([castedKey, await castedValue.provider(flagContext)] as [
                keyof ProFeatureFlags,
                ProFeatureFlags[keyof ProFeatureFlags],
              ])
            : undefined
        default:
          throw UnreachableError(castedValue)
      }
    })
  ).filterNotNull()

  return mapped.toObject() as ProFeatureFlags
}

const subscriptionContextToFlagContextFactory = ({
  user,
  groupId,
  reviewId,
  groupAccess,
  reviewAccess,
  userGroups,
}: {
  user: User | undefined | null
  groupId: string | undefined
  reviewId: string | undefined
  groupAccess?: {
    members: FirebaseGroupEntry['editingUsers']
    owner: FirebaseGroupEntry['owner']
  }
  reviewAccess?: {
    editors: ReviewFirebaseEntry['editingUsers']
    owner: ReviewFirebaseEntry['owner']
  }
  userGroups?: {
    [groupId: string]: boolean
  }
}): ViewContext => {
  return {
    groupInView:
      groupId ?
        {
          groupId,
          isUserInGroup:
            !!user && (groupAccess?.owner === user.uid || groupAccess?.members[user.uid] === true),
        }
      : undefined,
    reviewInView:
      reviewId ?
        {
          reviewId,
          isUserAnEditor:
            !!user &&
            (reviewAccess?.owner === user.uid || reviewAccess?.editors?.[user.uid] === true),
        }
      : undefined,
    user:
      user ?
        {
          userId: user.uid,
          groupIds: userGroups,
        }
      : undefined,
  }
}

// Information about where the user is trying to access the pro feature
export type ViewContext = {
  groupInView?: {
    // the group dashboard/private leaderboard the user is viewing
    groupId: string
    // whether the group is the one being checked
    isUserInGroup: boolean
  }
  reviewInView?: {
    // the review the user is viewing
    reviewId: string
    // the group the review belongs to
    groupIds?: { [groupId: string]: boolean | undefined }
    // whether the review belongs to the paid group being checked
    isUserAnEditor: boolean
  }
  user?: {
    userId: string
    // the groups the user is a member of
    groupIds?: { [groupId: string]: boolean | undefined }
  }
}

type GroupViewContext = ViewContext & Required<Pick<ViewContext, 'groupInView'>>
type ReviewViewContext = ViewContext & Required<Pick<ViewContext, 'reviewInView'>>

export type GroupBasedFlagDefinition<T> = {
  type: 'group'
  provider: FlagProvider<GroupViewContext, T>
}
export type ReviewBasedFlagDefinition<T> = {
  type: 'review'
  provider: FlagProvider<ReviewViewContext, T>
}
export type GeneralFlagDefinition<T> = {
  type: 'general'
  provider: FlagProvider<ViewContext, T>
}

export function groupFlag<T>(p: FlagProvider<GroupViewContext, T>): GroupBasedFlagDefinition<T> {
  return { type: 'group', provider: p }
}
export function reviewFlag<T>(p: FlagProvider<ReviewViewContext, T>): ReviewBasedFlagDefinition<T> {
  return { type: 'review', provider: p }
}
export function generalFlag<T>(p: FlagProvider<ViewContext, T>): GeneralFlagDefinition<T> {
  return { type: 'general', provider: p }
}
