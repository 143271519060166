import { Box, Flex } from '@chakra-ui/react'
import { DocumentPermissions } from 'data/common'
import { User } from 'firebase/auth'
import { clamp } from 'framer-motion'
import * as React from 'react'
import { createRef, useMemo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Definitions } from 'templates/Definitions'
import {
  EventDefinition,
  MATCH_RESET_DEFINITION,
  NOTE_DEFINITION,
  POINT_DRAW_DEFINITION,
  POINT_LOSE_DEFINITION,
  POINT_WIN_DEFINITION,
  SKETCH_NOTE_DEFINITION,
} from 'templates/TemplateConfig'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import { Player } from '../data/Player'
import { TimelineEvent } from '../data/TimelineEvent'
import { ViewingMode } from '../data/ViewingMode'
import { isNoteDefinition } from '../data/common'
import { TeamStore } from '../hooks/UseTeamRepo'
import { PlayerTile } from '../ui/PlayerTile'
import { ReviewActivityType } from '../ui/ReviewMetaStore'
import { ButtonBarSelection } from '../ui/ShortcutButtonBar'
import { SelectedEventState, SetSelectedEvents } from '../ui/TimelineStore'
import { showCommentPanel } from '../ui/VerticalCommentsPanel'
import { CompositeMarker } from './CompositeMarker'
import { Rect, ShowPlayerChooser } from './PlayerChooser'
import { RoundStartPlayersPanel } from './RoundStartPlayersPanel'
import { TimelineMarker } from './TimelineMarker'
import { VideoController } from './VideoController'
import { cn } from './common/utils/tailwindUtils'
import { displayTime } from './common/utils/timeUtils'
import { PropType } from './common/utils/typescriptUtils'

export const LOCAL_USER = { uid: 'local-user', displayName: 'Review owner' }

export interface TimelineProps<T> {
  // pixels
  activityType: ReviewActivityType | undefined
  width: number
  duration: number
  showSignInToEditDialog: undefined | (() => void)
  user: User | undefined
  viewingMode: ViewingMode
  baseViewingMode: ViewingMode
  documentPermissions: DocumentPermissions | undefined
  showPlayerChooser: ShowPlayerChooser
  videoController: VideoController
  displaySizing: 'small' | 'medium' | 'large'
  displayStyle?: 'standard' | 'thick-simplified'
  events: TimelineEvent<T>[]
  eventsCreatedThisSession?: PropType<TimelineEvent<T>, 'id'>[]
  onMarkerClick?: (event: TimelineEvent<T>) => void
  onMarkerUpdate?: (event: TimelineEvent<T>) => void
  onMarkerDelete?: (event: TimelineEvent<T>) => void
  onEditSketchClicked?: (event: TimelineEvent<T>) => void
  onSeek?: (time: number, pause?: boolean) => Promise<void>
  onViewSketchClicked: ((event: TimelineEvent<T>) => void) | undefined
  onScrub?: (time: number) => Promise<void>
  selectedPlayers?: Player[]
  eventFilters?: EventDefinition[]
  teamFilters?: number[]
  reversePoints?: boolean
  onMarkerHovered?: (event: TimelineEvent<T> | undefined) => void
  multiTeamMode: boolean
  getMatchNumberAtTime: (time: number) => number
  selectedEvents: SelectedEventState[]
  setSelectedEvents: SetSelectedEvents
  teamStore: TeamStore
}

interface State {
  currentTime: number
}

interface BackgroundState<T> {
  whenOverriden: number | undefined
  latestSeekPromise?: Promise<void>
  currentTimeOverride?: number | undefined
  previewSelection?: {
    button?: ButtonBarSelection
    event: TimelineEvent<T>
  }
  scrollZoom: number
}

const EMPTYHANDLER = () => {
  // do nothing
}
class Timeline extends React.Component<TimelineProps<EventDefinition>, State> {
  state: State = {
    currentTime: 0,
  }

  pointerHandler: {
    handlePointerMove: (
      event: Pick<PointerEvent, 'buttons' | 'pageX' | 'pageY' | 'pointerType'>,
    ) => void
    handlePointerUp: (
      event: Pick<PointerEvent, 'buttons' | 'pageX' | 'pageY' | 'pointerType'>,
    ) => void
  } = {
    handlePointerMove: EMPTYHANDLER,
    handlePointerUp: EMPTYHANDLER,
  }

  backgroundState: BackgroundState<EventDefinition> = {
    whenOverriden: undefined,
    latestSeekPromise: undefined,
    currentTimeOverride: undefined,
    previewSelection: undefined,
    scrollZoom: clamp(1.0, this.getMaxZoom(), 5 / (this.props.width / this.props.duration)),
  }

  POINT_EVENTS = new Set(
    [POINT_WIN_DEFINITION]
      .concat(POINT_LOSE_DEFINITION)
      .concat(MATCH_RESET_DEFINITION)
      .concat(POINT_DRAW_DEFINITION)
      .mapProp('key'),
  )

  timer?: NodeJS.Timer

  getMaxZoom() {
    return 240 / (this.props.width / this.props.duration)
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      if (
        this.backgroundState.currentTimeOverride !== undefined &&
        this.state.currentTime !== undefined
      ) {
        if (
          Math.abs(this.state.currentTime - this.backgroundState.currentTimeOverride) < 0.2 ||
          (this.backgroundState.whenOverriden &&
            Date.now() - this.backgroundState.whenOverriden > 3000)
        ) {
          this.backgroundState.currentTimeOverride = undefined
          this.backgroundState.whenOverriden = undefined
          this.backgroundState.latestSeekPromise = undefined
        }
      }
      this.setState((state) => ({
        ...state,
        currentTime: this.props.videoController.currentStatus.currentTime,
      }))
    }, 1000 / 30)

    window.addEventListener('pointermove', (e) => this.pointerHandler.handlePointerMove(e))
    window.addEventListener('pointerup', (e) => this.pointerHandler.handlePointerUp(e))
  }

  initialPinchDistance: number | undefined
  initialPinchX: number | undefined
  handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length >= 2) {
      this.initialPinchDistance = this.getDistance(event.touches[0], event.touches[1])
      this.initialPinchX = this.getMiddle(event.touches[0], event.touches[1]).x
    }
  }
  getCurrentTime = () => {
    return this.state.currentTime
  }
  getPreviewTime = () => {
    return this.backgroundState.previewSelection?.event.time
  }
  setPreviewEvent = (selection: ButtonBarSelection | undefined, time = 0) => {
    this.backgroundState.previewSelection = selection && {
      button: selection,
      event: {
        ...selection.definition,
        id: 'preview',
        team: -1,
        modifiedDate: -1,
        createdDate: -1,
        createBy: { uid: ' ', displayName: ' ' },
        modifiedBy: [],
        seenBy: {},
        tag: selection.definition as EventDefinition,
        time,
        definition_key: selection.definition.key,
        source: 'local',
      } satisfies TimelineEvent<EventDefinition>,
    }
  }

  handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length >= 2) {
      const currentDistance = this.getDistance(event.touches[0], event.touches[1])
      const currentPosition = this.getMiddle(event.touches[0], event.touches[1])
      const deltaScale = currentDistance / (this.initialPinchDistance || 1)
      const deltaX = currentPosition.x - (this.initialPinchX || 0)
      this.initialPinchDistance = currentDistance
      this.initialPinchX = currentPosition.x
      this.handlePinch(deltaScale, deltaX)
    } else {
      this.pointerHandler.handlePointerMove({
        buttons: 0,
        pageX: event.touches[0].pageX,
        pageY: event.touches[0].pageY,
        pointerType: 'touch',
      })
    }
  }

  handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length === 0) {
      this.handlePinchEnd()
      this.initialPinchDistance = undefined
      this.initialPinchX = undefined

      this.pointerHandler.handlePointerUp({
        buttons: 0,
        pageX: event.changedTouches[0].pageX,
        pageY: event.changedTouches[0].pageY,
        pointerType: 'touch',
      })
    }
  }

  handlePinch(deltaScale: number, deltaX: number) {
    const backgroundState = this.backgroundState
    const timeDelta = deltaX * this.timePerPixel()
    const currentTimeOverride =
      backgroundState.currentTimeOverride === undefined ?
        this.state.currentTime
      : backgroundState.currentTimeOverride
    const newTime = currentTimeOverride - timeDelta
    this.backgroundState.scrollZoom = clamp(
      1.0,
      this.getMaxZoom(),
      backgroundState.scrollZoom * deltaScale,
    )
    this.scrub(newTime)
  }

  handlePinchEnd() {
    if (this.backgroundState.currentTimeOverride !== undefined) {
      this.seek(this.backgroundState.currentTimeOverride)
    }
  }

  getDistance = (touch1: React.Touch, touch2: React.Touch) => {
    const xDiff = touch1.clientX - touch2.clientX
    const yDiff = touch1.clientY - touch2.clientY
    return Math.sqrt(xDiff ** 2 + yDiff ** 2)
  }
  getMiddle = (touch1: React.Touch, touch2: React.Touch) => {
    return {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2,
    }
  }
  onWheel = (ev: React.WheelEvent) => {
    if (ev.deltaY < 0 && this.backgroundState.scrollZoom === 1.0) return
    this.backgroundState.scrollZoom = this.backgroundState.scrollZoom = clamp(
      1.0,
      this.getMaxZoom(),
      this.backgroundState.scrollZoom * Math.pow(1.01, ev.deltaY / 10),
    )
  }

  private handleMarkerClick = (event: TimelineEvent<EventDefinition>) => {
    const focused = this.props.selectedEvents.find((e) => e.id === event.id && !e.hovered)
    if (focused) {
      this.props.setSelectedEvents?.((events) => events.filter((e) => e !== focused))
    } else {
      this.props.setSelectedEvents?.((events) => [{ id: event.id, hovered: false }])
    }
    if (event.extra?.drawing?.length || event.definition_key === SKETCH_NOTE_DEFINITION.key) {
      this.props.onViewSketchClicked?.(event)
    }
    this.showMarkerClickInfo()
  }
  private handleEditSketchClick = (event: TimelineEvent<EventDefinition>) => {
    this.props.setSelectedEvents([])
    this.props.onEditSketchClicked?.(event)
  }
  private handleMarkerBlur = (event: TimelineEvent<EventDefinition>) => {
    const focused = this.props.selectedEvents.find((e) => e.id === event.id && !e.hovered)
    if (focused) {
      this.props.setSelectedEvents?.((events) => events.filter((e) => e !== focused))
    }
  }

  private async seek(time: number, pause?: boolean) {
    const newSeekPromise = this.props.onSeek?.(time, pause)
    await this.handleSeekTimeOverride(time, newSeekPromise)
  }

  private async scrub(time: number) {
    // const newSeekPromise =
    this.props.onScrub?.(time)
    this.backgroundState.currentTimeOverride = time
    // await this.handleSeekTimeOverride(time, newSeekPromise)
  }

  private isSameSeekOverride = (time: number, seekPromise: Promise<void>) => {
    return (
      this.backgroundState.latestSeekPromise === seekPromise &&
      this.backgroundState.currentTimeOverride !== undefined
    )
  }

  private handleSeekTimeOverride = async (time: number, seekPromise?: Promise<void>) => {
    this.backgroundState.currentTimeOverride = time
    this.backgroundState.whenOverriden = Date.now()

    if (!seekPromise) return
    this.backgroundState.latestSeekPromise = seekPromise
    await seekPromise
    if (!this.isSameSeekOverride(time, seekPromise)) return

    if (Math.abs(this.state.currentTime - this.backgroundState.currentTimeOverride) < 0.5) {
      this.backgroundState.currentTimeOverride = undefined
      this.backgroundState.whenOverriden = undefined
      this.backgroundState.latestSeekPromise = undefined
      return
    }

    await new Promise((res) => setTimeout(res, 500))
    if (!this.isSameSeekOverride(time, seekPromise)) return

    this.backgroundState.currentTimeOverride = undefined
    this.backgroundState.latestSeekPromise = undefined
  }
  private handleMarkerDblClick = (event: TimelineEvent<EventDefinition>) => {
    this.props.setSelectedEvents?.([{ id: event.id, hovered: false }])
    if (isNoteDefinition(event.definition_key)) {
      showCommentPanel()
    }
    // this.seek(event.time - 4)
  }
  private handleExtraClicked = (event: TimelineEvent<EventDefinition>) => {
    this.props.setSelectedEvents?.([{ id: event.id, hovered: false }])
  }
  private handleMarkerLongPress = (event: TimelineEvent<EventDefinition>) => {
    this.props.setSelectedEvents?.([{ id: event.id, hovered: false }])
    this.props.showSignInToEditDialog?.()
  }

  private handleMarkerUpdate = (event: TimelineEvent<EventDefinition>) => {
    this.props.onMarkerUpdate?.(event)
  }
  private handleMarkerPanDragFinished =
    (event: TimelineEvent<EventDefinition>) => (offsetX: number) => {
      const timePerPixel = this.timePerPixel()

      const timeDelta = offsetX * timePerPixel
      this.props.onMarkerUpdate?.({ ...event, time: event.time + timeDelta })
    }
  private handleMarkerStretchDragFinished =
    (event: TimelineEvent<EventDefinition>) => (offsetX: number) => {
      const timePerPixel = this.timePerPixel()

      const timeDelta = offsetX * timePerPixel
      this.props.onMarkerUpdate?.({
        ...event,
        duration: Math.max((event.duration ?? 0) + timeDelta, 0),
      })
    }

  seekerRef = createRef<HTMLDivElement>()

  getSeekerClientPosition(): Rect {
    const seekerRect = this.seekerRef.current?.getBoundingClientRect()
    const lineRect = this.seekerRef.current?.getBoundingClientRect()
    const left = seekerRect?.right || lineRect?.left || window.innerWidth / 2
    const right = seekerRect?.right || lineRect?.left || window.innerWidth / 2
    const top = seekerRect?.top || lineRect?.top || window.innerHeight / 2
    const bottom = seekerRect?.bottom || lineRect?.bottom || window.innerHeight / 2
    return {
      left: left,
      right: right,
      top: top,
      bottom: bottom,
    }
  }

  showMarkerClickInfo = () => {
    // toast('Double-click to rewind to the event', {
    //   position: 'bottom-center',
    //   type: 'info',
    //   theme: 'colored',
    //   autoClose: 5000,
    // })

    this.showMarkerClickInfo = () => {
      // do nothing
    }
  }

  handleMarkerDeleteClicked = (timelineEvent: TimelineEvent<EventDefinition>) => {
    this.props.onMarkerDelete?.(timelineEvent)
  }

  lineRef = createRef<HTMLDivElement>()
  getProgressFromMouseEvent = (event: MouseEvent) => {
    const line = this.lineRef.current
    if (!line) return 0
    const rect = line.getBoundingClientRect()

    return (event.clientX - rect.left) / rect.width
  }
  timePerPixel = () => {
    const line = this.lineRef.current
    if (!line) return 1
    const rect = line.getBoundingClientRect()
    return this.props.duration / rect.width
  }
  handleLineClicked = async (event: MouseEvent) => {
    // await this.seek(this.getProgressFromMouseEvent(event) * this.props.duration)
  }
  handlePointerDown = async (event: React.PointerEvent) => {
    const timePerPixel = this.timePerPixel()
    const timeOnDown =
      this.backgroundState.currentTimeOverride !== undefined ?
        this.backgroundState.currentTimeOverride
      : this.state.currentTime || 0
    const videoController = this.props.videoController
    const wasPlaying = videoController.currentStatus.playbackState !== 'PAUSED'

    const firstClientX = event.pageX
    this.pointerHandler.handlePointerMove = (
      event: Pick<PointerEvent, 'buttons' | 'pageX' | 'pageY' | 'pointerType'>,
    ) => {
      if (
        this.initialPinchDistance !== undefined ||
        (event.pointerType === 'mouse' && (event.buttons & 1) !== 1)
      ) {
        // currently pinching
        this.pointerHandler.handlePointerUp = EMPTYHANDLER
        this.pointerHandler.handlePointerMove = EMPTYHANDLER
        return
      }
      if (Math.abs(event.pageX - firstClientX) <= 4) return
      if (videoController.currentStatus.playbackState !== 'PAUSED') videoController.pause()
      const timeDelta = (event.pageX - firstClientX) * timePerPixel
      const newTime = Math.min(Math.max(timeOnDown - timeDelta, 0), this.props.duration)
      this.scrub(newTime)
    }
    this.pointerHandler.handlePointerUp = async (
      event: Pick<PointerEvent, 'buttons' | 'pageX' | 'pageY'>,
    ) => {
      this.pointerHandler.handlePointerUp = EMPTYHANDLER
      this.pointerHandler.handlePointerMove = EMPTYHANDLER

      if (Math.abs(event.pageX - firstClientX) < 4) {
        // await this.handleLineClicked(event)
      } else {
        const timeDelta = (event.pageX - firstClientX) * timePerPixel
        const newTime = Math.min(Math.max(timeOnDown - timeDelta, 0), this.props.duration)
        await this.seek(newTime)
      }
      if (wasPlaying) videoController.play()
    }
  }

  offsetMarker = (
    percentage: number,
    currentWidth = this.props.width * this.backgroundState.scrollZoom,
  ) => percentage * currentWidth

  shouldShow(markerpercentage: number, seekerPercentage: number) {
    const seekerOffset = this.offsetMarker(seekerPercentage)
    const minOffset = seekerOffset - this.props.width / 2
    const maxOffset = seekerOffset + this.props.width / 2
    const markerOffset = this.offsetMarker(markerpercentage)
    if (markerOffset < minOffset || markerOffset > maxOffset) {
      return false
    }
    return true
  }

  handleMarkerHoverEnter = (e: React.PointerEvent, event: TimelineEvent<EventDefinition>) => {
    this.props.onMarkerHovered?.(event)
  }
  handleMarkerHoverExit = () => {
    this.props.onMarkerHovered?.(undefined)
  }

  render() {
    const { width } = this.props
    // Calculate the minimum and maximum times in the events array
    const currentTime =
      this.backgroundState.currentTimeOverride !== undefined ?
        this.backgroundState.currentTimeOverride
      : this.state.currentTime !== undefined ? this.state.currentTime
      : 0
    const zoom = this.backgroundState.scrollZoom
    const zoomedWidth = width * zoom
    const maxTime = this.props.duration

    const seekerXPercentage = currentTime / maxTime
    const leftOffset =
      this.props.width / 2 -
      Math.min(Math.max(zoom * seekerXPercentage * this.props.width, 0), zoomedWidth)
    const powerOf5 = Math.floor(Math.log(zoomedWidth / maxTime) / Math.log(10))
    const timeUnits = 12 / 5 ** powerOf5
    const lastEventCreated = this.props.eventsCreatedThisSession?.lastOrNull()
    const renderableEvents = this.props.events.filter((event) => {
      const isNote = isNoteDefinition((event.tag as EventDefinition).key)
      const isRoundEnd = ['point_win', 'point_lose', 'point_draw'].includes(
        (event.tag as EventDefinition).key,
      )
      const isFilteredOut =
        !(isNote && !event.who?.length) &&
        !isRoundEnd &&
        !(
          (!this.props.selectedPlayers?.length ||
            this.props.selectedPlayers.any((player) => !!event.who?.includes(player))) &&
          (!this.props.eventFilters?.length ||
            this.props.eventFilters.any((filter) => event.definition_key === filter.key))
        )
      return this.shouldShow(event.time / maxTime, seekerXPercentage) && !isFilteredOut
    })
    const displayStyle = this.props.displayStyle ?? 'standard'
    const thickMode = displayStyle === 'thick-simplified'
    const centeredEvents = renderableEvents.filter(
      (e, i, a) => isNoteDefinition(e.definition_key) || this.POINT_EVENTS.has(e.definition_key),
    )
    const statLocalEvents = renderableEvents.filter(
      (e, i, a) =>
        !(isNoteDefinition(e.definition_key) || this.POINT_EVENTS.has(e.definition_key)) &&
        e.source === 'local',
    )
    const statRecordEvents = renderableEvents.filter(
      (e, i, a) =>
        !(isNoteDefinition(e.definition_key) || this.POINT_EVENTS.has(e.definition_key)) &&
        e.source === 'stat_record',
    )

    const nextLocalEventIndex = statLocalEvents.findIndex((event) => event.time > currentTime)
    const nextStatRecordEventIndex = statRecordEvents.findIndex((event) => event.time > currentTime)

    const events = new Set(
      centeredEvents.concat(
        statRecordEvents.filter((e, i, a) => Math.abs(nextStatRecordEventIndex - i) <= 5),
        statLocalEvents
          // These next filters are just to clean up the timeline when there are too many visible events.
          // TODO(Davey): Grouping Events that are Close together will make these obselete.
          .filter(
            (e, i, a) =>
              (this.props.viewingMode === 'edit' && Math.abs(nextLocalEventIndex - i) <= 20) ||
              Math.abs(nextLocalEventIndex - i) <= 5,
          ),
      ),
      // .filter(
      //   (e, i, a) =>
      //     a.length < 200 ||
      //     Math.abs(e.time - currentTime) < 500 / zoom ||
      //     isNoteDefinition(e.definition_key) ||
      //     this.POINT_EVENTS.has(e.definition_key),
      // )
      // .filter(
      //   (e, i, a) =>
      //     a.length < 200 ||
      //     this.POINT_EVENTS.has(e.definition_key) ||
      //     (Math.abs(e.time - currentTime) < 1000 / zoom && isNoteDefinition(e.definition_key)) ||
      //     Math.abs(e.time - currentTime) < 300 / zoom,
      // )
      // .filter(
      //   (e, i, a) =>
      //     a.length < 200 ||
      //     this.POINT_EVENTS.has(e.definition_key) ||
      //     (Math.abs(e.time - currentTime) < 500 / zoom && isNoteDefinition(e.definition_key)) ||
      //     Math.abs(e.time - currentTime) < 100 / zoom,
      // )
      // .filter(
      //   (e, i, a) =>
      //     a.length < 200 ||
      //     this.POINT_EVENTS.has(e.definition_key) ||
      //     (Math.abs(e.time - currentTime) < 300 / zoom && isNoteDefinition(e.definition_key)) ||
      //     Math.abs(e.time - currentTime) < 50 / zoom,
      // )
      // .filter(
      //   (e, i, a) =>
      //     a.length < 200 ||
      //     this.POINT_EVENTS.has(e.definition_key) ||
      //     (Math.abs(e.time - currentTime) < 150 / zoom && isNoteDefinition(e.definition_key)) ||
      //     Math.abs(e.time - currentTime) < 30 / zoom,
      // ),
    )

    const filteredEvents = renderableEvents.filter((event) => !events.has(event))
    return (
      <Box
        key={'timeline-scroll-container'}
        className={cn('relative flex whitespace-nowrap', thickMode && 'translate-y-1.5')}
        textAlign={'center'}
        style={{
          width: this.props.width,
        }}
        onWheel={this.onWheel}>
        <div
          key={'timeline-container'}
          className={cn(
            'absolute h-[100px] whitespace-nowrap',
            this.props.displaySizing === 'large' ? 'text-[12px]' : 'text-[9px]',
          )}
          style={{
            width: zoomedWidth + 'px',
            left: leftOffset + 'px',
          }}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          onPointerDown={this.handlePointerDown}>
          {range(Math.floor(this.props.duration / timeUnits)).map((it) => {
            const timePercentage = (it * timeUnits) / maxTime
            if (!this.shouldShow(timePercentage, seekerXPercentage)) {
              return undefined
            }
            return (
              <div
                className={cn(
                  'absolute inset-y-0 w-0 border-[0.5px] border-solid',
                  thickMode ? 'border-[rgba(0,0,0,0.9)]' : 'border-[rgba(255,255,255,0.2)]',
                )}
                key={`timeunit_${it}`}
                style={{
                  left: this.offsetMarker(timePercentage) + 'px',
                  height:
                    it % 5 ? '20px'
                    : it % 2 ? '30px'
                    : '40px',
                  top: 0,
                  bottom: 0,
                  width: 0,
                }}
              />
            )
          })}
          <Box
            key={'timeline-line'}
            className={cn(
              'absolute left-0 top-0 h-[10px] w-full cursor-pointer bg-white',
              thickMode && 'height-[50px] top-[-6px] opacity-[50%]',
            )}
            onClick={(e) => this.handleLineClicked(e.nativeEvent)}
            ref={this.lineRef}
          />
          <Box
            key={'timeline-line-seeker'}
            className={cn(
              'pointer-events-none absolute left-0 top-0 h-[10px] cursor-pointer bg-[#bd2026]',
              thickMode ? 'height-[50px] top-[-6px]' : undefined,
            )}
            style={{
              width: `${seekerXPercentage * 100}%`,
            }}
            ref={this.seekerRef}
            onClick={(e) => this.handleLineClicked(e.nativeEvent)}
          />
          {displayStyle === 'standard' && (
            <div
              className='absolute bottom-0 top-0 h-[65px] w-0 border-[0.5px] border-solid border-[rgba(255,255,255,0.2)]'
              key={'timeline-line-pointer'}
              style={{
                left: `${seekerXPercentage * 100}%`,
              }}>
              <div
                className='border-x-solid border-y-solid absolute bottom-0 h-0 w-0 -translate-x-2/4 translate-y-full
                  border-x-[8px] border-y-[15px] border-x-[transparent] border-y-[#bd2026bb]'
              />
              <div
                className='font-[500] absolute bottom-[-20px] -translate-x-2/4 translate-y-full text-[14px]
                  text-[rgb(162,162,162)]'
                style={{
                  fontFamily: 'LeagueSpartan,sans-serif',
                }}>
                {displayTime(currentTime)}
              </div>
            </div>
          )}

          {Array.from(events)
            .map((event) => ({ event, dontgroup: true }))
            .concat(filteredEvents.map((event) => ({ event, dontgroup: false })))
            .orderBy((it) => it.event.time)
            .map(({ event, dontgroup }, idx, events) => {
              const isNote = isNoteDefinition((event.tag as EventDefinition).key)
              const isRoundEnd = ['point_win', 'point_lose', 'point_draw'].includes(
                (event.tag as EventDefinition).key,
              )
              const isMatchReset = ['match_reset'].includes((event.tag as EventDefinition).key)
              if (
                !(
                  isNote ||
                  isRoundEnd ||
                  isMatchReset ||
                  (this.props.teamFilters?.includes(event.team) ?? true)
                )
              ) {
                return
              }
              let displayStyle: 'above' | 'below' | 'centre'
              if (this.props.multiTeamMode) {
                displayStyle =
                  isNote || isRoundEnd || isMatchReset ? 'centre'
                  : (
                    event.team === this.props.teamStore.mainTeam ||
                    this.props.teamFilters?.length === 1
                  ) ?
                    'below'
                  : 'above'
              } else {
                displayStyle =
                  isNote ? 'centre'
                  : isRoundEnd || isMatchReset ? 'centre'
                  : 'below'
              }

              const editOnMount =
                isNote &&
                SKETCH_NOTE_DEFINITION.key !== event.definition_key &&
                lastEventCreated === event.id
              const isFocused = !!this.props.selectedEvents?.find(
                (state) => state.id === event.id && !state.hovered,
              )
              const result: {
                displayStyle: 'above' | 'below' | 'centre'
                dontgroup: boolean
                editOnMount: boolean
                isNote: boolean
                event: TimelineEvent<EventDefinition>
                isFocused: boolean
              } = {
                event,
                dontgroup,
                displayStyle,
                editOnMount,
                isNote,
                isFocused,
              }
              return result
            })
            .filterNotNull()
            .reduce<
              {
                displayStyle: 'above' | 'below' | 'centre'
                dontgroup: boolean
                editOnMount: boolean
                isNote: boolean
                event: TimelineEvent<EventDefinition>
                isFocused: boolean
              }[][]
            >((groups, currentValue, currentIndex, array) => {
              const currentGroupAverage = groups
                .lastOrNull()
                ?.mapProp('event')
                ?.mapProp('time')
                .avgOrNull()

              if (currentValue.dontgroup) {
                groups.push([currentValue])
                groups.push([])
                return groups
              }

              let latestGroup = groups.lastOrNull()

              if (!latestGroup) {
                latestGroup = []
                groups.push(latestGroup)
              }

              if (
                currentGroupAverage &&
                currentValue.event.time - currentGroupAverage > 30 / zoom
              ) {
                latestGroup = []
                groups.push(latestGroup)
              }

              latestGroup.push(currentValue)

              return groups
            }, [])
            .map((group) => {
              const groups = group.groupBy((it) => it.displayStyle).entriesArray()
              return groups.map(([displayStyle, group]) => {
                const isGrouped = group.length > 1 && displayStyle !== 'centre'
                // const isGrouped = false
                const groupTimePercentage =
                  isGrouped ?
                    (group.mapProp('event').mapProp('time').minOrNull() ?? 0) / maxTime
                  : 0

                const groupLeftOffset = this.offsetMarker(groupTimePercentage)
                const eventsElements = group.map(
                  ({ event, displayStyle, editOnMount, isNote, isFocused, dontgroup }) => {
                    const timePercentage = event.time / maxTime
                    const isRoundEnd = ['point_win', 'point_lose', 'point_draw'].includes(
                      (event.tag as EventDefinition).key,
                    )
                    const isMatchReset = ['match_reset'].includes(
                      (event.tag as EventDefinition).key,
                    )

                    return (
                        this.props.displayStyle === 'thick-simplified' &&
                          !(
                            isNote ||
                            isRoundEnd ||
                            isMatchReset ||
                            this.props.selectedPlayers?.length ||
                            this.props.eventFilters?.length
                          )
                      ) ?
                        <></>
                      : <TimelineMarker
                          {...event}
                          videoController={this.props.videoController}
                          className={cn('top-[-5px]',  'absolute')}
                          showSignInToEditDialog={this.props.showSignInToEditDialog}
                          editOnMount={editOnMount}
                          key={event.id}
                          user={this.props.user}
                          event={event}
                          width={event.duration ? event.duration / this.timePerPixel() : undefined}
                          reversePoints={this.props.reversePoints}
                          viewingMode={this.props.viewingMode}
                          baseViewingMode={this.props.baseViewingMode}
                          documentPermissions={this.props.documentPermissions}
                          onUpdate={this.handleMarkerUpdate}
                          selectedPlayers={this.props.selectedPlayers}
                          eventFilters={this.props.eventFilters}
                          showPlayerChooser={this.props.showPlayerChooser}
                          onClick={this.handleMarkerClick}
                          left={isGrouped ? undefined : this.offsetMarker(timePercentage) + 'px'}
                          onHoverEnter={this.handleMarkerHoverEnter}
                          onHoverExit={this.handleMarkerHoverExit}
                          onDblClick={this.handleMarkerDblClick}
                          onLongPress={this.handleMarkerLongPress}
                          onPanFinished={this.handleMarkerPanDragFinished(event)}
                          onStretchFinished={this.handleMarkerStretchDragFinished(event)}
                          aboveHoverPanel={
                            isRoundEnd && this.props.activityType ?
                              () => (
                                <>
                                  {this.props.activityType && (
                                    <RoundStartPlayersPanel
                                      activityType={this.props.activityType}
                                      events={this.props.events}
                                      roundEndEvent={event}
                                    />
                                  )}
                                </>
                              )
                            : event.who && event.who.length > 1 ?
                              () => <>{event.who && <MultiplePlayerPanel players={event.who} />}</>
                            : undefined
                          }
                          onExtraClicked={this.handleExtraClicked}
                          onEditSketchClicked={this.handleEditSketchClick}
                          displayStyle={displayStyle}
                          getMatchNumberAtTime={this.props.getMatchNumberAtTime}
                          simplified={group.length === 1 && !dontgroup}
                          onBlur={this.handleMarkerBlur}
                          onDeleteClicked={this.handleMarkerDeleteClicked}
                          isFocused={isFocused}
                          isSemiSelected={
                            !!this.props.selectedEvents?.find(
                              (state) => state.id === event.id && state.hovered,
                            )
                          }
                        />
                  },
                )
                if (isGrouped) {
                  return (
                    <CompositeMarker
                      key={group.firstOrNull()?.event?.id}
                      left={groupLeftOffset + 'px'}
                      shouldSimplify={true}
                      isNote={false}
                      displayStyle={displayStyle}>
                      {eventsElements}
                    </CompositeMarker>
                  )
                } else {
                  return eventsElements
                }
              })
            })}
          {this.backgroundState.previewSelection && (
            <TimelineMarker
              style={{
                position: 'absolute',
              }}
              user={undefined}
              {...this.backgroundState.previewSelection}
              key={'preview'}
              event={this.backgroundState.previewSelection.event}
              showSignInToEditDialog={this.props.showSignInToEditDialog}
              baseViewingMode={this.props.baseViewingMode}
              viewingMode={this.props.viewingMode}
              documentPermissions={this.props.documentPermissions}
              onUpdate={this.handleMarkerUpdate}
              onClick={this.handleMarkerClick}
              left={
                this.offsetMarker(this.backgroundState.previewSelection.event.time / maxTime) + 'px'
              }
              getMatchNumberAtTime={this.props.getMatchNumberAtTime}
              displayStyle={'centre'}
              opacity={'60%'}
              videoController={this.props.videoController}
            />
          )}
        </div>
      </Box>
    )
  }
}

function range(n: number): number[] {
  return Array.from(Array(n).keys())
}

export default Timeline
function MultiplePlayerPanel({ players }: { players: Player[] }) {
  const listElement = useMemo(() => {
    return players
      .distinctBy((it) => it?.id)
      .filterNotNull()
      .map((player) => (
        <PlayerTile
          key={player.id}
          player={player}
          size={25}
          editMode={false}
          canEnableEditing={false}
          displayStyle={'icon-only'}
        />
      ))
  }, [players])
  return <Flex direction={'row'}>{listElement}</Flex>
}
