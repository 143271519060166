export type VideoPlaybackState =
  | 'BUFFERING'
  | 'ENDED'
  | 'PAUSED'
  | 'PLAYING'
  | 'UNSTARTED'
  | 'VIDEO_CUED'

export interface VideoPlayerStatus {
  title: string | undefined
  thumbnail: string | undefined
  currentTime: number
  playbackState: VideoPlaybackState
  duration: number | undefined
  playbackRate: number
}

export const DEFAULT_VIDEO_PLAYER_STATUS: VideoPlayerStatus = {
  currentTime: 0,
  playbackRate: 1.0,
  duration: undefined,
  playbackState: 'UNSTARTED',
  title: undefined,
  thumbnail: undefined,
}
