import { FirebaseDb, useDatabaseRefLiveValueMemo } from 'components/common/Firebase'
import { UnreachableError } from 'components/common/utils/error'
import { ReviewFirebaseEntry } from 'data/common'
import { useCallback, useMemo } from 'react'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import { ActivityStatsTemplate, ReviewActivityType } from 'ui/ReviewMetaStore'

export function useStatsTemplateStore(
  firebase: FirebaseDb,
  reviewId: string | undefined,
): [ActivityStatsTemplate | undefined, (templateKey: string) => Promise<void>] {
  const activityType = useDatabaseRefLiveValueMemo(
    () =>
      firebase
        .getRef<ReviewFirebaseEntry>(reviewId && `/reviews/${reviewId}`)
        .childFromKey('activityType'),
    [firebase, reviewId],
  )

  const templateKey = useDatabaseRefLiveValueMemo(
    () =>
      firebase
        .getRef<ReviewFirebaseEntry>(reviewId && `/reviews/${reviewId}`)
        .childFromKey('statsTemplateKey'),
    [firebase, reviewId],
  )

  const setTemplate = useCallback(
    (templateKey: string) =>
      firebase
        .getRef<ReviewFirebaseEntry>(reviewId && `/reviews/${reviewId}`)
        .childFromKey('statsTemplateKey')
        .set(templateKey),
    [firebase, reviewId],
  )

  return useMemo(
    () => [
      activityType ? parseStatsTemplate(activityType, templateKey ?? undefined) : undefined,
      setTemplate,
    ],
    [activityType, setTemplate, templateKey],
  )
}

export function parseStatsTemplate(
  activityType: ReviewActivityType,
  templateKey: string | undefined,
): ActivityStatsTemplate {
  switch (activityType) {
    case 'dodgeball':
      if (!templateKey) return { activityType: 'dodgeball', templateId: 'basic', type: 'defined' }
      if (templateKey in DodgeballTemplate.TemplateIds) {
        return {
          activityType: 'dodgeball',
          templateId: templateKey as keyof typeof DodgeballTemplate.TemplateIds,
          type: 'defined',
        }
      }
      return { activityType: 'dodgeball', templateId: templateKey, type: 'custom' }
    case 'sports_notes':
      if (!templateKey)
        return { activityType: 'sports_notes', templateId: 'default', type: 'defined' }
      return { activityType: 'sports_notes', templateId: templateKey, type: 'custom' }
    case 'general_notes':
      if (!templateKey)
        return { activityType: 'general_notes', templateId: 'default', type: 'defined' }
      return { activityType: 'general_notes', templateId: templateKey, type: 'custom' }
    default:
      throw UnreachableError(activityType)
  }
}
