import {
  EventDimension,
  EventDimensionsDefinition,
  generateEventDefinitions,
} from 'templates/MetaEvents'
import '../MetaEvents'
import {
  CLIP_DEFINITION,
  EventDefinition,
  NOTE_DEFINITION,
  TypedButtonConfig,
  UISetup,
  extendBaseSportsTemplate,
} from '../TemplateConfig'
import { ThrowDimensions, _Dodgeball, opposingFromThrow, throws } from './DodgeballDefinitions'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DodgeballTemplate {
  export const Definitions = _Dodgeball.BaseDefinitions
  export const TemplateIds= {
    // advanced stats
    default: true,
    // basic stats
    basic: true,
    // live stats
    kdr: true,
  } as const
  export const buttonSetup: UISetup = extendBaseSportsTemplate({
    defaultViewMode: 'view',
    definitions: Definitions.asMap(NOTE_DEFINITION),
    baseNodes: {
      default: Definitions.getAll('died', 'throw', 'defend', 'defend_catch').asButtonConfigs(
        Definitions,
      ),
      basic: Definitions.getAll('died', 'throw', 'defend', 'defend_catch_success').asButtonConfigs(
        Definitions,
      ),
      kdr: Definitions.getAll('died', 'throw_hit', 'defend_catch_success').asButtonConfigs(
        Definitions,
      ),
    } satisfies Record<keyof typeof TemplateIds, TypedButtonConfig[]>,
    followUpEvents: new Map<EventDefinition, EventDefinition[]>([
      [Definitions.get('defend_catch_success'), [Definitions.get('revive')]],
    ]),
    statCategories: _Dodgeball.generateStatHierarchy(Definitions),
    buttonHierarchy: _Dodgeball.generateButtonConfig(Definitions),
    defaultHighlightSuggestions: [
      Definitions.get('throw_hit'),
      Definitions.get('throw'),
      Definitions.get('defend_catch_success'),
      Definitions.get('died'),
      CLIP_DEFINITION,
    ],
  })

  // call this whenever a custom button is changed
  export function updateDodgeBallTemplate(
    newThrowDimensions: Partial<
      EventDimensionsDefinition<EventDimension<ThrowDimensions[0], string>>
    >,
  ) {
    Definitions.addDefinitions(
      generateEventDefinitions({
        baseDefinition: throws,
        newDimensions: newThrowDimensions,
        opposing: opposingFromThrow,
      }),
    )

    DodgeballTemplate.buttonSetup.buttonHierarchy = _Dodgeball.generateButtonConfig(Definitions)
    DodgeballTemplate.buttonSetup.statCategories = _Dodgeball.generateStatHierarchy(
      Definitions,
      newThrowDimensions,
    )
  }
}
