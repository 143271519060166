export default class I18nPaths {
  public static get TemplatesNamespace(): string {
    return 'templates'
  }
  public static get Templates_Dodgeball(): string {
    return 'templates:dodgeball'
  }
  public static get Templates_General(): string {
    return 'templates:general'
  }
}
