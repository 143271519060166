import fuzzysearch from 'fuzzysearch'
import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { RankedPlayerMeasureResults } from './RankingsTable'
import { useQueryString } from './common/utils/QueryString'

export type LeaderboardFilterStrategy = {
  commonRankings: (
    rankings: RankedPlayerMeasureResults[] | undefined,
  ) => RankedPlayerMeasureResults[] | undefined
  leaderboardTableRankings: (
    rankings: RankedPlayerMeasureResults[] | undefined,
  ) => RankedPlayerMeasureResults[] | undefined
  profileCarouselRankings: (
    rankings: RankedPlayerMeasureResults[] | undefined,
  ) => RankedPlayerMeasureResults[] | undefined
}

export function useLeaderboardFilterStrategy({
  searchPlayerTerm,
  searchTeamsTerm,
}: {
  searchPlayerTerm: string | undefined
  searchTeamsTerm: string | undefined
}): LeaderboardFilterStrategy {
  return useMemo(() => {
    return {
      commonRankings: (rankings) =>
        rankings?.filter((it) => {
          // no search - show all results
          if (!searchPlayerTerm && !searchTeamsTerm) return true
          if (!searchPlayerTerm)
            return (
              !searchTeamsTerm ||
              // only team search - show only team results
              it.reviews.any((review) =>
                fuzzysearch(
                  searchTeamsTerm?.toLocaleLowerCase(),
                  review.teamName?.toLocaleLowerCase() ?? '',
                ),
              )
            )

          if (!searchTeamsTerm)
            // only player search:
            // - leaderboard will filter further by player
            // - carousel will show all results
            return true

          return (
            // both player and team search - show both player and team results
            fuzzysearch(searchPlayerTerm?.toLocaleLowerCase(), it.player.toLocaleLowerCase()) ||
            it.reviews.any((review) =>
              fuzzysearch(
                searchTeamsTerm?.toLocaleLowerCase(),
                review.teamName?.toLocaleLowerCase() ?? '',
              ),
            )
          )
        }),
      leaderboardTableRankings: (rankings) =>
        rankings?.filter(
          (it) =>
            // leaderboard filters further by player
            !searchPlayerTerm ||
            fuzzysearch(searchPlayerTerm?.toLocaleLowerCase(), it.player.toLocaleLowerCase()),
        ),
      profileCarouselRankings: (rankings) => rankings,
    } satisfies LeaderboardFilterStrategy
  }, [searchPlayerTerm, searchTeamsTerm])
}

export function useIsChanging(value: any): boolean {
  const previousValue = useDeferredValue(value)

  return value !== previousValue
}

export function usePlayerProfileSearchBehaviour({
  searchPlayerTerm,
  rawRankings,
}: {
  searchPlayerTerm: string | undefined
  rawRankings: RankedPlayerMeasureResults[] | undefined
}) {
  const [queryPlayerProfile, setQueryPlayerProfile] = useQueryString('profile')

  const isSearchPlayerTermChanging = useIsChanging(searchPlayerTerm)

  // Select searched player while searching
  useEffect(() => {
    if (!queryPlayerProfile) return
    if (!isSearchPlayerTermChanging) return
    if (!searchPlayerTerm) return
    if (!rawRankings?.length) return

    const searchedPlayer =
      rawRankings.find((it) =>
        fuzzysearch(searchPlayerTerm.toLocaleLowerCase(), it.player.toLocaleLowerCase()),
      )?.player ?? 'No player found'

    setQueryPlayerProfile(searchedPlayer)
  }, [
    isSearchPlayerTermChanging,
    queryPlayerProfile,
    rawRankings,
    searchPlayerTerm,
    setQueryPlayerProfile,
  ])

  const selectedPlayerProfile: string | undefined = useMemo(() => {
    if (!queryPlayerProfile) return undefined

    if (!rawRankings?.find((it) => it.player === queryPlayerProfile)) {
      return rawRankings?.firstOrNull()?.player ?? queryPlayerProfile
    }

    return queryPlayerProfile
  }, [queryPlayerProfile, rawRankings])

  return { selectedPlayerProfile, setSelectedPlayerProfile: setQueryPlayerProfile }
}
