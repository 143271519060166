import { FirebaseOptions } from 'firebase/app'
import { MutableRefObject, RefObject, createContext, useContext, useMemo } from 'react'
import { getWrappedRealtimeDb, initFirebase } from '../Firebase'
import { getQueryStringValues } from '../utils/QueryString'
import { usePromiseState } from '../utils/reactUtils'

/**
 * Custom hook to retrieve the value of a feature flag.
 * @param feature - The name of the feature flag. e.g "enable-teams".
 * @param defaultValue - The default value for the feature flag. e.g "false"
 * @param safeInProd - Whether the flag is "data safe" to test in production. Data safe means that data in production will not be corrupted or lost if the feature is enabled.
 * @returns An object containing the value of the feature flag.
 */
export function useFeatureFlag<T extends string | number | boolean>(
  feature: string,
  defaultValue: T,
  safeInProd = false,
): { value: [T] extends boolean ? boolean | undefined : T | undefined } {
  const flagProvider = useContext(FlagsProviderContext)

  const value = usePromiseState(
    () => flagProvider?.getFlagValue<T>(feature, defaultValue, safeInProd),
    [flagProvider, feature, defaultValue, safeInProd],
  )
  return {
    value: value ?? (value === null ? defaultValue : undefined),
  }
}

/**
 * Returns an array of default flag providers based on the given Firebase configuration.
 * @param firebaseConfig - The Firebase configuration options.
 * @returns An array of default flag providers.
 */
export const getDefaultFlagProviders = async (firebaseConfig?: FirebaseOptions) => {
  const providers = [createUrlFlags()]
  if (firebaseConfig) providers.push(await createFirebaseRemoteConfigFlags(firebaseConfig))

  return providers
}

/**
 * Context for the flag providers.
 */
const FlagsProviderContext = createContext<FlagProvider | undefined>(undefined)

/**
 * Provider component for the flag providers.
 * @param props - The component props.
 * @param props.children - The child components.
 * @param props.flagProviders - The flag providers.
 * @returns The rendered component.
 */
export function FlagsContextProvider(props: {
  children: React.ReactNode
  flagProviders: FlagProvider[] | Promise<FlagProvider[]> | undefined
}) {
  const flagProviders = usePromiseState(async () => {
    return await props.flagProviders
  }, [props.flagProviders])

  const flagProvider = useMemo(
    () => flagProviders && createCompositeFlags(flagProviders),
    [flagProviders],
  )
  return (
    <FlagsProviderContext.Provider value={flagProvider}>
      {props.children}
    </FlagsProviderContext.Provider>
  )
}

/**
 * Interface for a flag provider.
 */
type FlagProvider = {
  name?: string
  state?: any
  /**
   * Retrieves the value of a feature flag.
   * @param feature - The name of the feature flag.
   * @param safeInProd - Whether the flag is safe to use in production.
   * @returns The value of the feature flag.
   */
  getFlagValue: <T extends number | string | boolean>(
    feature: string,
    // we only use this value to handle typing. The actual value is not used.
    typeHint: T,
    safeInProd?: boolean,
  ) => Promise<T | null>
}

/**
 * Creates a composite flag provider from multiple flag providers.
 * @param flagProviders - The flag providers to combine.
 * @returns A composite flag provider.
 */
export function createCompositeFlags(flagProviders: FlagProvider[]): FlagProvider {
  return {
    name: 'CompositeFlags',
    state: [flagProviders.map((it) => ({ name: it.name, state: it.state }))],
    getFlagValue: async <T extends number | string | boolean>(
      feature: string,
      defaultValue: T,
      safeInProd?: boolean,
    ) => {
      for (const provider of flagProviders) {
        const value = await provider.getFlagValue(feature, defaultValue, safeInProd)
        if (value !== undefined && value != null) return value as T
      }
      return null
    },
  }
}

/**
 * Creates a composite flag provider from multiple flag providers.
 * @param flagProviders - The flag providers to combine.
 * @returns A composite flag provider.
 */
export function createWaitingCompositeFlags(
  flagProviders: FlagProvider[] | Promise<FlagProvider[]>,
): FlagProvider {
  const stateRef: MutableRefObject<any> = { current: undefined }
  return {
    name: 'WaitingCompositeFlags',
    state: stateRef,
    getFlagValue: async <T extends number | string | boolean>(
      feature: string,
      defaultValue: T,
      safeInProd?: boolean,
    ) => {
      const providers = await flagProviders
      stateRef.current = providers.map((p) => p.state)
      const provider = createCompositeFlags(providers)
      return provider.getFlagValue(feature, defaultValue, safeInProd)
    },
  }
}

/**
 * Creates a flag provider based on URL query parameters.
 * @returns A flag provider.
 */
export function createUrlFlags(): FlagProvider {
  return {
    name: 'UrlFlags',
    getFlagValue: async <T extends number | string | boolean>(
      feature: string,
      typeHint: T,
      safeInProd?: boolean,
    ) => {
      if (process.env.NODE_ENV === 'development' || safeInProd) {
        const flagString = getQueryStringValues('flag')?.find((f) => f.startsWith(feature))

        if (flagString && typeof typeHint === 'boolean' && flagString.split(':')[1]) {
          const value = flagString.split(':')[1]
          if (value === 'true') return true as T
          if (value === 'false') return false as T
          return null
        }

        return (flagString?.split(':')?.[1] ?? null) as T | null
      }
      return null
    },
  }
}

/**
 * Creates a flag provider based on Firebase Remote Config.
 * @param firebaseConfig - The Firebase configuration options.
 * @returns A flag provider.
 */
export async function createFirebaseRemoteConfigFlags(
  firebaseConfig: FirebaseOptions,
): Promise<FlagProvider> {
  const firebase = initFirebase(firebaseConfig)

  const generalFlagsRef = getWrappedRealtimeDb(firebase.database).getRef<FirebaseFlagEntry>('flags')

  const flags: { current: FirebaseFlagEntry['general'] | undefined } = { current: undefined }

  const flagPromise = await new Promise<void>((resolve, reject) => {
    generalFlagsRef.childFromKey('general').onValue(
      (snapshot) => {
        flags.current = snapshot.val()
        resolve()
      },
      (e) => {
        resolve()
      },
      { onlyOnce: false },
    )
  })

  return {
    name: 'FirebaseRemoteConfigFlags',
    state: flags,
    getFlagValue: async <T extends number | string | boolean>(
      feature: string,
      typeHint: T,
    ): Promise<T | null> => {
      await flagPromise

      const value = flags.current?.[feature]
      if (value === null || value === undefined) return null
      if (typeof typeHint === 'boolean') return (value === 'true' || value === true) as T
      if (typeof typeHint === 'number') return Number(value) as T
      return value as T
    },
  }
}

export type FirebaseFlagEntry = {
  general?: {
    [key: string]: string | null | boolean
  } | null
  users?: {
    [uid: string]: {
      nickname?: string | null
      staff?: boolean | null
      admin?: boolean | null
      statsPro: boolean | null
    }
  } | null
  groups?: { [groupId: string]: { [subscriptionType: string]: boolean } } | null
}
