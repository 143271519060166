import getVideoId from 'get-video-id'
import { Component, createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VideoSource } from '../data/common'
import { ReactVideoPlayer } from './ReactVideoPlayer'
import { useQueuedVideoController } from './UseQueuedVideoController'
import { VideoPlayerProps } from './VideoPlayerProps'
import { DEFAULT_VIDEO_PLAYER_STATUS, VideoPlayerStatus } from './VideoPlayerStatus'
import { YoutubeVideoPlayer } from './YoutubeVideoPlayer'
import { ErrorBoundary } from './common/utils/reactUtils'

export type StatusListener = (status: VideoPlayerStatus) => void

export interface VideoController {
  /**
   * Access [currentStatus] directly to avoid re-rendering every frame.
   * If you would like to keep state up to date, use [useVideoPlayerStatus] to get refreshes.
   */
  currentStatus: VideoPlayerStatus

  seekTo(time: number): Promise<void>

  scrubTo(time: number): Promise<void>

  seekRelative(time: number): Promise<void>

  setPlaybackRate(suggestedRate: number): Promise<void>

  play(): Promise<void>

  pause(): Promise<void>
}

export function useVideoPlayer(video?: { videoId: string; source: VideoSource }): {
  VideoPlayer: (props: Omit<VideoPlayerProps, 'videoId' | 'source'>) => JSX.Element
  videoController: VideoController
} {
  const { videoController, setVideoController } = useQueuedVideoController()
  const VideoPlayer = useCallback(
    (props: Omit<VideoPlayerProps, 'videoId' | 'source'>) => {
      return (
        <>
          <ErrorBoundary>
            {video && props.width && props.height && (
              <VideoPlayerInternal
                {...props}
                onPlayerReady={async (player, status) => {
                  await props.onPlayerReady(player, status)
                }}
                onController={setVideoController}
                source={video.source}
                videoId={video.videoId}
              />
            )}
          </ErrorBoundary>
        </>
      )
    },
    [video, setVideoController],
  )

  return useMemo(
    () => ({
      VideoPlayer,
      videoController,
    }),
    [videoController, VideoPlayer],
  )
}

export function useVideoPlayerStatus<T>(
  videoController: VideoController | undefined,
  frequency: number,
  onlyOnChange: boolean,
  fn: (status: VideoPlayerStatus) => T,
): T
export function useVideoPlayerStatus(
  videoController: VideoController | undefined,
  frequency: number,
  onlyOnChange: boolean,
): VideoPlayerStatus
export function useVideoPlayerStatus(
  videoController: VideoController | undefined,
  frequency: number,
): VideoPlayerStatus
export function useVideoPlayerStatus(
  videoController: VideoController | undefined,
  frequency: number,
  onlyOnChange?: boolean,
  fn?: (status: VideoPlayerStatus) => any,
): any {
  const fnRef = useRef<((status: VideoPlayerStatus) => any) | undefined>(fn)
  fnRef.current = fn
  const ref = useRef<any>(
    fnRef.current ? fnRef.current(DEFAULT_VIDEO_PLAYER_STATUS) : { ...DEFAULT_VIDEO_PLAYER_STATUS },
  )
  const [, refresh] = useState<object>()
  useEffect(() => {
    const timer = setInterval(async () => {
      if (
        videoController?.currentStatus &&
        (!onlyOnChange || ref.current !== videoController?.currentStatus)
      ) {
        ref.current =
          fnRef.current ?
            fnRef.current(videoController.currentStatus)
          : videoController.currentStatus
        refresh({})
      }
    }, 1000 / frequency)
    return () => clearInterval(timer)
  }, [onlyOnChange, frequency, videoController])
  return ref.current
}

class VideoPlayerInternal extends Component<
  VideoPlayerProps & {
    onController: (videoController: VideoController) => void
  }
> {
  youtubeRef = createRef<YoutubeVideoPlayer>()
  reactPlayerRef = createRef<ReactVideoPlayer>()

  getPlayerVariant = (): 'Youtube' | 'ReactPlayer' => {
    return this.props.source === 'Youtube' || this.props.source === 'Youtube_url' ?
        'Youtube'
      : 'ReactPlayer'
  }

  handleReady = async (videoController: VideoController, status: VideoPlayerStatus) => {
    await this.props.onPlayerReady(videoController, status)
    // We intentionally call this after onPlayerReady, so that any queued commands are applied after any default behaviour
    this.props.onController(videoController)
  }

  render() {
    const { videoId, source, ...videoPlayerProps } = this.props
    const resolvedVideoId = source === 'Youtube_url' ? getVideoId(videoId).id : videoId
    const variant = this.getPlayerVariant()
    return (
      <>
        {variant === 'Youtube' && resolvedVideoId && (
          <YoutubeVideoPlayer
            {...videoPlayerProps}
            onPlayerReady={this.handleReady}
            source={source}
            videoId={resolvedVideoId}
            ref={this.youtubeRef}
          />
        )}

        {variant === 'ReactPlayer' && (
          <ReactVideoPlayer
            {...videoPlayerProps}
            onPlayerReady={this.handleReady}
            source={source}
            videoId={videoId}
            ref={this.reactPlayerRef}
          />
        )}
      </>
    )
  }
}
