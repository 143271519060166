import { Flex, useUpdateEffect } from '@chakra-ui/react'
import { LOCAL_USER } from 'components/Timeline'
import { useCompositeRefObject } from 'components/UseCompositeRefObject'
import useHover from 'components/UseHover'
import { isInView, scrollIntoViewIfNeeded } from 'components/common/utils/reactUtils'
import { cn } from 'components/common/utils/tailwindUtils'
import { displayTime } from 'components/common/utils/timeUtils'
import { DocumentPermissions } from 'data/common'
import { User } from 'firebase/auth'
import React, {
  CSSProperties,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { EventDefinition, NOTE_DEFINITION } from 'templates/TemplateConfig'
import { useEffectOnce } from 'usehooks-ts'
import { Colors } from '../Colors'
import { useWindowDimensions } from '../UseWindowDimensions'
import { PwaInstaller } from '../components/common/InstallPwa'
import { RoundButton } from '../components/common/RoundButton'
import { NotificationRegisterer } from '../components/common/useNotificationsRegistration'
import { ReviewSelection } from '../data/ReviewSelectionInit'
import { TimelineEvent, TimelineNote } from '../data/TimelineEvent'
import { ViewingMode } from '../data/ViewingMode'
import { isNoteDefinition } from '../data/common'
import comment_icon from '../icons/comment.png'
import message_icon from '../icons/message_icon.svg'
import sketch_icon from '../icons/squiggle.svg'
import { ActionProps } from '../util/dialogUtils'
import { ClipboardCopyFn, getThumbnail, useCopyLinkToClipBoard } from '../util/links'
import { ClipCard, InstructionCard } from './ClipCard'
import { PlayerStore } from './PlayerStore'
import { ShowHidePanel } from './ShowHidePanel'
import { SelectedEventState, TimelineStore } from './TimelineStore'

let globalShowCommentPanel: (show: boolean) => void = () => {
  throw Error("Comments Panel hasn't been rendered")
}

export const showCommentPanel: (show?: boolean) => void = (show = true) =>
  globalShowCommentPanel(show)
const COMMENT_BAR_FONT_SCALE_KEY = 'comments_bar_font_scale'

// const COMMENT_BAR_EXPAND_KEY = 'comments_bar_expand'

export function VerticalCommentsPanel({
  isLocalReview,
  timelineStore,
  viewingMode,
  baseViewingMode,
  playerStore,
  title,
  style,
  onCommentPlayRequest,
  displayStyle = 'medium',
  enterEditMode,
  navigateToSignIn,
  user,
  showSignInToEditDialog,
  documentPermissions,
  notificationRegistrationStatus,
  reviewSelectionState,
  pwaInstaller,
  onSketchShowRequest,
  watchMode,
  ...props
}: {
  title: string | undefined
  pwaInstaller: PwaInstaller | undefined
  reviewSelectionState: ReviewSelection | undefined
  notificationRegistrationStatus: NotificationRegisterer
  isLocalReview: boolean
  showSignInToEditDialog: undefined | ((props?: ActionProps) => void)
  enterEditMode?: (user: User) => void
  user: User | undefined
  navigateToSignIn: () => void
  timelineStore: TimelineStore | undefined
  viewingMode: ViewingMode
  baseViewingMode: ViewingMode
  playerStore: PlayerStore
  style?: CSSProperties
  documentPermissions: DocumentPermissions | undefined
  onCommentPlayRequest: (time: number, eventId: string) => void
  onSketchShowRequest: ((event: TimelineEvent<EventDefinition>) => void) | undefined
  displayStyle?: 'large' | 'medium'
  watchMode?: boolean
  onAddComment?: () => void
  onAddSketch?: () => void
  onEditSketch?: () => void
}) {
  const panelWidth = useMemo(() => (displayStyle === 'large' ? '350px' : '300px'), [displayStyle])
  const defaultContentFontSize = useMemo(() => (displayStyle === 'large' ? 20 : 15), [displayStyle])
  const windowDimensions = useWindowDimensions()
  const sortedEvents = timelineStore?.sortedEvents
  const comments = useMemo(() => {
    return sortedEvents
      ?.filter((i) => i.specialType === 'note')
      .filter(
        (e) =>
          !e?.who?.length ||
          !playerStore.selectedPlayers?.length ||
          e.who.any((w) => !!playerStore.selectedPlayers?.includes(w)),
      )
  }, [sortedEvents, playerStore.selectedPlayers])

  const clipboardCopier = useCopyLinkToClipBoard()
  const handleShareCommentClicked = useCallback(
    (copyToClipboard: ClipboardCopyFn) => async (event: TimelineEvent<EventDefinition>) => {
      if (!reviewSelectionState) {
        return
      }

      const commentBy =
        event.createBy?.displayName?.trim() || event.modifiedBy?.lastOrNull()?.displayName?.trim()
      const trimmedComment = event.extra?.message?.slice(0, 40)
      const startTime = Math.max(event.time - 4, 0)
      await copyToClipboard({
        canonical: `${window.location.origin}/editor?id=${reviewSelectionState.reviewId}&mode=${documentPermissions}&eventid=${event.id}&t=${startTime}`,
        pageTitle: `${displayTime(event.time)} | ${commentBy ? `Comment by ${commentBy} | ` : ''}"${trimmedComment}..."`,
        thumbnail: await getThumbnail(reviewSelectionState.videoId, reviewSelectionState.source),
        reviewTitle: title,
        socialMediaText: `Check out this PlayBack clip!\n\n"${event.extra?.message}" ${commentBy ? ` by ${commentBy} | ` : ''}`,
      })
    },
    [documentPermissions, reviewSelectionState, title],
  )

  const defaultVisibility = useMemo(
    () =>
      (reviewSelectionState?.eventId &&
        isNoteDefinition(timelineStore?.getEvent(reviewSelectionState?.eventId)?.definition_key)) ||
      ((viewingMode === 'edit' || !!comments?.length) && !isMobile),
    [viewingMode, comments?.length, timelineStore, reviewSelectionState],
  )
  const userVisibilityState = useState<boolean | undefined>(undefined)
  const [, setUserSetVisibility] = userVisibilityState

  globalShowCommentPanel = useCallback(
    (show: boolean) => {
      setUserSetVisibility(show)
    },
    [setUserSetVisibility],
  )

  const Footer = useCallback(
    () => (
      <>
        {watchMode && documentPermissions === 'edit' && (
          <Flex
            justifyContent={'center'}
            padding={10}
            gap={8}>
            {props.onAddComment && (
              <RoundButton
                onClick={props.onAddComment}
                icon={message_icon}
                alt={'Add a note'}
                backgroundColor={Colors.color_comments_orange}
                squashedChildrenFallback={['Note']}>
                Add Note
              </RoundButton>
            )}
            {!props.onEditSketch && props.onAddSketch && (
              <RoundButton
                onClick={props.onAddSketch}
                color={'white'}
                icon={sketch_icon}
                alt={'Draw a sketch'}
                backgroundColor={Colors.color_sketch_green}
                squashedChildrenFallback={['Sketch', 'Draw']}>
                Add Sketch
              </RoundButton>
            )}
            {props.onEditSketch && (
              <RoundButton
                onClick={props.onEditSketch}
                color={'white'}
                icon={sketch_icon}
                alt={'Draw a sketch'}
                backgroundColor={Colors.color_sketch_green}
                squashedChildrenFallback={['Sketch', 'Draw']}>
                Add Sketch
              </RoundButton>
            )}
          </Flex>
        )}
      </>
    ),
    [props.onAddSketch, props.onAddComment, props.onEditSketch, documentPermissions, watchMode],
  )

  return (
    <>
      <ShowHidePanel
        side={'right'}
        style={style}
        fontScaleStorageKey={COMMENT_BAR_FONT_SCALE_KEY}
        width={panelWidth}
        defaultFontSize={defaultContentFontSize}
        color={'#f1bf46'}
        title={'Notes'}
        userVisibilityState={userVisibilityState}
        defaultVisibility={defaultVisibility}
        tabIcon={comment_icon}
        FloatingFooter={Footer}>
        <CommentPanelContent
          pwaInstaller={pwaInstaller}
          notificationRegistrationStatus={notificationRegistrationStatus}
          displayStyle={displayStyle}
          timelineStore={timelineStore}
          isLocalReview={isLocalReview}
          showSignInToEditDialog={showSignInToEditDialog}
          onShareCommentClicked={
            clipboardCopier.copy && handleShareCommentClicked(clipboardCopier.copy)
          }
          enterEditMode={enterEditMode}
          user={user}
          documentPermissions={documentPermissions}
          navigateToSignIn={navigateToSignIn}
          viewingMode={viewingMode}
          baseViewingMode={baseViewingMode}
          comments={comments}
          onCommentPlayRequest={(time, eventId) => {
            onCommentPlayRequest(time, eventId)
            windowDimensions.width < 600 && setUserSetVisibility(false)
          }}
          onSketchShowRequest={(event: TimelineEvent<EventDefinition>) => {
            onSketchShowRequest?.(event)
            windowDimensions.width < 600 && setUserSetVisibility(false)
          }}
        />
      </ShowHidePanel>
    </>
  )
}

type TimelineNoteWithChildren = TimelineNote<EventDefinition> & {
  children: TimelineNoteWithChildren[]
}

type CommentPanelContentProps = {
  pwaInstaller: PwaInstaller | undefined
  notificationRegistrationStatus: NotificationRegisterer
  comments: TimelineNote<EventDefinition>[] | undefined
  isLocalReview: boolean
  showSignInToEditDialog: undefined | ((props?: ActionProps) => void)
  onShareCommentClicked: ((event: TimelineEvent<EventDefinition>) => void) | undefined
  enterEditMode?: (user: User) => void
  user: User | undefined
  navigateToSignIn: () => void
  timelineStore: TimelineStore | undefined
  viewingMode: ViewingMode
  baseViewingMode: ViewingMode
  documentPermissions: DocumentPermissions | undefined
  onCommentPlayRequest: (time: number, eventId: string) => void
  onSketchShowRequest: (event: TimelineEvent<EventDefinition>) => void
  displayStyle: 'large' | 'medium' | undefined
}

export function CommentPanelContent(props: CommentPanelContentProps) {
  const {
    baseViewingMode,
    displayStyle,
    documentPermissions,
    enterEditMode,
    isLocalReview,
    navigateToSignIn,
    onCommentPlayRequest,
    onShareCommentClicked,
    showSignInToEditDialog,
    timelineStore,
    user,
    viewingMode,
    comments,
    notificationRegistrationStatus,
    pwaInstaller,
    onSketchShowRequest,
  } = props
  const iconSize = useMemo(() => (displayStyle === 'large' ? 35 : 30), [displayStyle])
  const selectedEvents: Map<string, SelectedEventState[]> | undefined = useMemo(() => {
    return timelineStore?.selectedEvents.groupBy((it) => it.id)
  }, [timelineStore?.selectedEvents])

  const getCommentList = useCallback((comments: TimelineNote<EventDefinition>[] | undefined) => {
    if (!Array.isArray(comments)) return []

    const commentList: {
      [id: string]: TimelineNoteWithChildren
    } = {}
    const rootComments: TimelineNoteWithChildren[] = []

    comments.forEach((item) => {
      commentList[item.id] = { ...item, children: [] }
    })

    comments.forEach((item) => {
      if (item.parentNoteId && commentList[item.parentNoteId]) {
        commentList[item.parentNoteId].children?.push(commentList[item.id])
      } else {
        rootComments.push(commentList[item.id])
      }
    })

    return rootComments.map((item) => ({
      ...item,
      children: item.children?.orderBy((it) => it.createdDate),
    }))
  }, [])

  const commentList = useMemo(() => getCommentList(comments), [comments, getCommentList])

  const addTimelineEvent = useCallback(
    async ({
      eventDefinition,
      timelineEvent,
    }: {
      eventDefinition: EventDefinition
      timelineEvent: TimelineNote<EventDefinition>
    }) => {
      if (timelineStore) {
        await timelineStore.addEvent(
          timelineEvent.time,
          eventDefinition,
          timelineEvent.team,
          isLocalReview,
          user,
          undefined,
          timelineEvent.id,
        )
      }
    },
    [isLocalReview, timelineStore, user],
  )

  const handleAddReplyButton = useCallback(
    (timelineEvent: TimelineNote<EventDefinition>) => {
      if (user) {
        addTimelineEvent({ eventDefinition: NOTE_DEFINITION, timelineEvent })
      } else showSignInToEditDialog?.({ title: 'reply', message: 'add a reply' })
    },
    [user, showSignInToEditDialog, addTimelineEvent],
  )

  return (
    <>
      <InstructionCard
        pwaInstaller={pwaInstaller}
        notificationRegisterer={notificationRegistrationStatus}
        isLocalReview={isLocalReview}
        baseViewingMode={baseViewingMode}
        user={user}
        enterEditMode={enterEditMode}
        viewingMode={viewingMode}
        documentPermissions={documentPermissions}
        navigateToSignIn={navigateToSignIn}
      />
      {commentList?.map((e) => {
        // { isSelected: !!selectedState, hovered: selectedState?.hovered, play: selectedState?.play! }
        return (
          <CommentItem
            key={e.id}
            timelineEvent={e}
            selectedEvents={selectedEvents}
            handleAddReplyButton={handleAddReplyButton}
            timelineStore={timelineStore}
            onCommentPlayRequest={onCommentPlayRequest}
            onSketchShowRequest={onSketchShowRequest}
            onShareCommentClicked={onShareCommentClicked}
            showSignInToEditDialog={showSignInToEditDialog}
            documentPermissions={documentPermissions}
            user={user}
            isLocalReview={isLocalReview}
            iconSize={iconSize}
            zIndex={10000}
          />
        )
      })}
    </>
  )
}

type CommentItemProps = {
  scrollContainerRef?: RefObject<HTMLDivElement>
  timelineEvent: TimelineNoteWithChildren
  selectedEvents: Map<string, SelectedEventState[]> | undefined
  timelineStore: TimelineStore | undefined
  handleAddReplyButton: (timelineEvent: TimelineNote<EventDefinition>) => void
  onCommentPlayRequest: (time: number, eventId: string) => void
  onSketchShowRequest: (event: TimelineEvent<EventDefinition>) => void
  onShareCommentClicked: ((event: TimelineEvent<EventDefinition>) => void) | undefined
  showSignInToEditDialog: ((props?: ActionProps) => void) | undefined
  documentPermissions: DocumentPermissions | undefined
  user: User | undefined
  isLocalReview: boolean
  iconSize: number
  isChildren?: boolean
  className?: string
  editableStringClassName?: string
  authorClassName?: string
  style?: CSSProperties
  zIndex: number
}

function CommentItem(props: CommentItemProps) {
  const {
    timelineEvent,
    selectedEvents,
    timelineStore,
    handleAddReplyButton,
    onCommentPlayRequest,
    onSketchShowRequest,
    onShareCommentClicked,
    showSignInToEditDialog,
    documentPermissions,
    user,
    isLocalReview,
    iconSize,
    isChildren,
    className,
    editableStringClassName,
    authorClassName,
    style,
    zIndex,
  } = props
  const selectedState = selectedEvents?.get(timelineEvent.id)
  const canEnableEditing = !!(
    documentPermissions === 'edit' &&
    timelineEvent.source === 'local' &&
    !timelineEvent?.isDeleted
  )
  const isCommentingAllowed = !!(
    canEnableEditing &&
    (user || (isLocalReview && timelineEvent.createBy.uid === LOCAL_USER.uid))
  )

  const isSelected = useMemo(() => !!selectedState?.length, [selectedState])
  const isExternallyHovered = useMemo(
    () => !!selectedState?.any((it) => it.hovered),
    [selectedState],
  )

  const firstInViewTimeRef = useRef<number | undefined>(undefined)
  const [seenThisSession, markSeenThisSessionTime] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)
  const clipCardRef = useRef<HTMLDivElement>(null)

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  scrollContainerRef.current =
    props.scrollContainerRef?.current ??
    (containerRef.current?.parentElement as HTMLDivElement | null) ??
    null

  const seen = useMemo(() => {
    if (!timelineEvent.extra?.message) return true
    return user?.uid === undefined ?
        true
      : Math.max(
          seenThisSession,
          timelineEvent.seenBy[user.uid] ?? /*feature roll out date*/ 1693966480244,
        ) +
          5000 >=
          Math.max(
            timelineEvent.reactedDate ?? 0,
            timelineEvent.createdDate ?? 0,
            timelineEvent.modifiedDate ?? 0,
          )
  }, [
    user,
    timelineEvent.reactedDate,
    timelineEvent.extra?.message,
    timelineEvent.modifiedDate,
    timelineEvent.seenBy,
    timelineEvent.createdDate,
    seenThisSession,
  ])

  useEffect(() => {
    if (isSelected && !isExternallyHovered) {
      // give the panel time to render
      setTimeout(() => {
        scrollIntoViewIfNeeded(clipCardRef.current, scrollContainerRef.current)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useUpdateEffect(() => {
    if (isSelected && !isExternallyHovered) {
      setTimeout(() => {
        scrollIntoViewIfNeeded(clipCardRef.current, scrollContainerRef.current)
      }, 300)
    }
  }, [isSelected, isExternallyHovered])

  const [isHovered, hoverRef] = useHover<HTMLDivElement>()
  const [lastHovered, setLastHovered] = useState(0)

  useEffect(() => {
    if (isHovered) {
      setLastHovered(Date.now())
    }
  }, [isHovered])

  // detect seen
  if (
    (firstInViewTimeRef.current && !isInView(clipCardRef.current, scrollContainerRef.current)) ||
    seen
  ) {
    firstInViewTimeRef.current = undefined
  } else if (!seen && user && isInView(clipCardRef.current, scrollContainerRef.current)) {
    if (firstInViewTimeRef.current) {
      if (
        (isMobile && firstInViewTimeRef.current < Date.now() - 5000) ||
        ((isHovered || lastHovered > Date.now() - 3000) &&
          firstInViewTimeRef.current < Date.now() - 3000)
      ) {
        timelineStore?.updateEventSeenDate(timelineEvent.id, user)
        markSeenThisSessionTime(Date.now())
        firstInViewTimeRef.current = undefined
      }
    } else {
      firstInViewTimeRef.current = Date.now()
    }
  }
  //end detect seen

  const multiClipCardRef = useCompositeRefObject(hoverRef, clipCardRef)
  return (
    <div
      ref={containerRef}
      className={className}
      style={{ zIndex }}>
      <ClipCard
        ref={multiClipCardRef}
        showReply={
          canEnableEditing && timelineEvent.children && timelineEvent.children.length === 0
        }
        editableEnabled={isCommentingAllowed}
        canEnableEditing={canEnableEditing}
        isLocalReview={isLocalReview}
        isSelected={isSelected}
        isHovered={isExternallyHovered}
        showSignInToEditDialog={showSignInToEditDialog}
        user={user}
        fontSize={'1em'}
        playerIconSize={iconSize}
        timelineStore={timelineStore}
        timelineEvent={timelineEvent}
        onPlayButtonClicked={() => {
          timelineStore?.setSelectedEvents([
            {
              id: timelineEvent.id,
              hovered: true,
            },
          ])
          onCommentPlayRequest(timelineEvent.time, timelineEvent.id)
        }}
        onShowSketchButtonClicked={() => {
          timelineStore?.setSelectedEvents([
            {
              id: timelineEvent.id,
              hovered: true,
            },
          ])
          onSketchShowRequest(timelineEvent)
        }}
        onShareButtonClicked={onShareCommentClicked && (() => onShareCommentClicked(timelineEvent))}
        documentPermissions={documentPermissions}
        hideCommentTopInteractionsBar={isChildren}
        seen={seen}
        containerRef={clipCardRef}
        className={cn('drop-shadow-xl', className)}
        editableStringClassName={cn(
          timelineEvent?.isDeleted && 'text-[#b0b0b0]',
          'bg-[#282c34]',
          editableStringClassName,
        )}
        onReplyButtonClicked={handleAddReplyButton}
        authorClassName={authorClassName}
        style={{
          zIndex: zIndex - 1,
          ...style,
        }}
      />
      {Array.isArray(timelineEvent.children) && (
        <>
          {timelineEvent.children.map((e) => {
            return (
              <CommentItem
                {...props}
                scrollContainerRef={scrollContainerRef}
                key={e.id}
                timelineEvent={e}
                isChildren
                className='-mt-3'
                editableStringClassName='pt-10 bg-[#353a44]'
                authorClassName='top-8'
                zIndex={zIndex - 2}
              />
            )
          })}
        </>
      )}
    </div>
  )
}
